let D7 = 0;

let D9 = null;
let D10 = null;
let D11 = null;
let D12 = null;
let D14 = null;
let D16 = null;
let D17 = null;
let D18 = null;

let D20 = null;
let D21 = null;
let D22 = null;
let D23 = null;
let D24 = null;

let D30 = null;
let D31 = null;
let D32 = null;
let D35 = null;
let D36 = null;
let D39 = null;
let D40 = null;
let D42 = null;
let D43 = null;
let D44 = null;
let D45 = null;

function degreeToRadians($degree)
{
    return $degree * Math.PI / 180;
}

function calcD18() {
    return D18 = D17;
}

function calcD22() {
    //calc D21? Check it
    let result = null;
    if (D21 <= 400) {
        result = 0.4;
    } else if (D21 > 400 && D21 <= 900) {
        result = 1;
    } else if (D21 > 900) {
        result = 3;
    }

    return D22 = result;
}

function calcD30() {
    let result = null;

    if (D12 < 20) {
        result = D12 / 20 * 0.85;
    } else if (D12 <= 30) {
        result = 0.85 - 0.15 / 10 * (D12 - 20);
    } else if (D12 <= 45) {
        result = 0.7 - 0.2 / 15 * (D12 - 30);
    } else if (D12 <= 60) {
        result = 0.5 - 0.05 / 15 * (D12 - 45);
    } else {
        result = 0.45;
    }

    return D30 = result;
}

function calcD31() {
    let result = null;

    result = (D9 / 2) * Math.tan((D12 * Math.PI) / 180);

    return D31 = result;
}

function calcD32() {
    let result = null;

    // check Calc D21
    if (D12 < 20) {
        result = (1 + calcD30()) * D9 / 2 * Math.tan(degreeToRadians(20)) - D31;
    } else {
        result = calcD30() * calcD31();
    }

    return D32 = result;
}

function calcD35() {
    let result = null;

    /**
     * @deprecated 27.05.24

    if (D7 == 1) {
        result = "entfällt";
    } else {
        result = D10 * Math.sin((Math.PI / 180) * D14) + D9 * Math.cos((Math.PI / 180) * D14);
    }*/

    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß

    if (D7 === 1) {
        result = "entfällt";
    } else if (D14 > 90) {
        var angle = (180 - D14) * radian;
        result = D10 * Math.sin(angle) + D9 * Math.cos(angle);
    } else {
        var angle = D14 * radian;
        result = D10 * Math.sin(angle) + D9 * Math.cos(angle);
    }

    return D35 = result;
}

function calcD36() {
    let result = null;
    const calc = calcD35();

    if (calc == "entfällt") {
        result = 6 * D11;
    } else {
        result = 1.75 * calc / (1 + 0.25 * calc / D11);
    }

    return D36 = result;
}

function calcD39() {
    let result = null;
    const cD36 = calcD36();
    if (D16 >= cD36) {
        result = "nicht relevant";
    } else {
        result = Math.sqrt(1 - Math.pow(D16, 2) / Math.pow(cD36, 2));
    }

    return D39 = result;
}

function calcD40() {
    let result = null;

    let cD39 = calcD39();
    if (cD39 == "nicht relevant") {
        result = "nicht relevant";
    } else {

        var berechnung = cD39 * (D11 + calcD32()) + calcD18() - D20;
        result = (berechnung <= 0) ? "nicht relevant" : berechnung;
    }

    return D40 = result;
}

function calcD42() {
    let result = null;
    const cD40 = calcD40();
    const cD22 = calcD22();
    if (cD40 == "nicht relevant") {
        result = "nicht relevant";
    } else {
        // Berechnung prüfen=? calcD22();

        if(cD40+cD22 <= D23) {
            result = "nicht relevant";
        }else{
            result = cD40 + cD22;
        }
    }

    return D42 = result;
}

function calcD43() {
    let result = null;
    const cD42 = calcD42();

    if (cD42 == "nicht relevant") {
        result = "nicht relevant";
    } else {
        if(cD42 <= D23) {
            result = "nicht relevant";
        }else{
            result = cD42 - D23;
        }
    }

    return D43 = result;
}

function calcD44() {
    let result = null;
    if (calcD43() == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = D24 + calcD43();
    }

    return D44 = result;
}


function calcD45() {
    let result = null;

    const cD42 = calcD42();

    if (calcD43() == "nicht relevant") {
        result = "nicht relevant";
    } else {
        // prüfen D22
        result = D20 + cD42;
    }
    return D45 = result;
}

function loadVorgSatteldach(param) {
    switch(param) {
        case "D7":
            return D7;
            break
        case "D9":
            return D9;
        break
        case "D10":
            return D10;
        break
        case "D11":
            return D11;
        break
        case "D12":
            return D12;
        break
        case "D14":
            return D14;
        break
        case "D16":
            return D16;
        break
        case "D17":
            return D17;
        break
        case "D18":
            return calcD18()
        break
        case "D31":
            return calcD31()
        break
        case "D35":
            return calcD35()
        break
        case "D36":
            return calcD36()
        break;
        case "D42":
            return calcD42()
        break;
        case "D43":
            return calcD43()
        break;
        case "D44":
            return calcD44()
        break;
        case "D45":
            return calcD45()
        break;
    }
}

function updateVorgSatteldach(param, value) {
    switch(param) {
        case 'D7':
            D7 = parseInt(value);
        break;
        case 'D20':
            D20 = parseFloat(value)
            break;
        case 'D21':
            D21 = parseFloat(value)
            break;
        case 'D22':
            D22 = parseFloat(value)
            break;
        case 'D23':
            D23 = parseFloat(value)
            break;
        case 'D24':
            D24 = parseFloat(value)
            break;
        case "D9":
            D9 = parseFloat(value.toString().replace(",", "."));
        break;
        case "D10":
            D10 = parseFloat(value.toString().replace(",", "."));
        break;
        case "D11":
            D11 = parseFloat(value.toString().replace(",", "."));
        break;
        case "D12":
            D12 = parseFloat(value.toString().replace(",", "."));
        break;
        case "D14":
            D14 = parseFloat(value.toString().replace(",", "."));
        break;
        case "D16":
            D16 = parseFloat(value.toString().replace(",", "."));
        break;
        case "D17":
            D17 = parseFloat(value.toString().replace(",", "."));
        break;
    }
}

export{
    loadVorgSatteldach, updateVorgSatteldach
}
