let T11 = null;
let T13 = null;
let T15 = null;
let T18 = null;
let T19 = null;

let T21 = null;
let T22 = null;
let T23 = null;
let T24 = null;
let T25 = null;
let T26 = null;
let T36 = null;
let T37 = null;
let T38 = null;
let U35 = null;
let T40 = null;
let T41 = null;
let T42 = null;
let T43 = null;
let T45 = null;
let T46 = null;
let T47 = null;
let T48 = null;
let T49 = null;

let U37 = null;
let U47 = null;
let U49 = null;

function calcT21() {
    return T21 = T19;
}

function calcU49() {
    let result;

    if (U37 == "nicht möglich") {
        result = "nicht möglich";
    } else {
        if (T11 > 0) {
            result = 0 + calcU37();
        } else {
            result = T19 + calcU37();
        }
    }

    return U49 = result;
}

function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

function calcU35() {
    //=WENN(U13<=0,4;0,4;U13*TAN(BOGENMASS(45)))

    let result;

    if (T13 <= 0.4) {
        result = 0.4;
    } else {
        result = T13 * Math.tan(degreesToRadians(45));
    }

    return U35 = result;
}


function calcU37() {
    let result;

    if (T13 < T15 / 4) {
        result = calcT22() + calcU35();
    } else {
        result = "nicht möglich";
    }

    return U37 = result;
}

function calcT22() {

   // =T15/2*TAN(BOGENMASS(20))

    const angleInDegrees = 20; // Replace with your desired angle in degrees
    const angleInRadians = angleInDegrees * (Math.PI / 180); // Convert degrees to radians
    const result = (T15 / 2) * Math.tan(degreesToRadians(20));

    return T22 = result;
}

function calcT23() {
    let result;

    if (T18 <= 400) {
        result = 0.4;
    } else if (T18 > 400 && T18 <= 900) {
        result = 1;
    } else if (T18 > 900) {
        result = 3;
    }

    return T23 = result;
}

function calcT26() {
    const angleInDegrees = 20; // Replace with your desired angle in degrees
    const angleInRadians = angleInDegrees * (Math.PI / 180); // Convert degrees to radians
    const result = (0 + T15 / 2) * Math.tan(angleInRadians);

    return T26 = result;
}

function calcT37() {
    return T37 = calcT23() + calcT26();
}

function calcT38() {
    const result = 1.3 * Math.pow(Math.pow(calcT21(), 2), 1/3) + calcT23();

    return T38 = result;
}

function calcT40() {
    let result;

    if (calcT37() <= calcT38()) {
        result = calcT37();
    } else {
        result = calcT38();
    }

    return T40 = result;
}

function calcT41() {
    return T41 = calcT21() + calcT40();
}

function calcT42() {
    return T42 = calcT21() * 2;
}

function calcT43() {
    let result;

    if (calcT41() <= calcT42()) {
        result = 0;
    } else {
        result = calcT41() - calcT42();
    }

    return T43 = result;
}

function calcT45() {
    let result;

    if (calcT37() == calcT40() && calcT43() == 0) {
        result = "nicht relevant";
    } else {
        result = calcT40() - calcT43();
    }

    return T45 = result;
}

function calcT46() {
    let result;

    if (calcT37() == calcT40() && calcT43() == 0) {
        result = "nicht relevant";
    } else {
        result = calcT21() + calcT45();
    }

    return T46 = result;
}

function calcU47() {
    let result;

    if (T13 < T15 / 4) {
        result = calcT22() + calcT23();
    } else {
        result = "nicht möglich";
    }

    return U47 = result;
}

function calcT47() {
    let result;

    if (T18 == 0) {
        result = 0;
    } else if (T18 > 0 && T18 <= 50) {
        result = 15;
    } else if (T18 > 50 && T18 <= 100) {
        result = 17;
    } else if (T18 > 100 && T18 <= 150) {
        result = 19;
    } else if (T18 > 150 && T18 <= 200) {
        result = 21;
    } else if (T18 > 200 && T18 <= 250) {
        result = 23;
    } else if (T18 > 250 && T18 <= 300) {
        result = 25;
    } else if (T18 > 300 && T18 <= 350) {
        result = 27;
    } else if (T18 > 350 && T18 <= 400) {
        result = 29;
    } else if (T18 > 400 && T18 <= 450) {
        result = 31;
    } else if (T18 > 450 && T18 <= 500) {
        result = 33;
    } else if (T18 > 500 && T18 <= 550) {
        result = 35;
    } else if (T18 > 550 && T18 <= 600) {
        result = 37;
    } else if (T11 > 600 && T18 <= 650) {
        result = 39;
    } else if (T18 > 650 && T18 <= 700) {
        result = 41;
    } else if (T18 > 700 && T18 <= 750) {
        result = 43;
    } else if (T18 > 750 && T18 <= 800) {
        result = 45;
    } else if (T18 > 800 && T18 <= 850) {
        result = 47;
    } else if (T18 > 850 && T18 <= 900) {
        result = 49;
    } else {
        result = 50;
    }

    return T47 = result;
}

function calcT48() {
    let result;

    if (T18 == 0) {
        result = 0;
    } else if (T18 > 0 && T18 <= 50) {
        result = 1;
    } else if (T18 > 50 && T18 <= 100) {
        result = 2;
    } else if (T18 > 100 && T18 <= 200) {
        result = 3;
    } else if (T18 > 200 && T18 <= 400) {
        result = 4;
    } else {
        result = 5;
    }

    return T48 = result;
}

function calcT49() {
    return T49 = calcT21() + calcT37();
}

function updateFlachdach(param, value) {
    console.log(param);
    if(param == "T19") {
        T19 = parseFloat(value.toString().replace(",", "."));
    }
    if(param == "T15") {
        T15 = parseFloat(value.toString().replace(",", "."));
    }
    if(param == "T13") {
        T13 = parseFloat(value.toString().replace(",", "."));
    }
    if(param == "T18") {
        T18 = parseFloat(value.toString().replace(",", "."));
    }
}

function loadFlachdach(param) {

    switch(param) {
        case 'T11':
            return T11
            break;
        case 'T13':
            return T13
            break;
        case 'T15':
            return T15
            break;
        case 'T18':
            return T18
            break;
        case 'T19':
            return T19
            break;
        case 'T21':
            return calcT21()
            break;
        case 'T22':
            return T22
            break;
        case 'T23':
            return calcT23();
            break;
        case 'T24':
            return T24
            break;
        case 'T25':
            return T25
            break;
        case 'T26':
            return T26
            break;
        case 'T36':
            return T36
            break;
        case 'T37':
            return T37
            break;
        case 'T38':
            return T38
            break;
        case 'T40':
            return T40
            break;
        case 'T41':
            return T41
            break;
        case 'T42':
            return T42
            break;
        case 'T43':
            return T43
            break;
        case 'T45':
            return T45
            break;
        case 'T46':
            return T46
            break;
        case 'T47':
            return T47
            break;
        case 'T48':
            return T48
            break;
        case 'T49':
            return T49
            break;
        case 'U37':
            return U37
            break;
    }
}

export{
    loadFlachdach, calcT49, calcT26, calcT38, calcT40, calcT42, calcT43, calcT23, calcT37, calcT41, calcT45, calcT46, calcT48, calcT47, calcT21, calcT22, calcU35, calcU37, calcU49, updateFlachdach
}
