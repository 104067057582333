let V7 = 1;
let V9 = null;
let V10 = null;
let V11 = null;
let V14 = null;
let V16 = null;
let V17 = null;
let V18 = null;

let D20 = null;
let D21 = null;
let D22 = null;
let D23 = null;
let D24 = null;

let V32 = null;
let V35 = null;
let V36 = null;
let V39 = null;
let V40 = null;
let V42 = null;
let V43 = null;
let V44 = null;
let V45 = null;

function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

function calcV18() {
    let result = null;
    result = V17;
    return V18 = result;
}

function calcD22() {
    //calc D21? Check it
    let result = null;
    if (D21 <= 400) {
        result = 0.4;
    } else if (D21 > 400 && D21 <= 900) {
        result = 1;
    } else if (D21 > 900) {
        result = 3;
    }

    return D22 = result;
}

function calcV32() {
    const V9Value = V9;

    const result = (0 + V9Value / 2) * Math.tan((20 * Math.PI) / 180);

    return V32 = result;
}

function calcV35() {
    /**
     *
     * @deprecated 27.05.24

    const V7Value = V7;
    const V10Value = V10;
    const V14Value = V14;

    const result = V7Value == 1 ? "entfällt" : V10Value * Math.sin((V14Value * Math.PI) / 180) + V9 * Math.cos((V14Value * Math.PI) / 180);
     */

    let result = null;

    const radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß

    if (V7 === 1) {
        result = "entfällt";
    } else if (V14 > 90) {
        const angle = (180 - V14) * radian;
        result = V10 * Math.sin(angle) + V9 * Math.cos(angle);
    } else {
        const angle = V14 * radian;
        result = V10 * Math.sin(angle) + V9 * Math.cos(angle);
    }

    return V35 = result;
}

function calcV36() {
    const V35Value = calcV35();
    const V11Value = V11;

    const result = V35Value == "entfällt"
        ? 6 * V11Value
        : (1.75 * V35Value) / (1 + 0.25 * V35Value / V11Value);

    return V36 = result;
}

function calcV39() {
    const V16Value = V16;
    const V36Value =calcV36();

    const result = V16Value >= V36Value ? "nicht relevant" : Math.sqrt(1 - (V16Value ** 2) / (V36Value ** 2));

    return V39 = result;
}

function calcV40() {
    const V39Value = calcV39();
    const V11Value = V11;
    const V32Value = calcV32();
    const V18Value = calcV18();
    const D20Value = D20;

    let result = null;

    if (V39 == "nicht relevant") {
        result = "nicht relevant";
    } else {
        var berechnung = V39Value * (V11Value + V32Value) + V18Value - D20Value;
        result = (berechnung <= 0) ? "nicht relevant" : berechnung;
    }

    return V40 = result;
}

function calcV42() {
    const V40Value = calcV40();
    const D22Value = calcD22();


    let result = null;
    if (V40Value == "nicht relevant") {
        result = "nicht relevant";
    } else {

        // =WENN(V40="nicht relevant";"nicht relevant";WENN((V40+$E22)<=$E23;"nicht relevant";V40+$E22))

        result = (V40Value+D22Value)<=D23 ? "nicht relevant" : V40Value + D22Value;
    }

    return V42 = result;
}

function calcV43() {
    const V42Value = calcV42();
    const D23Value = D23;

    let result = null;

    if (V42Value == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = (V42Value <= D23Value) ? "nicht relevant" : V42Value - D23Value;
    }

    return V43 = result;
}

function calcV44() {
    const D24Value = D24;
    const V43Value = calcV43();

    let result = null;
    if (V43Value == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = D24Value + V43Value;
    }

    return V44 = result;
}

function calcV45() {
    const V42Value = calcV42();
    const V43Value = calcV43();
    const D20Value = D20;

    let result = null;

    if (V43Value == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = D20Value + V42Value;
    }

    return V45 = result;
}

function loadVorgSheddach(param) {
    switch(param) {
        case "V9":
            return V9;
        break;
        case "V10":
            return V10;
        break;
        case "V11":
            return V11;
        break;
        case "V14":
            return V14;
        break;
        case "V16":
            return V16;
        break;
        case "V17":
            return V17;
        break;
        case "V35":
            return calcV35();
        break;
        case "V36":
            return calcV36();
        break;
        case "V42":
            return calcV42();
        break;
        case "V43":
            return calcV43();
        break;
        case "V44":
            return calcV44();
        break;
        case "V45":
            return calcV45();
        break;
    }
}

function updateVorgSheddach(param, value) {
    console.log(param, value)
    switch(param) {
        case 'V7':
            V7 = parseInt(value);
        break;
        case 'V9':
            V9 = parseInt(value);
            break;
        case 'V10':
            V10 = parseInt(value);
            break;
        case 'V11':
            V11 = parseInt(value);
            break;
        case 'V14':
            V14 = parseInt(value);
            break;
        case 'V16':
            V16 = parseInt(value);
            break;
        case 'V17':
            V17 = parseInt(value);
            break;
        case 'D20':
            D20 = parseFloat(value)
            break;
        case 'D21':
            D21 = parseFloat(value)
            break;
        case 'D22':
            D22 = parseFloat(value)
            break;
        case 'D23':
            D23 = parseFloat(value)
            break;
        case 'D24':
            D24 = parseFloat(value)
            break;
        case "V9":
            V9 = parseFloat(value.toString().replace(",", "."));
            break;
        case "V10":
            V10 = parseFloat(value.toString().replace(",", "."));
            break;
        case "V11":
            V11 = parseFloat(value.toString().replace(",", "."));
            break;
        case "V14":
            V14 = parseFloat(value.toString().replace(",", "."));
            break;
        case "V16":
            V16 = parseFloat(value.toString().replace(",", "."));
            break;
        case "V17":
            V17 = parseFloat(value.toString().replace(",", "."));
            break;
    }
}

export{
    loadVorgSheddach, updateVorgSheddach
}
