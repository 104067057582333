import Cookies from 'js-cookie';
import axios from "axios";

class AuthManager {
    login(token, expires_at) {
        Cookies.set('AccessToken', token, {
            expires_at: expires_at
        });

        axios.defaults.headers.common.Authorization = `Bearer ${ token }`;

    }

    logout() {
        Cookies.remove("AccessToken");
    }

    isAuthenticated() {
        return (Cookies.get("AccessToken") != null);
    }
}

export default AuthManager;
