let G7 = 0;

let G9 = null;
let G10 = null;
let G11 = null;
let G12 = null;
let G13 = null;
let G14 = null;
let G15 = null;
let G16 = null;
let G17 = null;
let G18 = null;

let G30 = null;
let G31 = null;
let G32 = null;
let G35 = null;
let G36 = null;

let G39 = null;
let G40 = null;
let G42 = null;
let G43 = null;
let G44 = null;
let G45 = null;

//update from satteldach?
let D20 = null;
let D21 = null;
let D22 = null;
let D23 = null;
let D24 = null;

function degreeToRadians($degree)
{
    return $degree * Math.PI / 180;
}


function calcD22() {
    //calc D21? Check it

    let result = null;
    if (D21 <= 400) {
        result = 0.4;
    } else if (D21 > 400 && D21 <= 900) {
        result = 1;
    } else if (D21 > 900) {
        result = 3;
    }

    return D22 = result;
}


function calcG15() {
    let result = null;

    result = calcG31() / Math.tan(G12 * (Math.PI / 180));

    return G15 = result;
}

function calcG18() {
    return G18 = G17;
}

function calcG30() {
    let result = null;

    if (G12 < 20) {
        result = (G12 / 20 * 0.85);
    } else if (G12 <= 30) {
        result = 0.85 - 0.15 / 10 * (G12 - 20);
    } else if (G12 <= 45) {
        result = 0.7 - 0.2 / 15 * (G12 - 30);
    } else if (G12 <= 60) {
        result = 0.5 - 0.05 / 15 * (G12 - 45);
    } else {
        result = 0.45;
    }

    return G30 = result;
}

function calcG31() {
    let result = null;

    result = (G9 / (Math.sin((180 - G12 - G13) * (Math.PI / 180))) * Math.sin(G12 * (Math.PI / 180))) * Math.sin(G13 * (Math.PI / 180));

    console.log(
        G9+" ; " + G12 + " ; "+ G13
    )

    return G31 = result;
}

function calcG32() {
    let result = null;
    const cG30 = calcG30();
    const cG31 = calcG31();

    if (G12 < 20) {
        result = (1 + cG30) * G15 * Math.tan(degreeToRadians(20)) - cG31;
    } else {
        result = cG30 * cG31;
    }

    return G32 = result;
}

function calcG35() {
    let result = null;

    /**
     * @deprecated 27.05

    if (G7 == 1) {
        result = "entfällt";
    } else {
        result = G10 * Math.sin((G14 * Math.PI) / 180) + G9 * Math.cos((G14 * Math.PI) / 180);
    }
    */

    const radian = Math.PI / 180;

    if (G7 === 1) {
        result = "entfällt";
    } else if (G14 > 90) {
        var angle = (180 - G14) * radian;
        result = G10 * Math.sin(angle) + G9 * Math.cos(angle);
    } else {
        var angle = G14 * radian;
        result = G10 * Math.sin(angle) + G9 * Math.cos(angle);
    }

    return G35 = result;
}

function calcG36() {
    let result = null;

    const  cG35 = calcG35();

    if (cG35 == "entfällt") {
        result = 6 * G11;
    } else {
        result = 1.75 * cG35 / (1 + 0.25 * cG35 / G11);
    }

    return G36 = result;
}

function calcG39() {
    let result = null;
    const cG36 =  calcG36();

    if (G16 >= cG36) {
        result = "nicht relevant";
    } else {
        result = Math.sqrt(1 - (Math.pow(G16, 2) / Math.pow(cG36, 2)));
        //console.log("calcG39", Math.sqrt(1 - (Math.pow(G16, 2) / Math.pow(cG36, 2))))
    }

    return G39 = result;
}

function calcG40() {
    let result = null;
    const cG39 = calcG39();
    const cG32 = calcG32();
    const cG18 = calcG18();



    if (cG39 == "nicht relevant") {
        result =  "nicht relevant";
    } else {
        var berechnung = cG39 * (G11 + cG32) + cG18 - D20;
        //console.log("G40", cG39 +"*"+"("+G11 + " + "+ cG32+") +"+ cG18 +"-"+ D20);
        result = (berechnung <= 0) ? "nicht relevant" : berechnung;
    }

    return G40 = result;
}

function calcG42() {
    let result = null;
    const  cG40 = calcG40();
    const  cD22 = calcD22();

    //=WENN(G40="nicht relevant";"nicht relevant";WENN((G40+$E22)<=$E23;"nicht relevant";G40+$E22))

    console.log("calcG42", cG40 +"+"+ D22 +"+"+ D23)
    result = (cG40 == "nicht relevant") ? "nicht relevant" : cG40 + cD22 <= D23 ? "nicht relevant" :  cG40 + cD22;



    return G42 = result;
}

function calcG43() {
    let result = null;
    const cG42 = calcG42();

    if (cG42 == "nicht relevant") {
        result = "nicht relevant";
    } else {

        //=WENN(G42="nicht relevant";"nicht relevant";WENN(G42<=$E23;"nicht relevant";G42-$E23))

        if(cG42 <= D23) {
            result = "nicht relevant";
        }else{
            result = cG42 - D23;
        }
    }

    return G43 = result;
}


function calcG44() {
    let result = null;
    const cG43 = calcG43();

    if (cG43 == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = D24 + cG43;
    }
    console.log(D24 +"+"+ cG43)

    return G44 = result;
}


function calcG45() {
    let result = null;
    const cG42 = calcG42();

    if (cG42 == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = D20 + cG42;
    }

    return G45 = result;
}

function loadVorgAsyncSatteldach(param) {
    switch(param) {
        case 'G9':
            return G9
        break;
        case 'G10':
            return G10
        break;
        case 'G11':
            return G11
        break;
        case 'G12':
            return G12
        break;
        case 'G13':
            return G13
        break;
        case 'G14':
            return G14
        break;
        case 'G16':
            return G16
        break;
        case 'G17':
            return G17
        break;
        case 'G31':
            return calcG31()
        break;
        case 'G35':
            return calcG35()
        break;
        case 'G36':
            return calcG36()
            break;
        case 'G42':
            return calcG42()
        break;
        case 'G43':
            return calcG43()
        break;
        case 'G44':
            return calcG44()
        break;
        case 'G45':
            return calcG45()
        break;
    }

}

function updateVorgAsyncSatteldach(param, value) {
    switch (param) {
        case 'G7':
            G7 = parseInt(value);
        break;
        case 'D20':
            if(typeof value != "undefined") {
               D20 = parseFloat(value.toString().replace(",", "."))
            }
        break;
        case 'D21':
            D21 = parseFloat(value.toString().replace(",", "."))
            break;
        case 'D22':
            D22 = parseFloat(value.toString().replace(",", "."))
        break;
        case 'D23':
            if(typeof value != "undefined") {
                D23 = parseFloat(value.toString().replace(",", "."))
            }
        break;
        case 'D24':
            if(typeof value != "undefined") {
                D24 = parseFloat(value.toString().replace(",", "."))
            }
        break;
        case 'G9':
            G9 = parseFloat(value.toString().replace(",", "."))
            break;
        case 'G10':
            G10 = parseFloat(value.toString().replace(",", "."))
            break;
        case 'G11':
            G11 = parseFloat(value.toString().replace(",", "."))
            break;
        case 'G12':
            G12 = parseFloat(value.toString().replace(",", "."))
            break;
        case 'G13':
            G13 = parseFloat(value.toString().replace(",", "."))
            break;
        case 'G14':
            G14 = parseFloat(value.toString().replace(",", "."))
            break;
        case 'G16':
            G16 = parseFloat(value.toString().replace(",", "."))
            break;
        case 'G17':
            G17 = parseFloat(value.toString().replace(",", "."))
            break;
    }
}

export{
    loadVorgAsyncSatteldach, updateVorgAsyncSatteldach
}
