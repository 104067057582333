import {loadMassarddach} from "./massarddach";

let J10 = 0;
let J11 = null;
let J12 = null;
let J13 = null;
let J14 = null;
let J15 = null;
let J16 = null;
let J18 = null;
let J19 = null;
let J20 = null;
let J21 = null;
let J23 = null;
let J24 = null;
let J25 = null;
let J26 = null;
let J27 = null;
let J28 = null;
let J29 = null;
let J30 = null;
let J31 = null;
let J32 = null;
let J34 = null;
let J36 = null;
let J37 = null;
let J38 = null;
let J39 = null;
let J40 = null;
let J41 = null;
let J43 = null;
let J47 = null;
let J48 = null;
let J49 = null;
let J50 = null;

let K28 = null;
let K29 = null;
let K30 = null;
let K31 = null;
let K33 = null;
let K34 = null;
let K35 = null;
let K36 = null;
let K37 = null;
let K49 = null;

function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}


function calcJ16() {
    let result;

    if (J12 !== 0) {
        result = calcJ20() / Math.tan(degreesToRadians(J12));
    } else {
        result = 0; // Handle the case when J12 is zero to avoid division by zero
    }

    return J16 = result;
}

function calcJ20() {
    return J20 = (J15 / 2) * Math.tan(degreesToRadians(J11));
}

function calcJ21 () {
    console.log("J21", J19 +"+"+ calcJ20())
    return J21 = J19 + calcJ20();
}

function calcJ23() {

    let result;

    if (J18 <= 400) {
        result = 0.4;
    } else if (J18 > 400 && J18 <= 900) {
        result = 1;
    } else if (J18 > 900) {
        result = 3;
    }

    return J23 = result;
}

function calcJ24() {
    let result;

    if (J11 <= 30) {
        result = 0;
    } else if (J11 <= 45) {
        result = (10 / 15) * (J11 - 30);
    } else if (J11 <= 60) {
        result = 10 + (10 / 15) * (J11 - 45);
    } else {
        result = 20;
    }

    return J24 = result;
}

function calcJ25() {
    let result;

    if (J11 < 20) {
        result = (J11 / 20) * 0.85;
    } else if (J11 <= 30) {
        result = 0.85 - (0.15 / 10) * (J11 - 20);
    } else if (J11 <= 45) {
        result = 0.7 - (0.2 / 15) * (J11 - 30);
    } else if (J11 <= 60) {
        result = 0.5 - (0.05 / 15) * (J11 - 45);
    } else {
        result = 0.45;
    }

    return J25 = result;
}

function calcJ26() {
    let result;
    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
    var winkel20 = 20 * radian; // Winkel von 20 Grad im Bogenmaß

    if (J11 < 20) {
        result = ((J13 + J15 / 2) * Math.tan(winkel20)) - calcJ20();
    } else {
        var bogenmaßJ11 = (J11 - calcJ24()) * radian; // Winkel J11 in Bogenmaß umrechnen
        result = J13 * Math.tan(bogenmaßJ11);
    }

    return J26 = result;

    // OLD 23.04
    if (J11 < 20) {
        result = (J13 + (J15 / 2)) * Math.tan(degreesToRadians(20)) - calcJ20();
    } else {
        result = J13 * Math.tan(degreesToRadians(J11 - calcJ24()));
    }

    return J26 = result;
}

function calcJ27() {
    let result;
    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
    var winkel20 = 20 * radian; // Winkel von 20 Grad im Bogenmaß

    if (J11 < 20) {
        return ((1 + calcJ25()) * J15 / 2) * Math.tan(winkel20) - calcJ20();
    } else {
        return calcJ25() * calcJ20();
    }
    return J27 = result;
    // OLD 23.04
    if (J11 < 20) {
        result = (1 + calcJ25()) * (J15 / 2) * Math.tan(degreesToRadians(20)) - calcJ20();
    } else {
        result = calcJ25() * calcJ20();
    }
    return J27 = result;
}

function calcJ28() {
    return J28 = J15 / (2 - J13);
}

function calcJ29() {
    let result;

    if (J12 < 20) {
        result = calcJ28() * Math.tan(degreesToRadians(20)); // Replace BOGENMASS with the correct function
    } else {
        result = 0;
    }

    return J29 = result;
}

function calcJ30() {
    let result;

    if (J12 < 20) {
        result = calcJ28() * Math.tan(degreesToRadians(calcJ20())); // Replace BOGENMASS with the correct function
    } else {
        result = 0;
    }

    return J30 = result;
}

function calcJ31() {
    let result;

    if (J11 < 20) {
        result = calcJ29() - calcJ30();
    } else {
        result = 0;
    }

    return J31 = result;
}

function calcJ32() {
    return J32 = J13 * Math.tan(J11 * (Math.PI / 180));
}

function calcJ34() {
    let result;

    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
    var bogenmaßJ12 = J12 * radian; // Winkel J12 in Bogenmaß umrechnen

    if (J14 <= 0) {
        result = 0;
    } else {
        result = J14 * Math.tan(bogenmaßJ12);
    }

    return J34 = result;

    // OLD 23.04
    if (J14 <= 0) {
        result = 0;
    } else if (J12 < 20) {
        result = calcJ29() + J14 * Math.tan(20 * (Math.PI / 180));
    } else {
        result = J14 * Math.tan(J12 * (Math.PI / 180));
    }

    return J34 = result;
}

function calcJ36() {
    let result;

    if (J14 > 0 && calcJ26() < calcJ27()) {
        console.log("---!----111! J23", calcJ23());
        console.log("---!----111! J26", calcJ26());
        console.log("---!----111! J27", calcJ27());
        result = calcJ23() + calcJ26() + calcJ34();
    } else if (J14 <= 0 && calcJ26() < calcJ27()) {
        console.log("---!----!");
        console.log("---!----111! J23", calcJ23());
        console.log("---!----111! J26", calcJ26());
        console.log("---!----111! J27", calcK33());
        result = calcJ23() + calcJ26() + calcK33();
    } else {
        result = calcJ23() + calcJ27() + calcK33();
        console.log("---!----111");
    }


    return J36 = result;
}

function calcJ37() {
    let result;

    if (J12 > 0 && calcJ26() < calcJ27()) {
        result = calcJ23() + calcJ26();
    } else if (J12 <= 0 && calcJ26() < calcJ25()) {
        result = calcJ23() + calcJ26();
    } else {
        result = calcJ23() + calcJ27();
    }

    return J37 = result;
}

function calcJ39() {
    let result;
    console.log(J15)
    console.log(J18)
    console.log(J15/J18)
    console.log(J11 == J12)

    if (J15 / J38 <= 1 && J15 / J38 > 0.833333 && J11 == J12) {
        result = J15 / J38;
    } else {
        result = "nicht relevant";
    }

    return J39 = result;
}

function calcJ40() {
    let result;

    //=WENN(J39="nicht relevant";"nicht relevant";(J36-J23)*0,6+J23)
    if (calcJ39() == "nicht relevant") {
        result = "nicht relevant";
    } else {
       // result = calcJ32() + calcJ41();
        result = ((calcJ36() - calcJ23()) * 0.6) + calcJ23();
    }

    return J40 = result;
}

function calcJ41() {
    let result;

    if (calcJ39() == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = (calcJ37() - calcJ23()) * 0.6 + calcJ23();
    }

    return J41 = result;
}

function calcJ43() {
    let result;

    if (J14 > 0) {
        result = calcJ34() / Math.tan(J11 * Math.PI / 180);  // Convert degrees to radians
    } else {
        result = "nicht relevant";
    }

    return J43 = result;
}

function calcJ47() {
    let result;

    if (J18 == 0) {
        result = 0;
    } else if (J18 > 0 && J18 <= 50) {
        result = 15;
    } else if (J18 > 50 && J18 <= 100) {
        result = 17;
    } else if (J18 > 100 && J18 <= 150) {
        result = 19;
    } else if (J18 > 150 && J18 <= 200) {
        result = 21;
    } else if (J18 > 200 && J18 <= 250) {
        result = 23;
    } else if (J18 > 250 && J18 <= 300) {
        result = 25;
    } else if (J18 > 300 && J18 <= 350) {
        result = 27;
    } else if (J18 > 350 && J18 <= 400) {
        result = 29;
    } else if (J18 > 400 && J18 <= 450) {
        result = 31;
    } else if (J18 > 450 && J18 <= 500) {
        result = 33;
    } else if (J18 > 500 && J18 <= 550) {
        result = 35;
    } else if (J18 > 550 && J18 <= 600) {
        result = 37;
    } else if (J10 > 600 && J18 <= 650) {
        result = 39;
    } else if (J18 > 650 && J18 <= 700) {
        result = 41;
    } else if (J18 > 700 && J18 <= 750) {
        result = 43;
    } else if (J18 > 750 && J18 <= 800) {
        result = 45;
    } else if (J18 > 800 && J18 <= 850) {
        result = 47;
    } else if (J18 > 850 && J18 <= 900) {
        result = 49;
    } else {
        result = 50;
    }

    return J47 = result;
}

function calcJ48() {
    let result;

    if (J18 == 0) {
        result = 0;
    } else if (J18 > 0 && J18 <= 50) {
        result = 1;
    } else if (J18 > 50 && J18 <= 100) {
        result = 2;
    } else if (J18 > 100 && J18 <= 200) {
        result = 3;
    } else if (J18 > 200 && J18 <= 400) {
        result = 4;
    } else {
        result = 5;
    }

    return J48 = result;
}

function calcJ49() {
    return J49 = calcJ21() + calcJ37();
}

function calcJ50() {
    let result;

    if (calcJ39() <= 1 && calcJ39() > 0.8333333) {
        result = calcJ21() + calcJ41();
    } else {
        result = "nicht relevant";
    }

    return J50 = result;
}

function calcK28() {
    let result;

    if (J14 <= 0) {
        result = J15/2 - J13;
    } else {
        result = calcJ16() - J14;
    }

    return K28 = result;
}

function calcK29() {
    let result;

    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
    var winkel20 = 20 * radian; // Winkel von 20 Grad im Bogenmaß

    if (J11 < 20) {
        result = J15 / 2 * Math.tan(winkel20);
    } else {
        result =  0;
    }
    return K29 = result;

    if (J11 < 20 || J12 < 20) {
        result = calcK28() * Math.tan(20 * (Math.PI / 180)); // Converts degrees to radians
    } else {
        result = 0;
    }
    return K29 = result;
}

function calcK30() {
    let result;

    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß

    if (J11 < 20) {
        const winkelK11 = J11 * radian; // Umrechnung des Winkels K11 in Bogenmaß
        result = J15 / 2 * Math.tan(winkelK11);
    } else {
        result = 0;
    }
    return K30 = result;

    // OLD 25.04
    if (J14 <= 0 && J11 < 20) {
        result = calcK28() * Math.tan(J11 * (Math.PI / 180)); // Converts degrees to radians
    } else {
        if (J14 > 0 && J12 < 20) {
            result = calcK28() * Math.tan(J12 * (Math.PI / 180)); // Converts degrees to radians
        } else {
            result = 0;
        }
    }
    return K30 = result;
}

function calcK31() {
    let result;
    console.log("K31", calcK29() +"-"+ calcK30())
    if (J11 < 20) {
        result = calcK29() - calcK30();
    } else {
        result = 0;
    }
    return K31 = result;
}

function calcK33() {
    const radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
    const bogenmaßJ11 = J11 * radian; // Winkel J11 in Bogenmaß umrechnen

    return K33 = J13 * Math.tan(bogenmaßJ11);

    // OLD 23.04
    var winkel = (J11 >= 20) ? J11 : 20;
    return K33 = J13 * Math.tan(winkel * Math.PI / 180);

    /**
     * OLD
    if (J11 >= 20) {
        result = J13 * Math.tan(J11 * (Math.PI / 180)); // Converts degrees to radians
    } else {
        result = J13 * Math.tan(20 * (Math.PI / 180)); // Converts degrees to radians
    }
    return K33 = result;**/
}

function calcK34() {
    let result;
    if (J14 <= 0) {
        result = 0;
    } else {
        var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
        var bogenmaßJ12 = J12 * radian; // Winkel J12 in Bogenmaß umrechnen
        result = J14 * Math.tan(bogenmaßJ12);
    }

    return K34 = result;
    // OLD 23.04


    if (J12 >= 20) {
        result = J14 * Math.tan(J12 * (Math.PI / 180)); // Converts degrees to radians
    } else {
        result = J14 * Math.tan(20 * (Math.PI / 180)); // Converts degrees to radians
    }

    return K34 = result;
}

function calcK35() {
    let result;

    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
    var winkel45 = 45 * radian; // Winkel von 45 Grad im Bogenmaß

    if (J13 <= 0.4) {
        result = 0.4;
    } else {
        result = J13 * Math.tan(winkel45);
    }

    return K35 = result;

    if (J13 <= 0.4) {
        result = 0.4;
    } else {
        result = J13 * Math.tan(45 * (Math.PI / 180)); // Converts degrees to radians
    }

    return K35 = result;
}

function calcK36() {
    let result;
    const cK31 = calcK31();
    const cK33 = calcK33();
    const cK34 = calcK34();
    const cK35 = calcK35();

    console.log("J14", J14);
    console.log("K36 RESULT", cK31 +"+"+ cK33 +"+"+ cK35)
    console.log("K36 RESULT", cK31 + cK33 + cK35)
    if (J14 > 0) {
        result = cK31 + cK34 + cK35;
    } else {
        if (J13 < J15 / 4) {
            result = cK31 + cK33 + cK35;
        } else {
            result = "nicht möglich";
        }
    }

    /*
    if (J14 > 0 && J14 < calcJ16()/2) {
        result = calcK31() + calcK34() + calcK35();
    } else {
        console.log(J14 +"<="+ 0 +"&&" + J13 +"<"+ J15/4)
        if (J14 <= 0 && J13 < J15/4) {

            result = calcK31() + calcK33() + calcK35();
        } else {
            result = "nicht möglich";
        }

    }*/

    return K36 = result;
}

function calcK37() {
    let result;

    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
console.log("K37", calcK36()+" "+calcK35())
    if (K36 === "nicht möglich") {
        result =  "nicht möglich";
    } else if (J11 < 20 && J14 > 0) {
        result =  calcK36() - J14 * Math.tan(J12 * radian);
    } else if (J11 < 20 && J14 <= 0) {
        result =  calcK36() - J13 * Math.tan(J11 * radian);
    } else {
        result =  calcK35();
    }
    return K37 = result;
    // OLD 25.04
    const cK36 = calcK36();
    const cK35 = calcK35();

    if (cK36 == "nicht möglich") {
        return "nicht möglich";
    } else {
        if (J11 < 20 && J14 > 0) {
            result = cK36 - J14 * Math.tan(J12 * Math.PI / 180);
        } else if (J11 < 20 && J14 <= 0) {
            result = cK36 - J13 * Math.tan(J11 * Math.PI / 180);
        } else {
            result = cK35;
        }
    }
    /*
    if (calcK36() == "nicht möglich") {
        result = "nicht möglich";
    } else {
        if (J11 < 20) {
            result = calcK36() - J13 * Math.tan(J11 * (Math.PI / 180)); // Converts degrees to radians
        } else {
            result = J13 * Math.tan(45 * (Math.PI / 180)); // Converts degrees to radians
        }
    }*/

    return K37 = result;
}

function calcK49() {
    let result;
    if (calcK37() == "nicht möglich") {
        result = "nicht möglich";
    } else {
        result = calcJ21() + calcK37();
    }
    return K49 = result;
}

function loadWalmdach(param) {

    switch(param) {
        case "J11":
            return J11;
            break;
        case "J12":
            return J12;
            break;
        case "J13":
            return J13;
            break;
        case "J14":
            return J14;
            break;
        case "J15":
            return J15;
            break;
        case "J16":
            return calcJ16();
        break;
        case "J18":
            return J18;
        case "J36":
            return calcJ36();
        break;
        case "J37":
            return calcJ37();
        break;
        case "K36":
            return calcK36();
        break;
        case "K37":
            return calcK37();
        break;
        case "J39":
            return calcJ39();
            break;
        case "J40":
            return calcJ40();
        break;
        case "J41":
            return calcJ41();
        break;
        case "J50":
            return calcJ50();
        break;
        case "J21":
            return calcJ21();
        break;
        case "J49":
            return calcJ49();
        break;
        case "K49":
            return calcK49();
        break;
        case "J47":
            return calcJ47()
            break;
        case "J20":
            return calcJ20();
        break;
        case "J43":
            return calcJ43();
        break;
        case "J48":
            return calcJ48();
        break;
    }
}

function updateWalmdach(param, value) {
    switch(param) {
        case "J11":
            J11 = parseFloat(value.toString().replace(",", "."));
        break;
        case "J12":
            J12 = parseFloat(value.toString().replace(",", "."));
        break;
        case "J15":
            J15 = parseFloat(value.toString().replace(",", "."));
        break;
        case "J38":
            J38 = parseFloat(value.toString().replace(",", "."));
        break;
        case "J13":
            J13 = parseFloat(value.toString().replace(",", "."));
        break;
        case "J14":
            J14 = parseFloat(value.toString().replace(",", "."));
        break;
        case "J18":
            J18 = parseFloat(value.toString().replace(",", "."));
        break;
        case "J19":
            J19 = parseFloat(value.toString().replace(",", "."));
        break;
    }
}

export{
    loadWalmdach, updateWalmdach
}
