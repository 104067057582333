let D10 = "Eingabe";
let D11 = null;;
let D13 = null;
let D15 = null;
let D18 = null;
let D19 = null;
let D20 = null;
let D21 = null;
let D23 = null;
let D24 = null;
let D25 = null;
let D26 = null;
let D27 = null;
let D28 = null;
let D32 = null;
let D36 = null;
let D37 = null;
let D39 = null;
let D40 = null;
let D41 = null;
let D47 = null;
let D48 = null;
let D49 = null;

let E22 = null;
let E28 = null;
let E29 = null;
let E30 = null;
let E31 = null;
let E33 = null;
let E35 = null;
let E36 = null;
let E37 = null;
let E49 = null;

function calcD20() {
    return D20 = (D15 / 2) * Math.tan(D11 * (Math.PI / 180));
}

function calcD21() {
    return D21 = D19 + calcD20();
}

function calcD23() {
    let result;

    if (D18 <= 400) {
        result = 0.4;
    } else if (D18 > 400 && D18 <= 900) {
        result = 1;
    } else if (D18 > 900) {
        result = 3;
    }

    return D23 = result;
}

function calcD24() {
    let result;

    if (D11 <= 30) {
        result = 0;
    } else if (D11 <= 45) {
        result = (10 / 15) * (D11 - 30);
    } else if (D11 <= 60) {
        result = 10 + (10 / 15) * (D11 - 45);
    } else {
        result = 20;
    }

    return D24 = result;
}

function calcD25() {
    let result;

    if (D11 < 20) {
        result = (D11 / 20) * 0.85;
    } else if (D11 <= 30) {
        result = 0.85 - (0.15 / 10) * (D11 - 20);
    } else if (D11 <= 45) {
        result = 0.7 - (0.2 / 15) * (D11 - 30);
    } else if (D11 <= 60) {
        result = 0.5 - (0.05 / 15) * (D11 - 45);
    } else {
        result = 0.45;
    }

    return D25 =result.toFixed(2);
}

function calcD26() {
    let result;

    if (D11 === 0) {
        result = (0 + D15 / 2) * Math.tan(20 * (Math.PI / 180)) - calcD20();
    } else if (D11 < 20) {
        result = (D13 + D15 / 2) * Math.tan(20 * (Math.PI / 180)) - calcD20();
    } else {

        result = D13 * Math.tan((D11 - calcD24()) * (Math.PI / 180));
    }
    return D26 = result;
}

function calcD27() {
    let result;

    if (D11 < 20) {
        result = (1 + calcD25()) * (D15 / 2) * Math.tan(20 * (Math.PI / 180)) - calcD20();
    } else {
        result = calcD25() * calcD20();
    }

    return D27 = result;
}

function calcD28() {
    let result;

    if (D15 / 2 - D13 < 0) {
        result = D15 / 2;
    } else {
        result = D15 / 2 - D13;
    }

    return D28 = result;
}

function calcD32() {
    const result = D13 * Math.tan(D11 * (Math.PI / 180));

    return D32 = result;
}

function calcD36() {
    let result;


    if (calcD26() > calcD27()) {
        result = calcD23() + calcD27() + calcD32();
    } else {
        console.log(calcD23() +"+"+ calcD26() +"+"+ calcD32())
        result = calcD23() + calcD26() + calcD32();
    }

    return D36 = result;
}

function calcD37() {
    let result;

    if (calcD26() > calcD27()) {
        result = calcD23() + calcD27();
    } else {
        result = calcD23() + calcD26();
    }

    return D37 = result;
}

function calcD39() {
    return D39 = calcD21() + calcD37()
}

function calcD40() {
    let result;

    if (calcD39() < calcD21() * 2) {
        result = "nicht relevant";
    } else {
        result = calcD21() * 2;
    }

    return D40 = result;
}

function calcD41() {
    let result;

    if (calcD39() <= calcD40()) {
        result = 0;
    } else {
        result = calcD39() - calcD40();
    }

    return D41 = result;
}

function calcD47() {
    let result;

    if (D18 === 0) {
        result = 0;
    } else if (D18 > 0 && D18 <= 50) {
        result = 15;
    } else if (D18 > 50 && D18 <= 100) {
        result = 17;
    } else if (D18 > 100 && D18 <= 150) {
        result = 19;
    } else if (D18 > 150 && D18 <= 200) {
        result = 21;
    } else if (D18 > 200 && D18 <= 250) {
        result = 23;
    } else if (D18 > 250 && D18 <= 300) {
        result = 25;
    } else if (D18 > 300 && D18 <= 350) {
        result = 27;
    } else if (D18 > 350 && D18 <= 400) {
        result = 29;
    } else if (D18 > 400 && D18 <= 450) {
        result = 31;
    } else if (D18 > 450 && D18 <= 500) {
        result = 33;
    } else if (D18 > 500 && D18 <= 550) {
        result = 35;
    } else if (D18 > 550 && D18 <= 600) {
        result = 37;
    } else if (D11 > 600 && D18 <= 650) {
        result = 39;
    } else if (D18 > 650 && D18 <= 700) {
        result = 41;
    } else if (D18 > 700 && D18 <= 750) {
        result = 43;
    } else if (D18 > 750 && D18 <= 800) {
        result = 45;
    } else if (D18 > 800 && D18 <= 850) {
        result = 47;
    } else if (D18 > 850 && D18 <= 900) {
        result = 49;
    } else {
        result = 50;
    }

    return D47 = result;
}


function calcD48() {
    let result;

    if (D18 === 0) {
        result = 0;
    } else if (D18 > 0 && D18 <= 50) {
        result = 1;
    } else if (D18 > 50 && D18 <= 100) {
        result = 2;
    } else if (D18 > 100 && D18 <= 200) {
        result = 3;
    } else if (D18 > 200 && D18 <= 400) {
        result = 4;
    } else {
        result = 5;
    }
    return D48 = result;
}

function calcD49() {
    let result = calcD21() + calcD37()
    return D47 = result;
}

function calcE28() {
    return E28 = D15 / 2 - D13;
}

function calcE29() {
    let result;

    if (D11 < 20) {
        result = calcE28() * Math.tan(20 * (Math.PI / 180));
    } else {
        result = 0;
    }

    return E29 = result;
}

function calcE30() {
    let result;

    if (D11 < 20) {
        result = calcE28() * Math.tan(D11 * (Math.PI / 180));
    } else {
        result = 0;
    }

    return E30 = result;
}

function calcE31() {
    let result;

    if (D11 < 20) {
        result = calcE29() - calcE30();
    } else {
        result = 0;
    }

    return E31 = result;
}

function calcE22() {
    return E22 = (D15 / 2) * Math.tan(20 * (Math.PI / 180));
}

function calcE33() {
    let result;

    if (D11 >= 20) {
        result = D13 * Math.tan((D11 * Math.PI) / 180); // Convert degrees to radians
    } else {
        result = D13 * Math.tan((20 * Math.PI) / 180); // Calculate tangent for 20 degrees (converted to radians)
    }

    return E33 = result;
}

function calcE35() {
    let result;

    if (D13 <= 0.4) {
        result = 0.4;
    } else {
        result = D13 * Math.tan((45 * Math.PI) / 180); // Converting degrees to radians
    }

    return E35 = result
}

function calcE36() {
    let result;

    if (D13 < (D15 / 4)) {
        result = calcE31() + calcE33() + calcE35();
    } else {
        result = "nicht möglich";
    }

    return result;

    //OLD 22.04
    if (D11 <= 0 && D13 < (D15 / 4)) {
        result = calcE22() + calcE35();
    } else {
        if (D11 > 0 && D13 < (D15 / 4)) {
            result = calcE31() + calcE33() + calcE35();

        } else {
            result = "nicht möglich";
        }
    }

    return E36 = result;
}

function calcE37() {
    let result;


    if (calcE36() === "nicht möglich") {
        return "nicht möglich";
    } else {
        if (D11 < 20) {
            result = calcE36() - D13 * Math.tan(D11 * Math.PI / 180);
        } else {
            result = calcE35();
        }
    }

    return result;
    //OLD 22.04
    const cE36 = calcE36();
    console.log("cE36", cE36)
    if (cE36 == "nicht möglich") {
        result = "nicht möglich";
    } else {
        if (D13 <= 0.4) {
            result = 0.4;
        } else {
            result = D13 * Math.tan(45 * Math.PI / 180);
        }
    }
    /*
    if (E36 === "nicht möglich") {
        result = "nicht möglich";
    } else {
        if (D11 < 20) {
            result = calcE36() - (D13 * Math.tan(D11 * (Math.PI / 180))); // Converts degrees to radians
        } else {
            result = D13 * Math.tan(45 * (Math.PI / 180)); // Converts degrees to radians
        }
    }*/

    return E37 = result;
}

function calcE49() {

    return E49 = (calcE37().toString().indexOf("nicht") != -1) ? "nicht möglich" :  calcD21() + calcE37();
  // return E49 = (calcE37().toString().indexOf("nicht") != -1) ? "nicht möglich" : Math.round(result * 10) / 10;
}

function loadSatteldach(param) {
    switch(param) {
        case "D13":
            return D13
            break;
        case "D15":
            return D15
            break;
        case "D18":
            return D18
            break;
        case "D36":
            return calcD36()
        break;
        case "D37":
            return calcD37()
        break;
        case "E36":
            return calcE36()
        break;
        case "D20":
            return calcD20()
            break;
        case "D21":
            return calcD21()
        break;
        case "D23":
            return calcD23()
        break
        case "D24":
            return calcD24()
            break
        case "D49":
            return calcD49()
        break
        case "D47":
            return calcD47()
        break
        case "D48":
            return calcD48()
        break
        case "E37":
            return calcE37()
        break
        case "E49":
            return calcE49()
        break

    }
}

function updateSatteldach(param, value) {
    switch(param) {
        case "D11":
            D11 = parseFloat(value.toString().replace(",", "."));
        break;
        case "D13":
            D13 = parseFloat(value.toString().replace(",", "."));
        break;
        case "D15":
            D15 = parseFloat(value.toString().replace(",", "."));
            break;
        case "D18":
            D18 = parseFloat(value.toString().replace(",", "."));
            break;
        case "D19":
            D19 = parseFloat(value.toString().replace(",", "."));
            break;
    }
}

export{
    loadSatteldach, updateSatteldach,calcD20, calcD21, calcD23, calcD24, calcD25, calcD26, calcD27, calcD28, calcD32, calcD36, calcD37, calcD39, calcD40, calcD41, calcD47, calcD48, calcD49
}
