import React from 'react';
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import {BASE_URL} from "./Constants";
import moment from "moment";

class Home extends React.Component {

    state = {
        isLoading: false,
        draft: [],
        done: []
    }

    getCookie(key) {
        var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    }

    componentDidMount() {
        if(this.getCookie("user") != "") {
            const formData = new FormData();
            formData.append('user_id', this.getCookie("user"));
            formData.append('partner', this.getCookie("partner"));
            axios.post(BASE_URL + '/project/list', formData).then((response) => {
                console.log((response))
                this.setState({done: response.data.done, draft: response.data.draft});
            });
        }
        this.loadContent();
    }

    deleteProject(id) {
        if(window.confirm('Willst du den Datensatz wirklich löschen?')){
            this.setState({isLoading: true})
            axios.get(BASE_URL+'/project/delete/'+id).then(e => window.location.href = "/").catch((e) => console.log(JSON.stringify(e)));
        }
    }

    checkisnull(val) {
        if(val == null) {
            return "-";
        }
        return val;
    }

    loadContent() {return <div>
        <div>
            <div className="titlebar" style={{margin: 0}}>
                <p className="titlebar-title">Offene Projekte</p>
            </div>

            <div style={{ display: "flex", flexWrap: "nowrap", overflowX: "scroll", paddingBottom: 25}}>

                <div style={{display: "inline-block", marginRight: 30, }} onClick={() => window.location = "/add"}>
                    <div style={{backgroundColor: "#296BC9", width: 100, textAlign: "center", cursor: "pointer", height: 100, borderRadius: 10}}>
                        <div style={{backgroundColor: "#296BC9", color: '#fff', fontSize: 12, textAlign: "center", paddingTop: 4, height: 20, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                            <img src={"/add_plus.svg"} style={{width: 25, marginTop: 25}}/>
                            <div style={{textAlign: "center"}}>Neu</div>
                        </div>
                    </div>
                </div>
                {
                    this.state.draft.map(item => <div><div onClick={() => window.location = "/add?id=" + item.project_id} style={{display: "inline-block", marginRight: 30, cursor: "pointer" }}>
                        <div style={{backgroundColor: "#eee", width: 150, height: 100, borderRadius: 10, borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
                            <div style={{backgroundColor: "#296BC9", color: '#fff', fontSize: 12, paddingTop: 4, paddingLeft: 10, height: 20, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                                {item.project_id}
                            </div>
                            <div style={{paddingLeft: 10, paddingTop: 5, margin:0, fontSize: 12}}>
                                {moment(item.date_from).format("DD.MM.Y")}
                            </div>
                            <div style={{paddingLeft: 10, paddingTop: 5, margin:0, fontSize: 12}}>
                                {item.name ?? "Kein Name"}
                            </div>
                            <div style={{paddingLeft: 10, paddingTop: 15, margin:0, fontSize: 12}}>
                                {item.user_data ?? "-"}
                            </div>
                        </div>
                    </div>
                        <div onClick={() => this.deleteProject(item.project_id)} style={{width: 140,cursor: "pointer", backgroundColor: "darkred", fontWeight: "bold",padding: 5, fontSize: 12, borderRadius: 10, borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                            <div style={{marginLeft: 5, color: '#fff'}}>löschen</div>
                        </div>
                    </div>)
                }

            </div>
        </div>
        <div>
            <div className="titlebar" style={{margin: 0}}>
                <p className="titlebar-title">Abgeschlossene Projekte</p>
            </div>

            <div style={{ display: "flex", flexWrap: "nowrap", overflowX: "scroll", paddingBottom: 25}}>

                {
                    this.state.done.length == 0 ? <p>Keine Projekte gefunden</p> : this.state.done.map(item => <div><div onClick={() => window.location = "/add?id=" + item.project_id} style={{ display: "inline-block", marginRight: 30, }}>
                        <div style={{cursor: "pointer",backgroundColor: "#eee", width: 150, height: 100, borderRadius: 10}}>
                            <div style={{backgroundColor: "#296BC9", color: '#fff', fontSize: 12, paddingTop: 4, paddingLeft: 10, height: 20, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                                {item.project_id}
                            </div>
                            <div style={{paddingLeft: 10, paddingTop: 5, margin:0, fontSize: 12}}>
                                {moment(item.date_from).format("DD.MM.Y")}
                            </div>
                            <div style={{paddingLeft: 10, paddingTop: 5, margin:0, fontSize: 12}}>
                                {item.name ?? "Kein Name"}
                            </div>
                            <div style={{paddingLeft: 10, paddingTop: 15, margin:0, fontSize: 12}}>

                                {item.user_data == null ? "-" : this.checkisnull(JSON.parse(item.user_data).project_firstname) + " " + this.checkisnull(JSON.parse(item.user_data).project_lastname)}
                            </div>

                        </div>

                    </div>
                        <div onClick={() => this.deleteProject(item.project_id)} style={{width: 140, cursor: "pointer", backgroundColor: "darkred", fontWeight: "bold",padding: 5, fontSize: 12, borderRadius: 10, borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                            <div style={{marginLeft: 5, color: '#fff'}}>löschen</div>
                        </div>
                        {item.partner_id == null || item.pdf_allow == null ? null : <div onClick={() => {
                            //window.location.href = item.pdf;
                            window.open(item.pdf+"?user="+this.getCookie("user"), '_blank');
                        }} style={{backgroundImage: 'url("/pdf_icon.svg")', cursor: "pointer", width: 140, backgroundSize: 10, backgroundPosition: "10px 5px",backgroundRepeat: "no-repeat",backgroundColor: "#FABF00", marginTop: 5, fontWeight: "bold",padding: 5, fontSize: 12, borderRadius: 10}}>
                            <div style={{marginLeft: 20}}>Exportieren</div>
                        </div>}
                        </div>)
                }

            </div>
        </div>

        </div>
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }

    render() {
        return (
            <div className="wrapper">


                <div className="content">
                    {
                        this.state.isLoading ? this.LoadingView() : this.loadContent()
                    }
                </div>
            </div>
        );
    }
}

export default Home;
