let S7 = 0;
let S9 = null;
let S10 = null;
let S11 = null;
let S14 = null;
let S16 = null;
let S17 = null;
let S18 = null;

//update from satteldach?
let D20 = null;
let D21 = null;
let D22 = null;
let D23 = null;
let D24 = null;

let S32 = null;

let S35 = null;
let S36 = null;
let S39 = null;
let S40 = null;
let S42 = null;
let S43 = null;
let S44 = null;
let S45 = null;

function degreeToRadians($degree)
{
    return $degree * Math.PI / 180;
}

function calcS18() {
    return S18 = S17;
}

function calcD22() {
    let result = null;
    if (D21 <= 400) {
        result = 0.4;
    } else if (D21 > 400 && D21 <= 900) {
        result = 1;
    } else if (D21 > 900) {
        result = 3;
    }


    return D22 = result;
}

function calcS32() {
    const S9Value = S9;

    const result = (0 + S9Value / 2) * Math.tan((20 * Math.PI) / 180);

    return S32 = result;
}

function calcS35() {
    /**
     *
     * @deprecated 27.05.24

    const S7Value = S7;
    const S10Value = S10;
    const S14Value = S14;
    const S9Value = S9;

    const result = S7Value == 1 ? "entfällt" : S10Value * Math.sin(S14Value * (Math.PI / 180)) + S9Value * Math.cos(S14Value * (Math.PI / 180));
     */
    let result = null;
    const radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß

    if (S7 === 1) {
        result = "entfällt";
    } else if (S14 > 90) {
        var angle = (180 - S14) * radian;
        result = S10 * Math.sin(angle) + S9 * Math.cos(angle);
    } else {
        var angle = S14 * radian;
        result = S10 * Math.sin(angle) + S9 * Math.cos(angle);
    }
    return S35 = result;
}

function calcS36() {
    const S35Value = calcS35();
    const S11Value = S11;

    const result = S35Value == "entfällt" ? 6 * S11Value : (1.75 * S35Value) / (1 + 0.25 * (S35Value / S11Value));

    return S36 = result;
}

function calcS39() {
    const S16Value = S16;
    const S36Value = calcS36();

    let result;

    if (S16Value >= S36Value) {
        result = "nicht relevant";
    } else {
        result = Math.sqrt(1 - (S16Value ** 2) / (S36Value ** 2));
    }

    return S39 = result;
}

function calcS40() {
    const S39Value = calcS39();
    const S11Value = S11;
    const S32Value = calcS32();
    const S18Value = calcS18();
    const D20Value = D20;

    let result;

    if (S39Value == "nicht relevant") {
        result = "nicht relevant";
    } else {
        const intermediateResult = S39Value * (S11Value + S32Value) + S18Value - D20Value;
        result = intermediateResult <= 0 ? "nicht relevant" : intermediateResult;
    }

    return S40 = result;
}

function calcS42() {
    const S40Value = calcS40();
    const D22Value = calcD22();
    const D23Value = D23;
    console.log(S40Value);
    console.log(D22Value);
    console.log(D23);
    const result = S40Value == "nicht relevant" ? "nicht relevant" : (S40Value + D22Value) <= D23Value ? "nicht relevant" : S40Value + D22Value;

    return S42 = result;
}

function calcS43() {
    const S42Value = calcS42();
    const D23Value = D23;

    let result = null;
    if (S42 == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = (S42Value <= D23Value) ? "nicht relevant" : S42Value - D23Value;
    }

    return S43 = result;
}

function calcS44() {
    const S43Value = calcS43();
    let result = null;
    if (S43Value == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = D24 + S43Value;
    }

    return S44 = result;
}

function calcS45() {
    const S42Value = calcS42();
    const D20Value = D20;

    let result = null;

    if (calcS43() == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = D20Value + S42Value;
    }

    return S45 = result;
}

function loadVorgFlachdach(param) {
    switch (param) {
        case 'S9':
            return S9
            break;
        case 'S10':
            return S10
            break;
        case 'S11':
            return S11
            break;
        case 'S14':
            return S14
            break;
        case 'S16':
            return S16
            break;
        case 'S17':
            return S17
        break;
        case 'S35':
            return calcS35()
        break;
        case 'S36':
            return calcS36()
        break;
        case 'S42':
            return calcS42()
        break;
        case 'S43':
            return calcS43()
        break;
        case 'S44':
            return calcS44()
        break;
        case 'S45':
            return calcS45()
        break;
    }
}

function updateVorgFlachdach(param, value) {
    switch (param) {
        case 'S7':
            S7 = parseInt(value);
        break;
        case 'D20':
            D20 = parseFloat(value.toString().replace(",", "."))
        break;
        case 'D21':
            D21 = parseFloat(value.toString().replace(",", "."))
            break;
        case 'D22':
            D22 = parseFloat(value.toString().replace(",", "."))
        break;
        case 'D23':
            D23 = parseFloat(value.toString().replace(",", "."))
        break;
        case 'D24':

            D24 = parseFloat(value.toString().replace(",", "."))
        break;
        case 'S9':
            S9 = parseFloat(value.toString().replace(",", "."))
        break;
        case 'S10':
            S10 = parseFloat(value.toString().replace(",", "."))
        break;
        case 'S11':
            S11 = parseFloat(value.toString().replace(",", "."))
        break;
        case 'S14':
            S14 = parseFloat(value.toString().replace(",", "."))
        break;
        case 'S16':
            S16 = parseFloat(value.toString().replace(",", "."))
        break;
        case 'S17':
            S17 = parseFloat(value.toString().replace(",", "."))
        break;
    }
}

export{
    loadVorgFlachdach, updateVorgFlachdach
}
