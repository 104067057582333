let W10 = null;
let W11 = null;
let W12 = null;
let W13 = null;
let W14 = null;
let W15 = null;
let W18 = null;
let W19 = null;
let W20 = null;
let W21 = W19;
let W23 = null;
let W24 = null;
let W25 = null;
let W26 = null;
let W34 = null;
let W36 = null;
let W37 = null;
let W38 = null;
let W39 = null;
let W40 = null;
let W41 = null;
let W42 = null;
let W43 = null;
let W44 = null;
let W45 = null;
let W46 = null;
let W47 = null;
let W48 = null;
let W49 = null;

let X22 = null;
let X36 = null;
let X37 = null;

let X34 = null;
let X35 = null;
let X49 = null;

function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

function calcW23() {
    let result;


    if (W18 <= 400) {
        result = 0.4;
    } else if (W18 > 400 && W18 <= 900) {
        result = 1;
    } else if (W18 > 900) {
        result = 3;
    }

    return W23 = result;
}

function calcW26() {
    return W26 = (0 + W15 / 2) * Math.tan((20 * Math.PI) / 180);
}

function calcW34() {
    return W34 = W14 * Math.tan((W12 * Math.PI) / 180);

}

function calcW36() {
    return W36 = calcW37() + calcW34()
}

function calcW37() {
    console.log("W39", calcW23() +"+"+ calcW26())
    return W37 = calcW23() + calcW26();
}

function calcW38() {
    return W38 = 1.3 * Math.pow(Math.pow(W21, 2), 1/3) + calcW23();
}

function calcW39() {

    return W39 = calcW40() + calcW34();
}

function calcW40() {
    let sCalcW37 = calcW37();
    let sCalcW38 = calcW38();
    console.log("calcW40", sCalcW37+" "+sCalcW38);
    return W40 = sCalcW37 <= sCalcW38 ? sCalcW37 : sCalcW38;
}

function calcW41() {
    return W41 = W21 + calcW40();
}

function calcW42() {
    return W42 = W21 * 2;
}

function calcW43() {
    let result;

    if (calcW41() <= calcW42()) {
        result = 0;
    } else {

        result = calcW41() - calcW42();
    }

    return W43 = result;
}

function calcW44() {
    let result;

    if (calcW37() == calcW40() && calcW43() == 0) {
        result = "nicht relevant";
    } else {
        console.log("calcW44", calcW39()+"-"+ calcW43())
        result = calcW39() - calcW43();
    }

    return W44 = result;
}


function calcW45() {
    let result;

    if (calcW37() == calcW40() && calcW43() == 0) {
        result = "nicht relevant";
    } else {
        result = calcW40() - calcW43();
    }


    return W45 = result;
}


function calcW46() {
    let result;

    if (calcW37() == calcW40() && calcW43() == 0) {
        result = "nicht relevant";
    } else {
        console.log("calcW46", W21  +" + "+ calcW45())
        result = W21 + calcW45();
    }

    return W46 = result;
}

function calcW47() {
    let result;

    if (W18 == 0) {
        result = 0;
    } else if (W18 > 0 && W18 <= 50) {
        result = 15;
    } else if (W18 > 50 && W18 <= 100) {
        result = 17;
    } else if (W18 > 100 && W18 <= 150) {
        result = 19;
    } else if (W18 > 150 && W18 <= 200) {
        result = 21;
    } else if (W18 > 200 && W18 <= 250) {
        result = 23;
    } else if (W18 > 250 && W18 <= 300) {
        result = 25;
    } else if (W18 > 300 && W18 <= 350) {
        result = 27;
    } else if (W18 > 350 && W18 <= 400) {
        result = 29;
    } else if (W18 > 400 && W18 <= 450) {
        result = 31;
    } else if (W18 > 450 && W18 <= 500) {
        result = 33;
    } else if (W18 > 500 && W18 <= 550) {
        result = 35;
    } else if (W18 > 550 && W18 <= 600) {
        result = 37;
    } else if (W18 > 600 && W18 <= 650) {
        result = 39;
    } else if (W18 > 650 && W18 <= 700) {
        result = 41;
    } else if (W18 > 700 && W18 <= 750) {
        result = 43;
    } else if (W18 > 750 && W18 <= 800) {
        result = 45;
    } else if (W18 > 800 && W18 <= 850) {
        result = 47;
    } else if (W18 > 850 && W18 <= 900) {
        result = 49;
    } else {
        result = 50;
    }

    return W47 = result;
}

function calcW48() {
    let result;

    if (W18 == 0) {
        result = 0;
    } else if (W18 > 0 && W18 <= 50) {
        result = 1;
    } else if (W18 > 50 && W18 <= 100) {
        result = 2;
    } else if (W18 > 100 && W18 <= 200) {
        result = 3;
    } else if (W18 > 200 && W18 <= 400) {
        result = 4;
    } else {
        result = 5;
    }

    return W48 = result;
}

function calcW49() {
    return W49 = W21 + calcW37();
}

function calcX22() {
    return X22 = (W15 / 2) * Math.tan(degreesToRadians(20));
}

function calcX34() {
    return X34 = calcW34();
}

function calcX35() {
    let result = null;
    if (W13 <= 0.4) {
        result = 0.4;
    } else {
        result = W13 * Math.tan((45 * Math.PI) / 180);
    }
    //const result = W13 <= 0.4 ? 0.4 : W13 * Math.tan(degreesToRadians(45));
    return X35 = result;
}

function calcX36() {
    let result;
    if (calcX37() == "nicht möglich") {
        result = "nicht möglich";
    } else {

        result = calcX34() + calcX37();
    }

    return X36 = result;
}

function calcX37() {
    let result;

    if (W13 < W15/4) {
        result = calcX22() + calcX35();
    } else {
        result = "nicht möglich";
    }

    return X37 = result;
}


function calcX49() {
    let result;
    if (calcX37() == "nicht möglich") {
        result = "nicht möglich";
    } else {
        /*FRAGE*/
        if (W11 > 0) {
            result = W21 + calcX37();
        } else {
            result = W19 + calcX37();
        }
    }
    return X49 = result;
}

function loadSheddach(param) {

    switch(param) {
        case "W11":
            return W11
            break;
        case "W12":
            return W12
            break;
        case "W13":
            return W13
            break;
        case "W14":
            return W14
            break;
        case "W15":
            return W15
            break;
        case "W49":
            return calcW49()
        break;
        case "W44":
            return calcW44()
        break;
        case "X36":
            return calcX36()
        break;
        case "W40":
            return calcW40()
        break;
        case "W45":
            return calcW45()
        break;
        case "X37":
            return calcX37()
        break;
        case "W18":
            return W18;
        break;
        case "W49":
            return calcW49()
        break;
        case "W46":
            return calcW46()
        break;
        case "X49":
            return calcX49()
        break;
        case "W47":
            return calcW47()
        break;
        case "W48":
            return calcW48()
        break;
        case "W39":
            return calcW39()
        break;
        case "W37":
            return calcW37()
        break;
        case "W36":
            return calcW36()
        break;
        case "W21":
            return W21
            break;
    }
}

function updateSheddach(param, value) {
    switch(param) {

        case "W12":
            W12 = parseFloat(value.toString().replace(",", "."));
            break;
        case "W13":
            W13 = parseFloat(value.toString().replace(",", "."));
        break;
        case "W14":
            W14 = parseFloat(value.toString().replace(",", "."));
        break;
        case "W15":
            W15 = parseFloat(value.toString().replace(",", "."));
            break;
        case "W18":
            W18 = parseFloat(value.toString().replace(",", "."));
        break;
        case "W19":
            W19 = parseFloat(value.toString().replace(",", "."));
            W21 = parseFloat(value.toString().replace(",", "."));
            break;
    }
}

export{
    loadSheddach, updateSheddach
}
