import React from 'react';
import { withRouter } from 'react-router';
import Cookies from "js-cookie";
import axios from "axios";
import {BASE_URL} from "./Constants";

class Navbar extends React.Component {

    state = {
        isMenuVisible: false,
        loading:true
    };

    constructor(props) {
        super(props);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let partner = urlParams.get('partner');

        if(partner != null) {
            Cookies.set('partner', partner);
        }else{
            partner = Cookies.get('partner');
        }

        this.state = {"name": "-", partner: partner == null ? "d7719070-9bed-43c7-a098-25b34937dba1" : partner,logo: ""};

        this.showCo2 = this.showCo2.bind(this);
        this.showImportList = this.showImportList.bind(this);
        this.showChat = this.showChat.bind(this);
        this.showProfile = this.showProfile.bind(this);
        this.showAdd = this.showAdd.bind(this);
        this.showLogin = this.showLogin.bind(this);
    }

    componentDidMount() {
        this.load();

        if((Cookies.get("AccessToken") != null)) {
            this.loadData();
        }
    }

    loadData() {
        axios.get(BASE_URL + "/user/profile/index")
            .then(result => {
                const profile = result.data.data;
                this.setState({name: profile.profile.firstname+ " " +profile.profile.lastname});
            });
    }

    load() {
        axios
            .get(BASE_URL + "/partner/"+this.state.partner)
            .then((result) => {
                const profile = result.data.data;
                this.setState({loading: false, logo: profile.image == null ? "https://www.bdh-industrie.de/fileadmin/templates/bdh-koeln/default/images/logo_bdh.svg" : profile.image.url})
            })
            .catch(() => {
                this.setState({loading: false, logo: "https://www.bdh-industrie.de/fileadmin/templates/bdh-koeln/default/images/logo_bdh.svg"})
            });
    }

    currentPageIsLogin() {
        return this.props.history.location.pathname.includes("login");
    }

    currentPageIsAdd() {
        return this.props.history.location.pathname.includes("add");
    }

    currentPageIsCo2() {
        return this.props.history.location.pathname.includes("co2");
    }

    currentPageIsImportlist() {
        return this.props.history.location.pathname === "/" || this.props.history.location.pathname.includes("importlist");
    }
    currentPageIsChat() {
        return this.props.history.location.pathname.includes("chat");
    }

    currentPageIsProfile() {
        return this.props.history.location.pathname.includes("profile");
    }

    render() {
        return (
            <div className="navbar">
                <div className={"logo"}>
                   <a href={"/"}><img src={this.state.logo}/></a>
                </div>
                <div className="navbar-menu-container">
                    {
                        window.innerWidth > 1023 ? <>
                                <div className="navbar-menu-item" id={ this.currentPageIsImportlist() ? "selected": null }

                                     onClick={ this.showImportList }>Startseite</div>

                                {/*
                                <div className="navbar-menu-item" id={ this.currentPageIsAdd() ? "selected": null }
                                     onClick={ this.showImportList }>Kontakt</div>
                                     */}


                            </>
                        : null
                    }

                    <div className="navbar-menu-item" style={{
                        fontSize: window.innerWidth > 1023 ? 16 : 12,backgroundColor: "#fff", paddingLeft: 10, marginLeft: 10, marginRight: 10, paddingRight: 10, color: "#000",width: (Cookies.get("AccessToken") != null) ? 130: window.innerWidth > 1023 ? 200 : 120
                    }} id={ this.currentPageIsLogin() ? "selected": null }
                         onClick={ this.showLogin }>{(Cookies.get("AccessToken") != null) ? this.state.name: "Partnerlogin"}</div>
                </div>
            </div>
        );
    }

    showImportList() {
        this.props.history.push('/');
    }

    showCo2() {
        this.props.history.push('/co2');
    }

    showAdd() {
        this.props.history.push('/add');
    }

    showChat() {
        this.props.history.push('/chat');
    }

    showLogin() {
        (Cookies.get("AccessToken") != null) ? this.props.history.push('/account') : this.props.history.push('/login')
    }

    showProfile() {
        this.props.history.push('/profile');
    }
}

export default withRouter(Navbar);
