import {calcT21, calcT23} from "./flachdach";

let G11 = null;
let G12 = null;
let G13 = null;
let G15 = null;
let G16 = null;
let G17 = null;
let G18 = null;
let G19 = null;
let G20 = null;
let G21 = G19+G20;
let G23 = null;
let G24 = null;
let G25 = null;
let G26 = null;
let G27 = null;
let G32 = null;
let G36 = null;
let G37 = null;
let G47 = null;
let G48 = null;

let G49 = null;

let H28 = null;
let H37 = null;
let H47 = null;

let H36 = null;
let H48 = null;
let H49 = null;

let H13 = G13;
let H17 = G17;
let H31 = null;
let H33 = null;
let H35 = null;

let H12 = G12;
let H29 = null;
let H30 = null;

function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

function calcG11() {
    //=WENN(G16=0;0,001;RUNDEN(GRAD(ARCTAN(G20/G16));0))

    let result = null;
    if (G16 === 0) {
        result = 0.001;
    } else {
        // Der Math.atan() gibt den Winkel in Bogenmaß zurück. Umrechnung in Grad mit Math.degrees().
        var winkelInBogenmaß = Math.atan(calcG20() / G16);
        var winkelInGrad = Math.round((winkelInBogenmaß * 180) / Math.PI);
        result =  winkelInGrad;
    }

    return G11 = result;
}

function calcH28() {
    return H28 = G17 - G13;
}

function calcH30() {
    let result;

    if (calcG11() < 20) {
        result = G16 * Math.tan(calcG11() * Math.PI / 180);
    } else {
        result = 0;
    }
    return H29 = result;
    // OLD 22.04
    //=WENN(H12<20;H28*TAN(BOGENMASS(20));0)
    if(calcG11() < 20) {
        result = calcH28() * Math.tan(degreesToRadians(calcG11()));
    }else {
        result = 0;
    }

    return H29 = result;
}

function calcH29() {
    let result;

    return H29 = (calcG11() < 20) ? G16 * Math.tan(20 * Math.PI / 180) : 0;

    //OLD 22.04
    console.log("G12",G12);
    if(calcG11() < 20) {
        result = calcH28() * Math.tan(degreesToRadians(20));
    }else {
        result = 0;
    }

    return H29 = result;
}

function calcH31() {
    let result;
    console.log("calcH31","START")
    console.log("calcH31",calcG11())
    if (calcG11() < 20) {
        console.log("calcH31","CALC")
        console.log("calcH31",calcH29() +"-"+ calcH30())
        result = calcH29() - calcH30();
    } else {
        result = 0;
    }
    console.log("calcH31","RESULT")
    console.log("calcH31",result)
    console.log("calcH31","END")
    return H31 = result;
}



function calcG20() {
    if(G17 == null || G12 == null) {
        return G20 = null;
    }
    return G20 = (G17 * Math.tan(G12 * (Math.PI / 180)));
}

function calcG21() {
    if(G19 == null || calcG20() == null) {
        return G21 = null;
    }
    return G21 = (G19 + calcG20());
}

function calcG23() {
    let result = 0;

    if (G18 <= 400) {
        result = 0.4;
    } else if (G18 > 400 && G18 <= 900) {
        result = 1;
    } else if (G18 > 900) {
        result = 3;
    }

    return G23 = result;
}

function calcG24() {
    let result = 0;
    let cG11 = calcG11();

    if (cG11 <= 30) {
        result = 0;
    } else if (cG11 <= 45) {
        result = (10 / 15) * (cG11 - 30);
    } else if (cG11 <= 60) {
        result = 10 + (10 / 15) * (cG11 - 45);
    } else {
        result = 20;
    }

    return G24 = result;
}

function calcG25() {
    let result = 0;
    let cG11 = calcG11();
    if (cG11 < 20) {
        result = (cG11 / 20) * 0.85;
    } else if (cG11 <= 30) {
        result = 0.85 - (0.15 / 10) * (cG11 - 20);
    } else if (cG11 <= 45) {
        result = 0.7 - (0.2 / 15) * (cG11 - 30);
    } else if (cG11 <= 60) {
        result = 0.5 - (0.05 / 15) * (cG11 - 45);
    } else {
        result = 0.45;
    }

    return G25 = result;
}

function calcG26() {
    let result = 0;
    let cG11 = calcG11();
    if (cG11 < 20) {
        result = ((G13 + G16) * Math.tan(20 * (Math.PI / 180))) - calcG20();
    } else {
        result = (G13 * Math.tan((cG11 - calcG24()) * (Math.PI / 180)));
    }

    return G26 = result;
}

function calcG27() {
    let result = 0;
    let cG11 = calcG11();
    if (cG11 < 20) {
        result = ((1 + calcG25()) * G16 * Math.tan(20 * (Math.PI / 180))) - calcG20();
    } else {
        result = calcG25() * calcG20();
    }

    return G27 = result;
}

function calcG32() {
    return G32 = G13 * Math.tan(G12 * (Math.PI / 180));
}

function calcG36() {
    let result = 0;

    let cG26 = calcG26();
    let cG27 = calcG27();
    let cG32 = calcG32();
    let cG23 = calcG23();

    if (cG26 > cG27) {
        result = cG23 + cG27 + cG32;
    } else {
        result = cG23 + cG26 + cG32;
    }

    return result;

    //OLD 22.04
    if (cG26 > cG27) {
        result = cG23 + cG27 + cG32;
    } else {
        result = cG23 + cG26 + cG32;
    }

    //=WENN(H13<H17/2;H31+H33+H35;"nicht möglich")

    return G36 = result;
}

function calcG37() {
    let result = 0;


    result = (calcG26() > calcG27()) ? calcG27() + calcG23() : calcG26() + calcG23();
    return G37 = result;

    //OLD 22.04
    if (calcG26() > calcG27()) {
        result = calcG27() + calcG23();
    } else {
        result = calcG26() + calcG23();
    }

    return G37 = result;
}

function calcG49() {
    return G49 = calcG21()+calcG37();
}

function calcH48() {
    let result = 0;

    if (calcH37() === "nicht möglich") {
        result = "nicht möglich";
    } else {
        result = calcG21() + calcH37();
    }

    return H48 = result
}

function calcH47() {
    let result = 0;

    if (H37 === "nicht möglich") {
        result = "nicht möglich";
    } else {
        result = calcG21() + calcH37();
    }

    return H47 = result
}

function calcG47() {
    let result;

    if (G18 === 0) {
        result = 0;
    } else if (G18 > 0 && G18 <= 50) {
        result = 15;
    } else if (G18 > 50 && G18 <= 100) {
        result = 17;
    } else if (G18 > 100 && G18 <= 150) {
        result = 19;
    } else if (G18 > 150 && G18 <= 200) {
        result = 21;
    } else if (G18 > 200 && G18 <= 250) {
        result = 23;
    } else if (G18 > 250 && G18 <= 300) {
        result = 25;
    } else if (G18 > 300 && G18 <= 350) {
        result = 27;
    } else if (G18 > 350 && G18 <= 400) {
        result = 29;
    } else if (G18 > 400 && G18 <= 450) {
        result = 31;
    } else if (G18 > 450 && G18 <= 500) {
        result = 33;
    } else if (G18 > 500 && G18 <= 550) {
        result = 35;
    } else if (G18 > 550 && G18 <= 600) {
        result = 37;
    } else if (G18 > 600 && G18 <= 650) {
        result = 39;
    } else if (G18 > 650 && G18 <= 700) {
        result = 41;
    } else if (G18 > 700 && G18 <= 750) {
        result = 43;
    } else if (G18 > 750 && G18 <= 800) {
        result = 45;
    } else if (G18 > 800 && G18 <= 850) {
        result = 47;
    } else if (G18 > 850 && G18 <= 900) {
        result = 49;
    } else {
        result = 50;
    }

    return G47 = result;
}

function calcG48() {
    let result;

    if (G18 === 0) {
        result = 0;
    } else if (G18 > 0 && G18 <= 50) {
        result = 1;
    } else if (G18 > 50 && G18 <= 100) {
        result = 2;
    } else if (G18 > 100 && G18 <= 200) {
        result = 3;
    } else if (G18 > 200 && G18 <= 400) {
        result = 4;
    } else {
        result = 5;
    }

    return G48 = result;
}

function calcH35() {
    //=WENN(H13<=0,4;0,4;H13*TAN(BOGENMASS(45)))
    let result = null;

    if(H13 <= 0 ) {
        result = 0.4;
    }else{
        result = H13 * Math.tan(degreesToRadians(45));
    }

    return H35 = result;
}


function calcH33() {
    //=WENN(H12>=20;H13*TAN(BOGENMASS(H12));H13*TAN(BOGENMASS(20)))
    let result = null;


    result = H13 * Math.tan(G12 * Math.PI / 180);
    /*
    if(H12 > 20) {
        result = H13 * Math.tan(degreesToRadians(H12));
    }else{
        result = H13 * Math.tan(degreesToRadians(20));
    }*/

    return H33 = result;
}

function calcH36() {
    let result;


    console.log("calcH36", "BEGINN")
    console.log("calcH36", calcH31() +"+"+ calcH33() +"+"+ calcH35())

    if (G13 < G17 / 2) {
        result = calcH31() + calcH33() + calcH35();
    } else {
        result = "nicht möglich";
    }
    console.log("calcH36", "RESULT")
    console.log("calcH36", result)
    console.log("calcH36", "END")
    return H36 = result;
}

function calcH37() {
    let result;

    if (calcH36() === "nicht möglich") {
        result = "nicht möglich";
    } else {

        if (H13 <= 0.4) {
            result = 0.4 + calcH31();
        } else {

            result = H13 * Math.tan(45 * (Math.PI / 180)) + calcH31();
        }
    }

    return H37 = result;
}

function calcH49() {
    let result;

    if (calcH37() === "nicht möglich") {
        result = "nicht möglich";
    } else {
        result = calcG21() + calcH37();
    }

    return H49 = result;
}


function load(param) {
    switch(param) {
        case 'G11':
            return calcG11();
            break;
        case 'G12':
            return G12
            break;
        case 'G13':
            return G13
            break;
        case 'G15':
            return G15
            break;
        case 'G16':
            return G16
            break;
        case 'G17':
            return G17
            break;
        case 'G18':
            return G18
            break;
        case 'G19':
            return G19
            break;
        case 'G20':
            return calcG20()
            break;
        case 'G21':
            return calcG21()
            break;
        case 'G23':
            return calcG23()
            break;
        case 'G24':
            return calcG24()
            break;
        case 'G25':
            return calcG25()
            break;
        case 'G26':
            return calcG26()
            break;
        case 'G27':
            return calcG27()
            break;
        case 'G32':
            return calcG32()
            break;

        case 'G36':
            return calcG36()
            break;
        case 'G37':
            return calcG37()
            break;
        case 'G47':
            return calcG47()
            break;
        case 'G48':
            return calcG48()
            break;
        case 'G49':
            return calcG49()
            break;
        case 'H47':
            return calcH47();
            break;
        case 'H36':
            return calcH36()
            break;
        case 'H49':
            return calcH49()
            break;
        case 'H37':
            return calcH37()
            break;
    }

}

function update(param, value) {
    switch(param) {
        case 'G11':
            G11 = parseFloat(value.toString().replace(",", "."));
            break;
        case 'G12':
            G12 = parseFloat(value.toString().replace(",", "."));
            calcG11();

            break;
        case 'G13':
            G13 = parseFloat(value.toString().replace(",", "."));
            H13 = G13;
            calcG11();
            break;
        case 'G15':
            G15 = parseFloat(value.toString().replace(",", "."));
            calcG11();
            break;
        case 'G16':
            G16 = parseFloat(value.toString().replace(",", "."));
            calcG11();
            break;
        case 'G17':
            G17 = parseFloat(value.toString().replace(",", "."));
            calcG11();
            break;
        case 'G18':
            G18 = parseFloat(value.toString().replace(",", "."));
            calcG11();
            break;
        case 'G19':
            G19 = parseFloat(value.toString().replace(",", "."));
            break;
        case 'G20':
            calcG20();
            break;
        case 'G21':
            calcG21();
            break;
        case 'G23':
            calcG23();
            break;
        case 'G24':
            calcG24();
            break;
        case 'G25':
            calcG25();
            break;
        case 'G26':
            calcG26();
            break;
        case 'G27':
            calcG27();
            break;
        case 'G32':
            calcG32()
            break;
        case 'G36':
            calcG36()
            break;
        case 'G37':
            calcG37()
            break;
        case 'G47':
            calcG47()
            break;
        case 'G48':
            calcG48()
            break;
        case 'G49':
            calcG49()
            break;
        case 'H47':
            calcH47()
            break;
        case 'H36':
            calcH36()
            break;
        case 'H49':
            calcH49()
            break;
    }

}


function loadAsyncSatteldach(param) {

    switch(param) {
        case 'G11':
            return calcG11();
            break;
        case 'G12':
            return G12
            break;
        case 'G13':
            return G13
            break;
        case 'G15':
            return G15
            break;
        case 'G16':
            return G16
            break;
        case 'G17':
            return G17
            break;
        case 'G18':
            return G18
            break;
        case 'G19':
            return G19
            break;
        case 'G20':
            return calcG20()
            break;
        case 'G21':
            return calcG21()
            break;
        case 'G23':
            return calcG23()
            break;
        case 'G24':
            return calcG24()
            break;
        case 'G25':
            return calcG25()
            break;
        case 'G26':
            return calcG26()
            break;
        case 'G27':
            return calcG27()
            break;
        case 'G32':
            return calcG32()
            break;

        case 'G36':
            return calcG36()
            break;
        case 'G37':
            return calcG37()
            break;
        case 'G47':
            return calcG47()
            break;
        case 'G48':
            return calcG48()
            break;
        case 'G49':
            return calcG49()
            break;
        case 'H47':
            return calcH47();
            break;
        case 'H36':
            return calcH36()
            break;
        case 'H49':
            return calcH49()
            break;
        case 'H37':
            return calcH37()
            break;
    }
}


function updateAll() {
    this.calcG11();
    this.calcG20();
    this.calcG21();
    this.calcG23();
    this.calcG24();
    this.calcG25();
    this.calcG26();
    this.calcG27();
    this.calcG32();
    this.calcG36();
    this.calcG37();
    this.calcG49();
}

export{
    calcG11,
    calcG20,
    calcG21,
    calcG23,
    calcG24,
    calcG25,
    calcG26,
    calcG27,
    calcG32,
    calcG36,
    calcG37,
    calcG49,
    calcH47,
    calcG47,
    calcG48,
    calcH36,
    calcH37,
    calcH48,
    calcH49,
    load,
    update,
    calcH31,
    loadAsyncSatteldach
}
