import React from 'react';
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import {BASE_URL} from "./Constants";


class Imprint extends React.Component {

    state = {
        isLoading: true,
        content: null
    }

    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        axios.get(BASE_URL + "/contents")
            .then(result => {
                const content = result.data;

                this.setState({ content, isLoading: false });
            })
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }

    ImprintView() {
        return (
            <div>
                <p className="heading-small" id="leading">Impressum</p>
                <p className="bodyText" id="leading">
                    { (this.state.content.data[0].value.split("\n").map((i,key) => {
                        return <div key={key}>{i}</div>;
                    }) )}
                </p>

                <div className="divider" />

                <p className="heading-small" id="leading">Datenschutz</p>
                <p className="bodyText" id="leading">
                    { (this.state.content.data[1].value.split("\n").map((i,key) => {
                        return <div key={key}>{i}</div>;
                    }) )}
                </p>
            </div>
        );
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Impressum & Datenschutz</p>
                </div>

                <div className="content">
                    {
                        this.state.isLoading ? this.LoadingView() : this.state.content.data != null ? this.ImprintView() : null
                    }
                </div>
            </div>
        );
    }
}

export default Imprint;
