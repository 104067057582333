import React from 'react';
import {Link} from "react-router-dom";
import ErrorBox from "./ErrorBox";
import Button from "./Button";
import axios from "axios";
import AuthManager from "./AuthManager";
import {BASE_URL, LOGIN_ENDPOINT} from "./Constants";
import Cookies from "js-cookie";

class Login extends React.Component {

    state = {
        email: "",
        password: "",
        incorrectCredentials: false,
        errorMessage: null,
        isSending: false
    }

    constructor(props) {
        super(props);

        this.handleEmailFieldChange = this.handleEmailFieldChange.bind(this);
        this.handlePasswordFieldChange = this.handlePasswordFieldChange.bind(this);

        this.login = this.login.bind(this);
    }

    handleEmailFieldChange(event) {
        this.setState({email: event.target.value});
    }

    handlePasswordFieldChange(event) {
        this.setState({password: event.target.value});
    }

    login() {
        this.setState({ isSending: true });

        axios.post(BASE_URL + LOGIN_ENDPOINT, {
            email: this.state.email,
            password: this.state.password
        })
            .then(result => {
                const loginResult = result.data;

                let authManager = new AuthManager();
                authManager.login(loginResult.access_token, loginResult.expires_at);
                if(loginResult.uuid != null) {
                    Cookies.set('partner', loginResult.uuid);

                }
                //this.props.history.push('/');
                window.location.href = "/";
            })
            .catch(error => {
                console.log(error)
                if (error.response) {
                    this.setState({ isSending: false, errorMessage: "Falsche Zugangsdaten" });
                }
            });
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Anmeldung für Partner</p>
                </div>

                <div className="content">
                    <input type="text" placeholder="E-Mail Adresse" value={ this.state.email } onChange={ this.handleEmailFieldChange } />
                    <input type="password" placeholder="Passwort" value={ this.state.password } onChange={ this.handlePasswordFieldChange } />

                    <Link to="/forgotPassword">Passwort vergessen?</Link>

                    { this.ErrorsView() }


                    <Button title="Anmelden"
                            onClick={ this.login }
                            showLoadingState={ this.state.isSending }
                            disabled={this.state.email.length === 0 || this.state.password.length === 0}
                    />

                </div>
            </div>
        );
    }

}

export default Login;
