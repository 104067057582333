let J7 = 0;
let J9 = null;
let J10 = null;
let J11 = null;
let J12 = null;
let J14 = null;
let J16 = null;
let J17 = null;
let J18 = null;

let D20 = null;
let D21 = null;
let D22 = null;
let D23 = null;
let D24 = null;

let J30 = null;
let J31 = null;
let J32 = null;
let J35 = null;
let J36 = null;
let J39 = null;
let J40 = null;
let J42 = null;
let J43 = null;
let J44 = null;
let J45 = null;

function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

function calcJ18() {
    let result = null;
    result = J17;
    return J18 = result;
}

function calcD22() {
    //calc D21? Check it
    let result = null;
    if (D21 <= 400) {
        result = 0.4;
    } else if (D21 > 400 && D21 <= 900) {
        result = 1;
    } else if (D21 > 900) {
        result = 3;
    }

    return D22 = result;
}

function calcJ30() {
    let result = null;

    result =
        J12 < 20
            ? (J12 / 20) * 0.85
            : J12 <= 30
                ? 0.85 - 0.15 / 10 * (J12 - 20)
                : J12 <= 45
                    ? 0.7 - 0.2 / 15 * (J12 - 30)
                    : J12 <= 60
                        ? 0.5 - 0.05 / 15 * (J12 - 45)
                        : 0.45;

    return J30 = result;
}

function calcJ31() {
    let result = null;

    result = J9 / 2 * Math.tan(degreesToRadians(J12));

    return J31 = result;
}

function calcJ32() {
    let result = null;

    const cJ30 = calcJ30();
    const cJ31 = calcJ31();

    if (J12 < 20) {
        // Wenn J12 < 20 ist
        result = (1 + cJ30) * J16 * Math.tan((20 * Math.PI) / 180) - cJ31;
    } else {
        // Wenn J12 >= 20 ist
        result = cJ30 * cJ31;
    }

    //result = J12 < 20 ? (1 + cJ30) * J16 * Math.tan(degreesToRadians(20)) - J31 : cJ30 * calcJ31();

    return J32 = result;
}

function calcJ35(){
    let result = null;

    /**
     * @deprecated 27.05.24

    if (J7 == 1) {
        result = "entfällt";
    } else {
        result = J10 * Math.sin(degreesToRadians(J14)) + J9 * Math.cos(degreesToRadians(J14));
    }
     */


    const radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß

    if (J7 === 1) {
        result = "entfällt";
    } else if (J14 > 90) {
        const angle = (180 - J14) * radian;
        result = J10 * Math.sin(angle) + J9 * Math.cos(angle);
    } else {
        const angle = J14 * radian;
        result = J10 * Math.sin(angle) + J9 * Math.cos(angle);
    }

    return J35 = result;
}

function calcJ36(){
    let result = null;
    const cJ35 = calcJ35();

    result = cJ35 == "entfällt" ? 6 * J11 : (1.75 * cJ35) / (1 + 0.25 * cJ35 / J11);

    return J36 = result;
}

function calcJ39() {
    let result = null;
    const cJ36 = calcJ36();
    console.log("cJ36", calcJ36())
    console.log("J16", J16)

    result = J16 >= cJ36 ? 'nicht relevant' : Math.sqrt(1 - (J16 ** 2 / cJ36 ** 2));

    return J39 = result;
}

function calcJ40() {
    let result = null;
    const cJ18 = calcJ18();
    const cJ32 = calcJ32();
    const cJ39 = calcJ39();

    // =WENN(J39="nicht relevant";"nicht relevant";WENN(J39*(J11+J32)+J18-$E20<=0;"nicht relevant";J39*(J11+J32)+J18-$E20))

   // let D20 = 18.5864944676592;
//console.log("cJ39",cJ39)
    //console.log("cJ39 1 ",cJ39 * (J11 + cJ32) + cJ18 - D20 )
    if(cJ39 == "nicht relevant") {
        result = "nicht relevant"
    }else if(cJ39 * (J11 + cJ32) + cJ18 - D20 <= 0) {
        result = "nicht relevant"
    }else {
        result = cJ39 * (J11 + cJ32) + cJ18 - D20;
    }

    console.log("D20D20D20",D20)
    console.log(cJ39 +"* ("+J11 +"+"+ cJ32+")" + "+"+cJ18 +"-"+D20);
    console.log(cJ39 * (J11 + cJ32) + cJ18 - D20);
    //console.log("cJ39 2",D20)

    // =WENN(J39="nicht relevant";"nicht relevant";WENN(J39*(J11+J32)+J18-$E20<=0;"nicht relevant";J39*(J11+J32)+J18-$E20))

    return J40 = result;
}

function calcJ42() {
    let result = null;

    const cJ40 = calcJ40();

    // =WENN(J40="nicht relevant";"nicht relevant";WENN((J40+$E22)<=$E23;"nicht relevant";J40+$E22))

    result = cJ40 == "nicht relevant" ? "nicht relevant" : cJ40 + calcD22() <= D23 ? "nicht relevant" : cJ40 + calcD22();

    return J42 = result;
}

function calcJ43() {
    let result = null;
    const cJ42 = calcJ42();

// =WENN(J42="nicht relevant";"nicht relevant";WENN(J42<=$E23;"nicht relevant";J42-$E23))

    result = cJ42 == 'nicht relevant' ? 'nicht relevant' : cJ42<=D23 ? 'nicht relevant' : cJ42 - D23;

    return J43 = result;
}

function calcJ44() {
    let result = null;
    const cJ42 = calcJ42();
    const cJ43 = calcJ43();

    ///=WENN(J43="nicht relevant";"nicht relevant";$E24+J43)

    result = cJ42 == 'nicht relevant' ? 'nicht relevant' : D24 + cJ43;

    return J44 = result;
}

function calcJ45() {
    let result = null;

    const J42Value = calcJ42();
    const D20Value = D20;

    result = J42Value == 'nicht relevant' ? 'nicht relevant' : D20Value + J42Value;

    return J45 = result;
}

function loadVorgWalmdach(param) {

    switch(param) {
        case "J9":
            return J9;
        break;
        case "J10":
            return J10;
        break;
        case "J11":
            return J11;
        break;
        case "J12":
            return J12;
        break;
        case "J14":
            return J14;
        break;
        case "J16":
            return J16;
        break;
        case "J17":
            return J17;
        break;
        case "J31":
            return calcJ31();
        break;
        case "J35":
            return calcJ35();
        break;
        case "J36":
            return calcJ36();
        break;
        case "J42":
            return calcJ42();
        break;
        case "J43":
            return calcJ43();
        break;
        case "J44":
            return calcJ44();
        break;
        case "J45":
            return calcJ45();
        break;

    }
}

function updateVorgWalmdach(param, value) {
    switch(param) {
        case 'J7':
            J7 = parseInt(value);
        break;
        case 'D20':
            D20 = parseFloat(value)
            break;
        case 'D21':
            D21 = parseFloat(value)
            break;
        case 'D22':
            D22 = parseFloat(value)
            break;
        case 'D23':
            D23 = parseFloat(value)
            break;
        case 'D24':
            D24 = parseFloat(value)
            break;
        case "J9":
            J9 = parseFloat(value.toString().replace(",", "."));
            break;
        case "J10":
            J10 = parseFloat(value.toString().replace(",", "."));
            break;
        case "J11":
            J11 = parseFloat(value.toString().replace(",", "."));
            break;
        case "J12":
            J12 = parseFloat(value.toString().replace(",", "."));
            break;
        case "J14":
            J14 = parseFloat(value.toString().replace(",", "."));

            break;
        case "J16":
            J16 = parseFloat(value.toString().replace(",", "."));
            break;
        case "J17":
            J17 = parseFloat(value.toString().replace(",", "."));
            break;
    }
}

export{
    loadVorgWalmdach, updateVorgWalmdach
}
