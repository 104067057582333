import React from 'react';
import './App.css';
import axios from "axios";
import {BASE_URL} from "./Constants";
import LoadingSpinner from "./LoadingSpinner";
import Modal from 'react-modal';
import Button from "./Button";
import moment from "moment";
import Papa from "papaparse";
import EditField from "./EditField";
import Cookies from "js-cookie";

class Add extends React.Component {
    upload = null;

    state = {
        isLoading: true,
        modalIsOpen: false,
        name: "",
        email: "",
        isSending: false,
        customers: [],
        profile: [],
        download: false,
        upload_csv: false,
        pdf_allow: false,
        web_regist: false,
        note: "Die Berechnung erfolgte auf der Basis der zur Verfügung gestellten Daten. Das Berechnungsergebnis ist demzufolge auch nur verwendbar, wenn diese Daten den tatsächlichen Gegebenheiten entsprechen. Vor Umsetzung dieser Ergebnisse am hier berechneten Schornstein empfehlen wir, das Einverständnis des zuständigen bevollmächtigten Bezirksschornsteinfeger ein-zuholen. Das Ergebnis der Berechnung gibt keine Auskunft über weitere zu beachtende gesetzliche, baurechtliche oder normative Anforderungen, wie z. B. die Einhaltung von Sicherheitsabständen zwischen dem berechneten Schornstein und Bauteilen aus brennbaren Baustoffen, die baulichen Notwendigkeiten bei der Führung von Schornsteinen mit Überdruckbetrieb oder die aus funktions- oder umweltschutzgründen notwendige Höhe der Schornsteinmündung über der Dachhaut oder den Dach-first"
    }

    constructor(props) {
        super(props);

        this.closeModal = this.closeModal.bind(this)
        this.load = this.load.bind(this)
        this.addPeople = this.addPeople.bind(this);
        this.deleteCustomer = this.deleteCustomer.bind(this);
        this.uploaded = this.uploaded.bind(this);
        this.saveSettings = this.saveSettings.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.submitNote = this.submitNote.bind(this);
    }

    deleteCustomer(id) {
        axios.get(BASE_URL + "/user/customer/delete/"+id)
            .then(result => {
                this.load();
            });
    }

    load() {
        axios.get(BASE_URL + "/user/overview")
            .then(result => {
                this.setState({note: result.data.profile.note, pdf_allow: result.data.profile.pdf_allow, web_regist: result.data.profile.web_regist,profile: result.data.profile, customers: result.data.customer, isLoading: false})
            });
    }

    componentDidMount() {
        this.load();
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }

    uploaded(e) {
        const files = e.target.files;

        if (files) {
            const me = this;
            Papa.parse(files[0], {
                config: {delimiter: ";"},
                complete: function(results) {
                    me.setState({upload_csv: true});

                    results.data.map((x,y) => {

                        if(y > 0) {
                            const config = {
                                headers: {
                                    'Accept': 'application/json',
                                    'content-type': 'multipart/form-data'
                                }
                            }

                            const url = BASE_URL + '/user/create';
                            const formData = new FormData();
                            formData.append("email", x[1]);
                            formData.append("name", x[0]);
                            formData.append("download", x[2]);


                            axios.post(url, formData, config).then(o => {
                                if(y == results.data.length - 1) {
                                  window.location.reload();
                                }
                            });
                        }
                    })

                }}
            )
        }
    }

    showProfil() {
        return <div style={{display: "flex", margin: "auto"}}>

            <div style={{width: "40%"}}>
                <div style={{display: "flex"}}>
                    <div style={{marginRight: 20}}>
                        <div style={{height: 140, width: 140, borderRadius: "100%", backgroundSize: "cover", backgroundImage: "url('"+(this.state.profile.image == null ? "/no-preview-available.png" : this.state.profile.image.url)+"')", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}>

                        </div>
                    </div>
                    <div>
                        <div><b>Status</b></div>
                        <div>Partner</div>

                        <div style={{marginTop: 10}}><b>Letzter Login</b></div>
                        <div>{moment(this.state.profile.updated_at).format("DD.MM.Y HH:mm")}</div>
                    </div>
                </div>
                <div style={{display: "flex", marginTop: 14}}>

                    <Button title="Passwort ändern"
                            onClick={ () => {window.location.href = "/profile/password"}}
                            style={{  backgroundColor: "darkorange", color: '#fff', fontWeight: 400,  height: 30, marginTop: 0, fontSize: 14, paddingTop: 7}}
                    />

                    <Button title="Profil bearbeiten"
                            onClick={ () => {window.location.href = "/profile"}}
                            style={{   backgroundColor: "darkgreen", color: '#fff',  fontWeight: 400, height: 30, marginTop: 0, fontSize: 14, marginLeft: 10, paddingTop: 7}}
                    />


                    <Button title="Abmelden"
                            onClick={ () => {Cookies.remove('AccessToken'); Cookies.remove('partner'); window.location.reload();}}
                            style={{   backgroundColor: "darkred", color: '#fff',  fontWeight: 400, height: 30, marginTop: 0, fontSize: 14, marginLeft: 10, paddingTop: 7}}
                    />
                </div>
            </div>
            <div style={{width: "40%", marginLeft: 35}}>
                <h4 style={{marginTop: 0}}>Persönliche Daten</h4>
                <p>
                    <span>Firma: {this.state.profile.profile.company}</span><br />
                    <span>Name: {this.state.profile.profile.firstname} {this.state.profile.profile.lastname}</span><br />
                    <span>E-Mail: {this.state.profile.email}</span>
                </p>
            </div>

        </div>
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    saveSettings() {

        const url = BASE_URL + '/user/profile/settings/update';
        const formData = new FormData();

        formData.append('pdf_allow', this.state.pdf_allow == 1 || this.state.pdf_allow == "true" ? "true" : "false");
        formData.append('web_regist', this.state.web_regist == 1 || this.state.web_regist == "true" ? "true" : "false");

        const config = {
            headers: {
                'Accept': 'application/json',
                'content-type': 'multipart/form-data'
            }
        }

        return axios.post(url, formData, config);
    }

    addPeople() {

        if(this.state.email.length > 1 && this.state.email.length < 6) {
            alert("Bitte gebe eine gültige E-Mail Adresse an");
            return false;
        }
        if(this.state.name.length < 4) {
            alert("Bitte gebe einen Namen an");
            return false;
        }
        this.setState({isSending: true});

        const config = {
            headers: {
                'Accept': 'application/json',
                'content-type': 'multipart/form-data'
            }
        }

        const url = BASE_URL + '/user/create';
        const formData = new FormData();
        formData.append("email", this.state.email);
        formData.append("name", this.state.name);
        formData.append("download", this.state.download);

        return axios.post(url, formData, config)
            .then(() => {
                this.setState({modalIsOpen: false, name: "", email: "", isSending: false});
                this.load();
            }).catch(error => {
                console.log(error.response)
                if (error.response) {
                    this.setState({ isSending: false, errors: error.response.data.errors, errorMessage: error.response.data.message });
                }
            });

        //
    }

    handleFieldChange(event) {
        const value = event.target.value;
        this.setState({note: value});
    }

    submitNote() {
        this.setState({isSending: true})

        const url = BASE_URL + '/user/note';

        const formData = new FormData();
        formData.append("note", this.state.note);

        return axios.post(url, formData)
            .then(() => {
                this.setState({isSending: false})
            }).catch(error => {
                console.log(error.response);
                this.setState({isSending: false})
            });
    }
    showInvite() {

        return <div style={{marginTop: 40}}>

            <h3 style={{margin: 0}}>Eingeladene Nutzer</h3>
            <div style={{display: "flex"}}>
                <p style={{margin: 0}}>
                    <small>Laden Sie schnell und einfach Mitarbeiter oder Kunden zur Nutzung der BDH WebApp ein.</small>
                </p>
                <div style={{marginLeft: "auto", marginTop: -10, marginRight: 10, display: "flex"}}>
                    <img onClick={() => this.setState({modalIsOpen: true})} style={{width: 25, marginTop: 2, cursor: "pointer"}} src={"/plus.multiuse.svg"} />
                    {
                        this.state.upload_csv ? <div className="loadingSpinner" style={{margin: 0, marginTop: 2, marginLeft: 5, width: 30, height: 30}}>
                            <div className="double-bounce1" />
                            <div className="double-bounce2" />
                        </div> : <img onClick={() => {this.upload.click()}} style={{width: 25, height: 25, marginLeft: 5, cursor: "pointer"}} src={"/csv.svg"} />
                    }
                    <a href={"https://plattform.schorn-muendung.de/api/filezip/"+this.state.profile.id} target={"_blank"}>
                        <img style={{width: 25, marginTop: 2, cursor: "pointer"}} src={"/download.svg"} />
                    </a>
                </div>
            </div>

            <div style={{marginTop: 20, marginLeft: -10}}>
                {
                    this.state.customers.length == 0 ? null : this.state.customers.map((x) => <div style={{cursor: "pointer", float: "left",  marginLeft: 10, marginBottom: 10, width: window.innerWidth < 900 ? "96%" : "46%", padding: "1%", borderRadius: 10, backgroundColor: "#E5ECF5", display: "flex"}}>
                        <div onClick={() => {navigator.clipboard.writeText("https://schorn-muendung.de?partner="+x.uuid); setTimeout(function() {
                            alert('In der Zwischenablage gespeichert!')
                        }, 100)}}>
                            <small>
                                Name: {x.name}<br />
                                Link: https://schorn-muendung.de?partner={x.uuid}
                            </small>
                        </div>
                        <div style={{marginLeft: 10, alignSelf: "center", marginRight: 0, marginTop: 5}}>
                            <img onClick={() => this.deleteCustomer(x.id)} style={{width: 30, cursor: "pointer"}} src={"/close_icon.svg"} />
                        </div>
                    </div>)
                }
            </div>
            <div style={{clear: "both"}}></div>
            <h3 style={{margin: 0}}>Einstellungen</h3>
            <div style={{marginTop: 10}}>
                <div>
                    <input id={"pdf_allow"} style={{marginTop: 5, marginRight: 10}} type="checkbox"
                          value={"1"} checked={this.state.pdf_allow} onChange={(e) => this.setState({pdf_allow: !this.state.pdf_allow}, function() {
                        this.saveSettings();
                    })}/>
                    <label htmlFor={"pdf_allow"}><small>Automatische PDF-Download-Freischaltung </small></label>
                </div>
                <div>
                    <input id={"web_regist"} style={{marginTop: 5, marginRight: 10}} type="checkbox" checked={this.state.web_regist}
                           value={"1"} onChange={(e) => this.setState({web_regist: !this.state.web_regist}, function() {
                        this.saveSettings();
                    })}/>
                    <label htmlFor={"web_regist"}><small>Registrierung über Webformular erlauben</small></label>
                </div>


                <p>PDF Notiz</p>
                <textarea type={ "text" } multiple placeholder={ "Keinen Hinweis" } className="editField-textField"
                       name={ "note" } value={ this.state.note }
                       onChange={ this.handleFieldChange }
                />

                <Button showLoadingState={ this.state.isSending } title={"Speichern"} onClick={ () => this.submitNote() } />

            </div>
        </div>
    }

    selectView() {
        return <div style={{paddingBottom: 140}}>
            {this.showProfil()}
            <div style={{clear: "both"}}></div>
            {this.showInvite()}
        </div>
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Mein Profil</p>
                </div>

                <div className="content">
                    {
                        this.state.isLoading ? this.LoadingView() : this.selectView()
                    }

                </div>


                <Modal
                    isOpen={this.state.modalIsOpen}

                    onRequestClose={this.closeModal}
                    style={{content: {
                            width: window.innerWidth < 400 ? 300 : 400,
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                        }}}
                    contentLabel="Example Modal"
                >
                    <div style={{marginBottom: 10, marginLeft: "auto", marginRight: 0}}>
                        <img onClick={this.closeModal} style={{width: 25, cursor: "pointer"}} src={"/close_icon.svg"} />
                    </div>
                    <div>
                        <div>
                            <small>Name</small>
                            <input  style={{marginTop: 5}}  type="text" placeholder="Name" value={ this.state.name } onChange={ (e) => this.setState({name: e.target.value}) } />
                        </div>
                        <div>
                            <small>E-Mail Adresse (optional)</small>
                            <input style={{marginTop: 5}} type="text" placeholder="E-Mail Adresse" value={ this.state.email } onChange={ (e) => this.setState({email: e.target.value}) } />
                        </div>
                        <div>
                            <input id={"select_download"} style={{marginTop: 5, marginRight: 10}} type="checkbox" value={ this.state.download } onChange={ (e) => this.setState({download: e.target.value}) } />
                            <label for={"select_download"}><small>PDF Download genehmigen</small></label>
                        </div>

                        <Button title="Hinzufügen"
                                onClick={() => this.addPeople() }
                                style={{    height: 30, marginTop: 20, fontSize: 14, paddingTop: 7}}
                                showLoadingState={ this.state.isSending }
                                disabled={this.state.name.length < 3}
                        />
                    </div>
                </Modal>

                <input type={"file"} onChange={(e) => this.uploaded(e)} ref={e => (this.upload = e)} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" name={"upload"} style={{display:"none"}}/>

            </div>
        );
    }
}

export default Add;
