import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import Main from "./Main";
import NavBar from './NavBar';
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom"
import PageNotFound from "./PageNotFound";
import Login from "./Login";
import AuthManager from "./AuthManager";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";
import ForgotPassword from "./ForgotPassword";
import SetNewPassword from "./SetNewPassword";
import Footer from "./Footer";
import Add from "./Add";
import Imprint from "./Imprint";
import Home from "./home";
import Regulatory from "./Regulatory";
import Account from "./account";

function App() {
  let history = useHistory();

  function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }

  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQSTUVWXYaabcdefghijklmnopqrstuvwxyz012345678901234567890123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.common.Authorization = `Bearer ${ Cookies.get("AccessToken") }`;

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401 && !error.response.config.url.includes("login")) {
      showLogin();
    } else {
      return Promise.reject(error);
    }
  });

  function showLogin() {
    history.push('/login');
  }


  if(getCookie("user") == "") {
    document.cookie = `user=` + makeid(100) + `;max-age=12604800;`
  }
  return (
      <div>
        <NavBar/>

        <Switch>
          <Route exact path='/' component={ Home } />
          <Route exact path='/login' component={ Login } />
          <Route exact path='/forgotPassword' component={ ForgotPassword } />
          <Route exact path='/forgotPassword/set' component={ SetNewPassword } />
          <Route exact path='/imprint' component={ Imprint } />
          <Route exact path='/regulatory' component={ Regulatory } />

          <Route exact path='/add' component={ Add } />

          <PrivateRoute exact path='/account' component={ Account } />
          <PrivateRoute exact path='/profile' component={ Profile } />
          <PrivateRoute path='/profile/password' component={ ChangePassword } />
          <PrivateRoute path='/profile/delete' component={ DeleteAccount } />

          <Route path='*' component={ PageNotFound } />
        </Switch>

        <Footer/>

      </div>
  );
}

function PrivateRoute ({component: Component, authed, ...rest}) {
  let authManager = new AuthManager();

  return (
      <Route
          {...rest}
          render={(props) => authManager.isAuthenticated() === true
              ? <Component {...props} />
              : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
      />
  )
}

export default App;
