let M10 = null;
let M11 = null;
let M13 = null;
let M15 = null;
let M18 = null;
let M19 = null;
let M20 = null;
let M21 = null;
let M22 = null;
let M23 = null;
let M26 = null;
let M27 = null;
let M28 = null;
let M32 = null;
let M36 = null;
let M37 = null;
let M47 = null;
let M48 = null;
let M49 = null;

let N31 = null;
let N33 = null;
let N35 = null;
let N36 = null;
let N37 = null;
let N29 = null;
let N28 = null;
let N30 = null;
let N49 = null;

function calcM20() {
    const angleInRadians = M11 * (Math.PI / 180);

// Calculate the result using the Math.tan function
    return M20 = M15 * Math.tan(angleInRadians);
}

function calcM21() {
    return M21 = M19 + calcM20();
}

function calcM22() {
    const angleInDegrees = 20;
    const angleInRadians = (angleInDegrees * Math.PI) / 180;

// Calculate the tangent value
    const tangentValue = (M15 / 2) * Math.tan(angleInRadians);
    return M22 = tangentValue;
}

function calcM23() {
    let result;

    if (M18 <= 400) {
        result = 0.4;
    } else if (M18 > 400 && M18 <= 900) {
        result = 1;
    } else if (M18 > 900) {
        result = 3;
    }

    return M23 = result;
}


function calcM26() {
    const angleInDegrees = 20;
    const angleInRadians = (angleInDegrees * Math.PI) / 180;

    let result;

    console.log(typeof M13)

    if (M11 < 20) {
        result = ((M13) + ((M15 / 2 - M13) * (1 - M11 / 20))) * Math.tan(angleInRadians);
    } else {
        result = M13 * Math.tan(angleInRadians);
    }

    return M26 = result;
}

function calcM27() {
    const angleInRadians = 20 * (Math.PI / 180); // Convert the angle from degrees to radians

// Calculate the result using the Math.tan function
    return M27 = (M15 / 2) * Math.tan(angleInRadians);

}

function calcM28() {
    return M28 = M15 - M13;
}

function calcM32() {
    const angleInRadians = M11 * (Math.PI / 180);
    return M32 = M13 * Math.tan(angleInRadians);
}

function calcM36() {
    let result;

    if (M13 < M15 / 2) {
        result = calcM23() + calcM26() + calcM32();
        console.log(calcM23() +"+"+ calcM26() +"+"+ calcM32())
    } else {
        result = calcM23() + calcM27() + calcM32();
    }

    return M36 = result;
}

function calcM37() {
    let result;

    if (M13 < M15 / 2) {
        result = calcM26() + calcM23();
    } else {
        result = calcM27() + calcM23();
    }

    return M37 = result;
}

function calcM47() {
    let result;

    if (M18 === 0) {
        result = 0;
    } else if (M18 > 0 && M18 <= 50) {
        result = 15;
    } else if (M18 > 50 && M18 <= 100) {
        result = 17;
    } else if (M18 > 100 && M18 <= 150) {
        result = 19;
    } else if (M18 > 150 && M18 <= 200) {
        result = 21;
    } else if (M18 > 200 && M18 <= 250) {
        result = 23;
    } else if (M18 > 250 && M18 <= 300) {
        result = 25;
    } else if (M18 > 300 && M18 <= 350) {
        result = 27;
    } else if (M18 > 350 && M18 <= 400) {
        result = 29;
    } else if (M18 > 400 && M18 <= 450) {
        result = 31;
    } else if (M18 > 450 && M18 <= 500) {
        result = 33;
    } else if (M18 > 500 && M18 <= 550) {
        result = 35;
    } else if (M18 > 550 && M18 <= 600) {
        result = 37;
    } else if (M10 > 600 && M18 <= 650) {
        result = 39;
    } else if (M18 > 650 && M18 <= 700) {
        result = 41;
    } else if (M18 > 700 && M18 <= 750) {
        result = 43;
    } else if (M18 > 750 && M18 <= 800) {
        result = 45;
    } else if (M18 > 800 && M18 <= 850) {
        result = 47;
    } else if (M18 > 850 && M18 <= 900) {
        result = 49;
    } else {
        result = 50;
    }

    return M47 = result;
}

function calcM48() {
    let result;

    result = M18 === 0 ? 0 :
        M18 > 0 && M18 <= 50 ? 1 :
            M18 > 50 && M18 <= 100 ? 2 :
                M18 > 100 && M18 <= 200 ? 3 :
                    M18 > 200 && M18 <= 400 ? 4 : 5;

    return M48 = result;
}

function calcN33() {
    let result;
    if (M11 >= 20) {
        result = M13 * Math.tan(M11 * (Math.PI / 180)); // Converts degrees to radians
    } else {
        result = M13 * Math.tan(20 * (Math.PI / 180)); // Converts degrees to radians
    }
    return N31 = result;
}

function calcN28() {
    return N28 = M15 - M13;
}

function calcN29() {
    let result;

    if (M11 < 20) {
        result = calcN28() * Math.tan(20 * (Math.PI / 180)); // Converts degrees to radians
    } else {
        result = 0;
    }

    return N29 = result;
}

function calcN30() {
    let result;

    if (M11 < 20) {
        result = calcN28() * Math.tan(M11 * (Math.PI / 180)); // Converts degrees to radians
    } else {
        result = 0;
    }

    return N30 = result;
}

function calcN31() {
    let result;

    if (M11 < 20) {
        result = calcN29() - calcN30();
    } else {
        result = 0;
    }

    return N31 = result;
}

function calcN35() {
    let result;

    if (M13 <= 0.4) {
        result = 0.4;
    } else {
        result = M13 * Math.tan(45 * (Math.PI / 180)); // Converts degrees to radians
    }

    return N35 = result;
}

function calcN36() {
    let result;

    if (M13 < M15/2) {
        result = calcN31() + calcN33() + calcN35();
    } else {
        result = "nicht möglich";
    }

    return N36 = result;
}

function calcN37() {
    let result;

    if (calcN36() === "nicht möglich") {
        result = "nicht möglich";
    } else {
        if (M11 < 20) {
            return calcN36() - M13 * Math.tan(M11 * Math.PI / 180);
        } else {
            return calcN35();
        }
    }

    return N37 = result;
}

function calcM49() {
    return M49 = calcM21() + calcM37();
}

function calcN49() {
    let result;

    if (calcN37() === "nicht möglich") {
        result = "nicht möglich";
    } else {
        result = calcM21() + calcN37();
    }

    return N49 = result;
}

function loadPultdach(param) {

    switch(param) {
        case "M11":
            return M11;
            break;
        case "M13":
            return M13;
            break;
        case "M15":
            return M15;
            break;
        case "M18":
            return M18;
            break;
        case "M36":
            return calcM36();
        break;
        case "M37":
            return calcM37();
        break;
        case "N36":
            return calcN36();
            break;
        case "N37":
            return calcN37();
            break;
        case "M22":
            return calcM22();
            break;
        case "M49":
            return calcM49();
        break;
        case "N49":
            return calcN49();
        break;
        case "M47":
            return calcM47();
        break;
        case "M48":
            return calcM48();
        break;
        case "M20":
            return calcM20();
        case "M21":
            return calcM21();
        break;
    }
}

function updatePultdach(param, value) {
    switch(param) {
        case "M11":
            M11 = parseFloat(value.toString().replace(",", "."));
        break;
        case "M15":
            M15 = parseFloat(value.toString().replace(",", "."));
        break;
        case "M13":
            M13 = parseFloat(value.toString().replace(",", "."));
        break;
        case "M18":
            M18 = parseFloat(value.toString().replace(",", "."));
        break;
        case "M19":
            M19 = parseFloat(value.toString().replace(",", "."));

        break;
    }
}

export{
    loadPultdach, updatePultdach
}
