let P11 = null;
let P12 = null;
let P14 = null;
let P15 = null;
let P16 = null;
let PM18 = null;
let P18 = null;
let P19 = null;
let P20 = null;
let P21 = null;
let P22 = null;
let P23 = null;
let P24 = null;
let P25 = null;
let P26 = null;
let P27 = null;
let P28 = null;
let P29 = null;
let P30 = null;
let P31 = null;
let P32 = null;
let P36 = null;
let P37 = null;
let P38 = null;
let P39 = null;
let P40 = null;
let P41 = null;
let P42 = null;
let P47 = null;
let P48 = null;
let P49 = null;

let Q28 = null;
let Q31 = null;
let Q29 = null;
let Q30 = null;
let Q33 = null;
let Q35 = null;
let Q36 = null;
let Q37 = null;
let Q49 = null;

function calcP19() {
    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
    var bogenmaßP11 = P11 * radian; // Winkel P11 in Bogenmaß umrechnen

    return P19 =  (P16 / 2) * Math.tan(bogenmaßP11);

    // OLD 22.04
    const angleInDegrees = P11; // Replace with the actual angle in degrees
    const angleInRadians = (angleInDegrees * Math.PI) / 180;

    return P19 = (P15 / 2) * Math.tan(angleInRadians);
    /*
    const angleInDegrees = P11; // Replace with your desired angle in degrees

// Convert the angle from degrees to radians
    const angleInRadians = angleInDegrees * (Math.PI / 180);

// Calculate the result
    const result = (P15 / 2) * Math.tan(angleInRadians);
    return P19 = result;*/
}

function calcP20() {

    return P20 = (P16 / 2) * Math.tan(P12 * Math.PI / 180);

    //OLD 22.04
    const angleInDegrees = P12; // Replace with your desired angle in degrees

// Convert the angle from degrees to radians
    const angleInRadians = angleInDegrees * (Math.PI / 180);

// Calculate the result
    const result = (P16 / 2) * Math.tan(angleInRadians);
    return P20 = result;
}

function calcP21() {
    let result;

    if (PM18 <= 400) {
        result = 0.4;
    } else if (PM18 > 400 && PM18 <= 900) {
        result = 1;
    } else if (PM18 > 900) {
        result = 3;
    }

    return P21 = result;
}

function calcP22() {
    let result;

    if (P11 <= 30) {
        result = 0;
    } else if (P11 <= 45) {
        result = 10 / 15 * (P11 - 30);
    } else if (P11 <= 60) {
        result = 10 + 10 / 15 * (P11 - 45);
    } else {
        result = 20;
    }

    return P22 = result;
}

function calcP24() {
    let result;

    if (P11 < 20) {
        result = (P11 / 20) * 0.85;
    } else if (P11 <= 30) {
        result = 0.85 - (0.15 / 10) * (P11 - 20);
    } else if (P11 <= 45) {
        result = 0.7 - (0.2 / 15) * (P11 - 30);
    } else if (P11 <= 60) {
        result = 0.5 - (0.05 / 15) * (P11 - 45);
    } else {
        result = 0.45;
    }

    return P24 = result;
}

function calcP23() {
    let result;

    if (P12 <= 30) {
        result = 0;
    } else if (P12 <= 45) {
        result = 10 / 15 * (P12 - 30);
    } else if (P12 <= 60) {
        result = 10 + 10 / 15 * (P12 - 45);
    } else {
        result = 20;
    }

    return P23 = result;
}

function calcP25() {
    let result;

    if (P12 < 20) {
        result = (P12 / 20) * 0.85;
    } else if (P12 <= 30) {
        result = 0.85 - (0.15 / 10) * (P12 - 20);
    } else if (P12 <= 45) {
        result = 0.7 - (0.2 / 15) * (P12 - 30);
    } else if (P12 <= 60) {
        result = 0.5 - (0.05 / 15) * (P12 - 45);
    } else {
        result = 0.45;
    }

    return P25 = result;
}

function calcP26() {
    let result;

    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
    var winkel20 = 20 * radian; // Winkel von 20 Grad im Bogenmaß

    if (P11 === 0) {
        result = (0 + P16 / 2) * Math.tan(winkel20) - calcP19();
    } else if (P11 < 20) {
        result = (P14 + P16 / 2) * Math.tan(winkel20) - calcP19();
    } else {
        var bogenmaßP11 = (P11 - calcP22()) * radian; // Winkel P11 in Bogenmaß umrechnen
        result = P14 * Math.tan(bogenmaßP11);
    }
    return P26 = result;
    // OLD 23.04


    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
    var winkel20 = 20 * radian; // Winkel von 20 Grad im Bogenmaß
    var bogenmaßP11 = P11 * radian; // Winkel P11 in Bogenmaß umrechnen

    if (P11 === 0) {
        result = ((0 + P16 / 2) * Math.tan(winkel20)) - calcP19();
    } else if (P11 < 20) {
        console.log("calcP26", "(("+P14 +"+"+ P16 +"/"+ 2+") * Math.tan("+winkel20+")) -"+ calcP19());
        result = ((P14 + P16 / 2) * Math.tan(winkel20)) - calcP19();
    } else {
        result = (P14 * Math.tan(bogenmaßP11 - calcP22() * radian)) - calcP19();
        console.log("calcP26", "("+P14 +"* Math.tan("+bogenmaßP11 +"-"+ calcP22() +"*"+ radian+")) - "+calcP19());
        console.log("calcP26", result);
    }

    return P26 = result;
    // OLD 22.04

    if (P11 === 0) {
        result = (0 + P15 / 2) * Math.tan(20 * (Math.PI / 180)) - calcP19();
    } else if (P11 < 20) {
        result = (P14 + P15 / 2) * Math.tan(20 * (Math.PI / 180)) - calcP19();
    } else {
        result = P14 * Math.tan((P11 - calcP22()) * (Math.PI / 180));
    }

    return P26 = result;
}

function calcP27() {
    let result;

    const cP20 = calcP20();
    const cP23 = calcP23();

    console.log("calcP20()", cP20);
    console.log("calcP23()", cP23);

    console.log("calcP27- P12",P12);
    console.log("calcP27- P14",P14);
    console.log("calcP27- P16",P16);

    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß
    var winkel20 = 20 * radian; // Winkel von 20 Grad im Bogenmaß
    var bogenmaßP12 = P12 * radian; // Winkel P12 in Bogenmaß umrechnen

    if (P12 === 0) {
        result = ((0 + P16 / 2) * Math.tan(winkel20)) - cP20;
    } else if (P12 < 20) {
        result = ((P14 + P16 / 2) * Math.tan(winkel20)) - cP20;
    } else {
        result = (P14 * Math.tan(bogenmaßP12 - cP23 * radian));
    }

    console.log("calcP27", result);


    //=WENN(P12=0;(0+P16/2)*TAN(BOGENMASS(20))-P20;WENN(P12<20;(P14+P16/2)*TAN(BOGENMASS(20))-P20;P14*TAN(BOGENMASS(P12-P23))))
    return P27 = result;

    //OLD 22.04
    if (P12 === 0) {
        result = (0 + P16 / 2) * Math.tan(20 * (Math.PI / 180)) - calcP20();
    } else if (P12 < 20) {
        result = (P14 + P16 / 2) * Math.tan(20 * (Math.PI / 180)) - calcP20();
    } else {
        result = P14 * Math.tan((P12 - calcP23()) * (Math.PI / 180));
    }

    return P27 = result;
}

function calcP28() {
    let result;

    if (P11 < 20) {
        result = (1 + calcP24()) * (P16 / 2) * Math.tan(20 * (Math.PI / 180)) - calcP19();
    } else {
        result = calcP24() * calcP19();
    }

    return P28 = result;
}

function calcP29() {
    let result;

    if (P12 < 20) {
        result = (1 + calcP25()) * (P16 / 2) * Math.tan(20 * (Math.PI / 180)) - calcP20();
    } else {
        result = calcP25() * calcP20();
    }

    return P29 = result;
}

function calcP30() {

    console.log("CALCP30", P15 +"/"+ P16 +"*"+ calcP26() +"+  ("+1 +"-"+ P15 +"/"+ P16+") * "+calcP27() );
    return P30 = P15 / P16 * calcP26() + (1 - P15 / P16) * calcP27()
  // return P30 = (P15 / P16) * calcP26() + (1 - P15 / P16) * calcP27();
}

function calcP31() {
    //=P15/P16*P28+(1-P15/P16)*P29
    console.log("calcP31", "("+P15 +"/"+ P16+") * "+calcP28() +"+ ("+1 +"-"+ P15 +"/"+ P16+") * "+calcP29())
    return P31 = (P15 / P16) * calcP28() + (1 - P15 / P16) * calcP29();
}

function calcP32() {
    const angleInRadians = P11 * (Math.PI / 180);

// Calculate the result using the Math.tan function
    const result = P14 * Math.tan(angleInRadians);
    return P32 = result;
}

function calcP36() {
    console.log("calcP36", "("+calcP30() +">"+ calcP31()+") ? "+calcP21() +"+"+ calcP31() +"+"+ calcP32() +":"+ calcP21() +"+"+ calcP30() +"+"+ calcP32());
    return P36 = (calcP30() > calcP31()) ? calcP21() + calcP31() + calcP32() : calcP21() + calcP30() + calcP32();
    //OLD 22.04
    return P36 = calcP30() > calcP31() ? calcP21() + calcP31() + calcP32() : calcP21() + calcP30() + calcP32();
}

function calcP37() {
    return P37 = (calcP30() > calcP31()) ? calcP31() + calcP21() : calcP30() + calcP21();
    //OLD 22.04
    return P37 = calcP30() > calcP31() ? calcP31() + calcP21() : calcP30() + calcP21();
}

function calcP39() {
    const angleInRadians = P12 * (Math.PI / 180);

// Calculate the result using the Math.tan function
    const result = ((P16 - P15) / 2) * Math.tan(angleInRadians);
    return P39 = result;
}

function calcP38() {
    const angleInDegrees = P11; // Replace with the actual angle in degrees
    const angleInRadians = (angleInDegrees * Math.PI) / 180;

    return P38 = (P15 / 2) * Math.tan(angleInRadians);
}

function calcP41() {
    return P41 = calcP38() + calcP39();
}

function calcP42() {
    return P42 = P40 + calcP41();
}

function calcP47() {
    let result;

    if (P18 === 0) {
        result = 0;
    } else if (P18 > 0 && P18 <= 50) {
        result = 15;
    } else if (P18 > 50 && P18 <= 100) {
        result = 17;
    } else if (P18 > 100 && P18 <= 150) {
        result = 19;
    } else if (P18 > 150 && P18 <= 200) {
        result = 21;
    } else if (P18 > 200 && P18 <= 250) {
        result = 23;
    } else if (P18 > 250 && P18 <= 300) {
        result = 25;
    } else if (P18 > 300 && P18 <= 350) {
        result = 27;
    } else if (P18 > 350 && P18 <= 400) {
        result = 29;
    } else if (P18 > 400 && P18 <= 450) {
        result = 31;
    } else if (P18 > 450 && P18 <= 500) {
        result = 33;
    } else if (P18 > 500 && P18 <= 550) {
        result = 35;
    } else if (P18 > 550 && P18 <= 600) {
        result = 37;
    } else if (P18 > 600 && P18 <= 650) {
        result = 39;
    } else if (P18 > 650 && P18 <= 700) {
        result = 41;
    } else if (P18 > 700 && P18 <= 750) {
        result = 43;
    } else if (P18 > 750 && P18 <= 800) {
        result = 45;
    } else if (P18 > 800 && P18 <= 850) {
        result = 47;
    } else if (P18 > 850 && P18 <= 900) {
        result = 49;
    } else {
        result = 50;
    }

    return P47 = result;
}

function calcP48() {
    let result;

    if (P18 === 0) {
        result = 0;
    } else if (P18 > 0 && P18 <= 50) {
        result = 1;
    } else if (P18 > 50 && P18 <= 100) {
        result = 2;
    } else if (P18 > 100 && P18 <= 200) {
        result = 3;
    } else if (P18 > 200 && P18 <= 400) {
        result = 4;
    } else {
        result = 5;
    }

    return P48 = result;
}

function calcP49() {
    return P49 = calcP37() + calcP42();
}

function calcQ28() {
    return Q28 = P16 / 2 - P14;
}

function calcQ29() {
    let result;

    result = (P11 < 20) ? (P15 / 4) * Math.tan(20 * Math.PI / 180) : 0;
    return Q29 = result;
    // OLD 22.04
    if (P11 < 20) {
        result = calcQ28() * Math.tan(20 * (Math.PI / 180)); // Converts degrees to radians
    } else {
        result = 0;
    }
    return Q29 = result;
}

function calcQ30() {
    let result;

    result = (P11 < 20) ? (P15 / 4) * Math.tan(P11 * Math.PI / 180) : 0;

    return Q30 = result;

    //OLD 22.04
    if (P11 < 20) {
        result = calcQ28() * Math.tan(P11 * (Math.PI / 180)); // Converts degrees to radians
    } else {
        result = 0;
    }

    return Q30 = result;
}

function calcQ31() {
    let result;

    if (P11 < 20) {
        result = calcQ29() - calcQ30();
        console.log("calcQ31",calcQ29() +"-"+ calcQ30())
    } else {
        result = 0;
    }

    return Q31 = result;
}

function calcQ33() {
    let result;

    if (P11 >= 20) {
        result = P14 * Math.tan(P11 * (Math.PI / 180)); // Converts degrees to radians
    } else {
        result = P14 * Math.tan(20 * (Math.PI / 180)); // Converts 20 degrees to radians
    }

    return Q33 = result;
}

function calcQ35() {
    let result;

    if (P14 <= 0.4) {
        result = 0.4;
    } else {
        result = P14 * Math.tan(45 * (Math.PI / 180)); // Converts degrees to radians
    }

    return Q35 = result;
}

function calcQ36() {
    let result;

    if (P14 < (P16 / 4)) {
//        console.log(calcQ31() +"+"+ calcQ33() +"+"+ calcQ35());
        result = calcQ31() + calcQ33() + calcQ35();
    } else {
        result = "nicht möglich";
    }

    return Q36 = result;
}

function calcQ37() {
    let result;

    if (calcQ36() == "nicht möglich") {
        result = "nicht möglich";
    } else {
        if (P11 < 20) {
            result = calcQ36() - P14 * Math.tan(P11 * Math.PI / 180);
        } else {
            result = calcQ35();
        }
    }

    return Q37 = result;

    //OLD 22.04
    if (Q36 === "nicht möglich") {
        result = "nicht möglich";
    } else {

        if (P14 <= 0.4) {
            result = 0.4 + Q31;
        } else {
            result = P14 * Math.tan(45 * (Math.PI / 180) + calcQ31()); // Converts degrees to radians
        }
    }

    return Q37 = result;
}

function calcQ49() {
    let result;
    if (calcQ37() === "nicht möglich") {
        result = "nicht möglich";
    } else {
        result = calcQ37() + calcP42();
    }

    return Q49 = result;
}


function loadMassarddach(param) {

    switch(param) {
        case "P11":
            return P11;
            break;
        case "P12":
            return P12;
            break;
        case "P14":
            return P14;
            break;
        case "P15":
            return P15;
            break;
        case "P16":
            return P16;
            break;
        case "P21":
            return calcP21();
            break;
        case "P18":
            return P18;
        break;
        case "P36":
            return calcP36();
        break;
        case "P37":
            return calcP37();
        break;
        case "P41":
            return calcP41();
            break;
        case "P42":
            return calcP42();
            break;
        case "P47":
            return calcP47();
            break;
        case "P48":
            return calcP48();
            break;
        case "P49":
            return calcP49();
        break;
        case "Q49":
            return calcQ49();
        break;
        case "Q37":
            return calcQ37();
            break;

        case "Q36":
            return calcQ36();
            break;
    }
}

function updateMassarddach(param, value) {
    switch(param) {
        case "P11":
            P11 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P12":
            P12 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P15":
            P15 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P16":
            P16 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P14":
            P14 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P18":
            P18 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P40":
            P40 = parseFloat(value.toString().replace(",", "."));
            break;
    }
}

export{
    loadMassarddach, updateMassarddach
}
