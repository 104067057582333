let P7 = 0;
let P9 = null;
let P10 = null;
let P11 = null;
let P12 = null;
let P13 = null;
let P14 = null;
let P15 = null;
let P16 = null;
let P17 = null;
let P18 = null;

let D20 = null;
let D21 = null;
let D22 = null;
let D23 = null;
let D24 = null;

let P26 = null;
let P27 = null;
let P28 = null;
let P29 = null;
let P30 = null;
let P31 = null;
let P32 = null;
let P35 = null;
let P36 = null;
let P39 = null;
let P40 = null;
let P42 = null;
let P43 = null;
let P44 = null;
let P45 = null;


function degreeToRadians($degree)
{
    return $degree * Math.PI / 180;
}

function calcP18() {
    let result = null;

    result = P17;

    return P18 = result;
}

function calcD22() {
    //calc D21? Check it
    let result = null;
    if (D21 <= 400) {
        result = 0.4;
    } else if (D21 > 400 && D21 <= 900) {
        result = 1;
    } else if (D21 > 900) {
        result = 3;
    }

    return D22 = result;
}

function calcP26() {
    const P13Value = P13;

    const result = P13Value < 20
        ? (P13Value / 20 * 0.85)
        : (P13Value <= 30
            ? 0.85 - 0.15 / 10 * (P13Value - 20)
            : (P13Value <= 45
                ? 0.7 - 0.2 / 15 * (P13Value - 30)
                : (P13Value <= 60
                    ? 0.5 - 0.05 / 15 * (P13Value - 45)
                    : 0.45)));

    return P26 = result;
}

function calcP27() {
    const P14Value = P14;

    let result;

    if (P14Value < 20) {
        result = (P14Value / 20 * 0.85);
    } else if (P14Value <= 30) {
        result = 0.85 - 0.15 / 10 * (P14Value - 20);
    } else if (P14Value <= 45) {
        result = 0.7 - 0.2 / 15 * (P14Value - 30);
    } else if (P14Value <= 60) {
        result = 0.5 - 0.05 / 15 * (P14Value - 45);
    } else {
        result = 0.45;
    }

    return P27 = result;
}

function calcP28() {
    const P13Value = P13;

    const result = (P9 / 2) * Math.tan(P13Value * (Math.PI / 180));

    return P28 = result;
}

function calcP29() {
    const P10Value = P10;
    const P14Value = P14;

    const result = (P10Value / 2) * Math.tan(P14Value * (Math.PI / 180));

    return P29 = result;
}

function calcP30() {
    const P13Value = P13;
    const P26Value = calcP26();
    const P9Value = P9;
    const P28Value = calcP28();

    const result = P13Value < 20 ? (1 + P26Value) * P9Value / 2 * Math.tan(20 * Math.PI / 180) - P28Value : P26Value * P28Value;

    return P30 = result;
}

function calcP31() {
    const P14Value = P14;
    const P27Value = calcP27();
    const P10Value = P10;
    const P29Value = calcP29();

    let result;

    if (P14Value < 20) {
        result = (1 + P27Value) * P10Value / 2 * Math.tan(degreeToRadians(20)) - P29Value;
    } else {
        result = P27Value * P29Value;
    }

    return P31 = result;
}

function calcP32() {
    const P9Value = P9;
    const P10Value = P10;
    const P30Value = calcP30();
    const P31Value = calcP31();

    const result = (P9Value / P10Value) * P30Value + (1 - P9Value / P10Value) * P31Value;

    return P32 = result;
}

function calcP35() {
    /**
     *
     * @deprecated 27.05.24

    const P7Value = P7;
    const P11Value = P11;
    const P15Value = P15;
    const P10Value = P10;

    let result;

    if (P7Value == 1) {
        result = "entfällt"; // Assuming "entfällt" means "omitted" or "excluded" in German.
    } else {
        result = P11Value * Math.sin((P15Value * Math.PI) / 180) + P10Value * Math.cos((P15Value * Math.PI) / 180);
    }*/


    let result;

    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß

    if (P7 === 1) {
        result = "entfällt";
    } else if (P14 > 90) {
        var angle = (180 - P14) * radian;
        result = P10 * Math.sin(angle) + P9 * Math.cos(angle);
    } else {
        var angle = P14 * radian;
        result = P10 * Math.sin(angle) + P9 * Math.cos(angle);
    }

    return P35 = result;
}

function calcP36() {
    const P35Value = calcP35();
    const P12Value = P12;

    let result;

    if (P35Value == "entfällt") {
        result = 6 * P12Value;
    } else {
        result = Math.round(((1.75 * P35Value) / (1 + 0.25 * P35Value / P12Value) * 100),2) / 100;
    }

    return P36 = result;
}

function calcP39() {
    const P16Value = P16;
    const P36Value = calcP36();

    const result = P16Value >= P36Value ? "nicht relevant" : Math.sqrt(1 - Math.pow(P16Value, 2) / Math.pow(P36Value, 2));

    return P39 = result;
}

function calcP40() {
    const P39Value = calcP39();
    const P11Value = P11;
    const P32Value = calcP32();
    const P18Value = calcP18();
    const D20Value = D20;

    let result;

    if (P39Value == "nicht relevant") {
        result = "nicht relevant";
    } else {
        const intermediateResult = P39Value * (P11Value + P32Value) + P18Value - D20Value;
        result = intermediateResult <= 0 ? "nicht relevant" : intermediateResult;
    }

    return P40 = result;
}

function calcP42() {
    const P40Value = calcP40();

    let result;

    if (P40Value == "nicht relevant") {
        result = "nicht relevant";
    } else {
        const D22Value = calcD22();

        // =WENN(P40="nicht relevant";"nicht relevant";WENN((P40+$E22)<=$E23;"nicht relevant";P40+$E22))

        //=WENN(S40="nicht relevant";"nicht relevant";WENN((S40+$E22)<=$E23;"nicht relevant";S40+$E22))

        result = (P40Value+D22Value)<=D23 ? "nicht relevant" : P40Value + D22Value;
    }

    return P42 = result;
}

function calcP43() {
    const P42Value = calcP42();
    const D23Value = D23;

    let result = null;

    if(P42Value == "nicht relevant"){
        result = "nicht relevant";
    }else {
        if(P42Value <= D23Value) {
            result = "nicht relevant";
        }else{
            result = P42Value - D23Value;
        }

    }

    return P43 = result;
}

function calcP44() {
    const P42Value = calcP42();
    const D24Value = D24;
    const P43Value = calcP43();

    const result = P43Value == "nicht relevant" ? "nicht relevant" : D24Value + P43Value;

    return P44 = result;
}

function calcP45() {
    const P42Value = calcP42();
    const D20Value = D20;

    const result = calcP43() == "nicht relevant" ? "nicht relevant" : D20Value + P42Value;

    return P45 = result;
}

function loadVorgMassarddach(param) {

    switch(param) {
        case "P9":
            return P9;
        break;
        case "P10":
            return P10;
        break;
        case "P11":
            return P11;
        break;
        case "P12":
            return P12;
        break;
        case "P13":
            return P13;
        break;
        case "P14":
            return P14;
        break;
        case "P15":
            return P15;
        break;
        case "P16":
            return P16;
        break;
        case "P17":
            return P17;
        break;
        case "P31":
            return calcP31();
        break;
        case "P35":
            return calcP35();
        break;
        case "P36":
            return calcP36();
        break;
        case "P42":
            return calcP42();
        break;
        case "P43":
            return calcP43();
        break;
        case "P44":
            return calcP44();
        break;
        case "P45":
            return calcP45();
        break;

    }
}

function updateVorgMassarddach(param, value) {
    switch(param) {
        case 'P7':
            P7 = parseInt(value);
            break;
        case 'D20':
            D20 = parseFloat(value)
        break;
        case 'D21':
            D21 = parseFloat(value.toString().replace(",", "."))
            break;
        case 'D22':
            D22 = parseFloat(value)
        break;
        case 'D23':
            D23 = parseFloat(value)
        break;
        case 'D24':
            D24 = parseFloat(value)
        break;
        case "P9":
            P9 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P10":
            P10 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P11":
            P11 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P12":
            P12 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P13":
            P13 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P15":
            P15 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P16":
            P16 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P14":
            P14 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P17":
            P17 = parseFloat(value.toString().replace(",", "."));
            break;
        case "P18":
            P18 = parseFloat(value.toString().replace(",", "."));
            break;

    }
}

export{
    loadVorgMassarddach, updateVorgMassarddach
}
