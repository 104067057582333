import React from 'react';
import './App.css';
import axios from "axios";
import {BASE_URL} from "./Constants";
import LoadingSpinner from "./LoadingSpinner";
import Button from "./Button";
import moment from "moment";
import {
    loadAsyncSatteldach,
    updateAll,
    update,
    load,
    calcG11,
    calcG20,
    calcG21,
    calcG23,
    calcG24,
    calcG25,
    calcG26,
    calcG27,
    calcG32,
    calcG36,
    calcG37,
    calcG49,
    calcG47,
    calcG48,
    calcH36,
    calcH37,
    calcH49
} from "./helper/assymsattelsach"
import {
    loadFlachdach,
    calcT45,
    calcT37,
    calcT23,
    calcT38,
    calcT26, calcT22, calcU35, calcU37, calcT49, calcU49, calcT46, calcT47, calcT48, calcT42, calcT40, updateFlachdach
} from "./helper/flachdach";

import {
    loadMassarddach, updateMassarddach
} from "./helper/massarddach";

import {loadPultdach, updatePultdach} from "./helper/pultdach";

import {loadSheddach, updateSheddach} from "./helper/sheddach";

import {loadWalmdach, updateWalmdach} from "./helper/walmdach";
import {loadSatteldach, updateSatteldach} from "./helper/satteldach";

import {loadVorgSatteldach, updateVorgSatteldach} from "./helper/vorg_satteldach";
import {loadVorgAsyncSatteldach, updateVorgAsyncSatteldach} from "./helper/vorg_assymsattelsach";
import {loadVorgWalmdach, updateVorgWalmdach} from "./helper/vorg_walmdach";
import {loadVorgPultdach, updateVorgPultdach} from "./helper/vorg_pultdach";
import {loadVorgMassarddach, updateVorgMassarddach} from "./helper/vorg_massarddach";
import {loadVorgFlachdach, updateVorgFlachdach} from "./helper/vorg_flachdach";
import {loadVorgSheddach, updateVorgSheddach} from "./helper/vorg_sheddach";

class Add extends React.Component {
     makeid(length) {
        let result = '';
        const characters = '012345678901234567890123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    getCookie(key) {
        var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    }

    state = {
        intervalId: null,
        project_title: "",
        project_firstname: "",
        project_lastname: "",
        project_street: "",
        project_zip: "",
        project_city: "",
        project_phone: "",
        project_mobile: "",
        project_email: "",
        project_country: "",
        isLoading: false,
        list: [],
        width: window.innerWidth,
        newImagePreview: [],
        newImage: [],
        size: "",
        weight: "PR-"+this.makeid(10),
        category_slug: "Abholung/Sonstiges",
        name: "",
        description: "",
        date_from: moment().format("Y-MM-DD"),
        date_to: "",
        location: "",
        country: "",
        street: "",
        latitude: "",
        longitude: "",
        radius: "",
        locale: "",
        zahlungsempfaenger: "",
        customer_email: "",
        type_dach_selected: 0,
        type_dach: "daecher",
        show: "daecher",
        type_dach_selected_art: 0,
        vorg_daecher: [{
            name: "Satteldach",
            slug: "satteldach",
            image: "Satteldach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextSattel15",
                label: "Höhe des realen Daches des vorgelagerten Gebäudes (bei geschlossener Bauweise vom höchsten Gebäude)",
                subfix: "m",
            }, {
                value: "",
                name: "TextSattel16",
                label: "leff = Effektive Länge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                subfix: "m",
            }, {
                value: "",
                name: "TextSattel10",
                label: "Horizontale Ausdehnung lRZ der Rezirkulationsszone",
                subfix: "m",
            },{
                value: "",
                name: "TextSattel11",
                label: "HA2 = Erforderliche Schornstein-Mündungshöhe über dem First, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m"
            }, {
                value: "",
                name: "TextSattel82",
                label: "Zusätzliche Schornsteinhöhe durch das vorgelagerte Gebäude",
                subfix: "m"
            }, {
                value: "",
                name: "TextSattel94",
                label: "Erforderliche Schornstein-Mündungshöhe über der Dachfläche, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m"
            }, {
                value: "",
                name: "TextSattel83",
                label: "HGes. = Erforderliche Schornstein-Mündungshöhe über der Bezugsebene, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m"
            }],
            items: [{
                label: "bv = Breite der Giebelseite des vorgelagerten Einzel- bzw. Gruppengebäudes, gemessen von Traufe zu Traufe",
                value: "",
                name: "TextSattel7",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "lv = Seitenlänge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                value: "",
                name: "TextSattel4",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HFirst.V = Firsthöhe des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextSattel21",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "α = Dachneigungswinkel des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextSattel1",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            },{
                label: "β = horizontaler Winkel von der Außenkante des vorgelagerten Gebäudes zu der \"Verbindungslinie von der Mitte des vorgelagerten Gebäude zum Schornstein\"",
                value: "",
                name: "TextSattel14",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "lA = horizontale Entfernung von Mitte-Schornsteinmündung bis zum vorgelagerten Gebäude bzw. Gebäudegruppe",
                value: "",
                name: "TextSattel9",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "Δh = Höhendifferenz zwischen vorgelagertem Gebäude und Gebäude mit Schornstein (wenn nicht zutreffend = 0, wenn vorgel. Gebäude niedriger, Minuszeichen vor Wert setzen)",
                value: "",
                name: "TextSattel131",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }]
        },{
            name: "asym Satteldach",
            slug: "assymsattelsach",
            image: "assym.Satteldach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextAsym15",
                label: "Höhe des realen Daches des vorgelagerten Gebäudes (bei geschlossener Bauweise vom höchsten Gebäude)",
                subfix: "m",
            }, {
                value: "",
                name: "TextAsym16",
                label: "leff = Effektive Länge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                subfix: "m",
            }, {
                value: "",
                name: "TextAsym21",
                label: "Horizontale Ausdehnung lRZ der Rezirkulationsszone",
                subfix: "m",
            },{
                value: "",
                name: "TextAsym22",
                label: "HA2 = Erforderliche Schornstein-Mündungshöhe über dem First, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }, {
                value: "",
                name: "TextAsym113",
                label: "Zusätzliche Schornsteinhöhe durch das vorgelagerte Gebäude ",
                subfix: "m",
            }, {
                value: "",
                name: "TextAsym115",
                label: "Erforderliche Schornstein-Mündungshöhe über der Dachfläche, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }, {
                value: "",
                name: "TextAsym114",
                label: "HGes. = Erforderliche Schornstein-Mündungshöhe über der Bezugsebene, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }],
            items: [{
                label: "bv = Breite der Giebelseite des vorgelagerten Einzel- bzw. Gruppengebäudes, gemessen von Traufe zu Traufe",
                value: "",
                name: "TextAsym18",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "lv = Seitenlänge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                value: "",
                name: "TextAsym13",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HFirst.V = Firsthöhe des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextAsym17",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "α1 = Dachneigungswinkel des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextAsym24",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "α2 = Dachneigungswinkel des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextAsym19",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "β = horizontaler Winkel von der Außenkante des vorgelagerten Gebäudes zu der \"Verbindungslinie von der Mitte des vorgelagerten Gebäude zum Schornstein\"",
                value: "",
                name: "TextAsym14",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "lA = horizontale Entfernung von Mitte-Schornsteinmündung bis zum vorgelagerten Gebäude bzw. Gebäudegruppe",
                value: "",
                name: "TextAsym20",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "Δh = Höhendifferenz zwischen vorgelagertem Gebäude und Gebäude mit Schornstein (wenn nicht zutreffend = 0, wenn vorgel. Gebäude niedriger, Minuszeichen vor Wert setzen)",
                value: "",
                name: "TextAsym132",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }]
        }, {
            name: "Walmdach",
            slug: "walmdach",
            image: "Walmdach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextWalm27",
                label: "Höhe des realen Daches des vorgelagerten Gebäudes (bei geschlossener Bauweise vom höchsten Gebäude)",
                subfix: "m",
            }, {
                value: "",
                name: "TextWalm28",
                label: "leff = Effektive Länge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                subfix: "m",
            }, {
                value: "",
                name: "TextWalm33",
                label: "Horizontale Ausdehnung lRZ der Rezirkulationsszone",
                subfix: "m",
            }, {
                value: "",
                name: "TextWalm34",
                label: "HA2 = Erforderliche Schornstein-Mündungshöhe über dem First, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            },{
                value: "",
                name: "TextWalm116",
                label: "Zusätzliche Schornsteinhöhe durch das vorgelagerte Gebäude ",
                subfix: "m",
            }, {
                value: "",
                name: "TextWalm118",
                label: "Erforderliche Schornstein-Mündungshöhe über der Dachfläche, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }, {
                value: "",
                name: "TextWalm117",
                label: "HGes. = Erforderliche Schornstein-Mündungshöhe über der Bezugsebene, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }],
            items: [{
                label: "bv = Breite der Giebelseite des vorgelagerten Einzel- bzw. Gruppengebäudes, gemessen von Traufe zu Traufe",
                value: "",
                name: "TextWalm30",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "lv = Seitenlänge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                value: "",
                name: "TextWalm25",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HFirst.V = Firsthöhe des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextWalm29",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "α = Dachneigungswinkel des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextWalm36",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "β = horizontaler Winkel von der Außenkante des vorgelagerten Gebäudes zu der \"Verbindungslinie von der Mitte des vorgelagerten Gebäude zum Schornstein\"",
                value: "",
                name: "TextWalm26",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "lA = horizontale Entfernung von Mitte-Schornsteinmündung bis zum vorgelagerten Gebäude bzw. Gebäudegruppe",
                value: "",
                name: "TextWalm32",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "Δh = Höhendifferenz zwischen vorgelagertem Gebäude und Gebäude mit Schornstein (wenn nicht zutreffend = 0, wenn vorgel. Gebäude niedriger, Minuszeichen vor Wert setzen)",
                value: "",
                name: "TextWalm133",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }]
        }, {
            name: "Pultdach",
            slug: "pultdach",
            image: "Pultdach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextPult39",
                label: "Höhe des realen Daches des vorgelagerten Gebäudes (bei geschlossener Bauweise vom höchsten Gebäude)",
                subfix: "m",
            }, {
                value: "",
                name: "TextPult40",
                label: "leff = Effektive Länge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                subfix: "m",
            }, {
                value: "",
                name: "TextPult45",
                label: "Horizontale Ausdehnung lRZ der Rezirkulationsszone",
                subfix: "m",
            },{
                value: "",
                name: "TextPult46",
                label: "HA2 = Erforderliche Schornstein-Mündungshöhe über dem First, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }, {
                value: "",
                name: "TextPult119",
                label: "Zusätzliche Schornsteinhöhe durch das vorgelagerte Gebäude ",
                subfix: "m",
            }, {
                value: "",
                name: "TextPult121",
                label: "Erforderliche Schornstein-Mündungshöhe über der Dachfläche, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }, {
                value: "",
                name: "TextPult120",
                label: "HGes. = Erforderliche Schornstein-Mündungshöhe über der Bezugsebene, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }],
            items: [{
                label: "bv = Breite der Giebelseite des vorgelagerten Einzel- bzw. Gruppengebäudes, gemessen von First zu Traufe",
                value: "",
                name: "TextPult42",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "lv = Seitenlänge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                value: "",
                name: "TextPult37",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HFirst.V = Firsthöhe des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextPult41",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "α = Dachneigungswinkel des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextPult43",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "β = horizontaler Winkel von der Außenkante des vorgelagerten Gebäudes zu der \"Verbindungslinie von der Mitte des vorgelagerten Gebäude zum Schornstein\"",
                value: "",
                name: "TextPult38",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "lA = horizontale Entfernung von Mitte-Schornsteinmündung bis zum vorgelagerten Gebäude bzw. Gebäudegruppe",
                value: "",
                name: "TextPult44",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "Δh = Höhendifferenz zwischen vorgelagertem Gebäude und Gebäude mit Schornstein (wenn nicht zutreffend = 0, wenn vorgel. Gebäude niedriger, Minuszeichen vor Wert setzen)",
                value: "",
                name: "TextPult134",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }]
        }, {
            name: "Mansarddach",
            slug: "masarddach",
            image: "Mansarddach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextMansard50",
                label: "Höhe des realen Daches des vorgelagerten Gebäudes (bei geschlossener Bauweise vom höchsten Gebäude)",
                subfix: "m",
            }, {
                value: "",
                name: "TextMansard51",
                label: "leff = Effektive Länge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                subfix: "m",
            }, {
                value: "",
                name: "TextMansard56",
                label: "Horizontale Ausdehnung lRZ der Rezirkulationsszone",
                subfix: "m",
            },{
                value: "",
                name: "TextMansard57",
                label: "HA2 = Erforderliche Schornstein-Mündungshöhe über dem First, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }, {
                value: "",
                name: "TextMansard122",
                label: "Zusätzliche Schornsteinhöhe durch das vorgelagerte Gebäude ",
                subfix: "m",
            }, {
                value: "",
                name: "TextMansard124",
                label: "Erforderliche Schornstein-Mündungshöhe über der Dachfläche, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }, {
                value: "",
                name: "TextMansard123",
                label: "HGes. = Erforderliche Schornstein-Mündungshöhe über der Bezugsebene, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }],
            items: [{
                label: "bo = Breite der Giebelseite des vorgelagerten Einzel- bzw. Gruppengebäudes in Höhe der Dachflächenknickpunkte",
                value: "",
                name: "TextMansard53",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            },{
                label: "bv = Breite der Giebelseite des vorgelagerten Einzel- bzw. Gruppengebäudes, gemessen von First zu Traufe",
                value: "",
                name: "TextMansard60",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "lv = Seitenlänge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                value: "",
                name: "TextMansard48",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "HFirst.V = Firsthöhe des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextMansard52",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "αo = Dachneigungswinkel des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextMansard59",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "αu = Dachneigungswinkel des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextMansard54",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "β = horizontaler Winkel von der Außenkante des vorgelagerten Gebäudes zu der \"Verbindungslinie von der Mitte des vorgelagerten Gebäude zum Schornstein\"",
                value: "",
                name: "TextMansard49",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "lA = horizontale Entfernung von Mitte-Schornsteinmündung bis zum vorgelagerten Gebäude bzw. Gebäudegruppe",
                value: "",
                name: "TextMansard55",
                disabled: false,
                subfix: "m",
                type: "input"
            }, {
                label: "Δh = Höhendifferenz zwischen vorgelagertem Gebäude und Gebäude mit Schornstein (wenn nicht zutreffend = 0, wenn vorgel. Gebäude niedriger, Minuszeichen vor Wert setzen)",
                value: "",
                name: "TextMansard135",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }]
        }, {
            name: "Flachdach",
            slug: "flachdach",
            image: "Flachdach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextFlach64",
                label: "leff = Effektive Länge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                subfix: "m",
            }, {
                value: "",
                name: "TextFlach69",
                label: "Horizontale Ausdehnung lRZ der Rezirkulationsszone",
                subfix: "m",
            }, {
                value: "",
                name: "TextFlach70",
                label: "HA2 = Erforderliche Schornstein-Mündungshöhe über dem First, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            },{
                value: "",
                name: "TextFlach125",
                label: "Zusätzliche Schornsteinhöhe durch das vorgelagerte Gebäude ",
                subfix: "m",
            }, {
                value: "",
                name: "TextFlach127",
                label: "Erforderliche Schornstein-Mündungshöhe über der Dachfläche, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }, {
                value: "",
                name: "TextFlach126",
                label: "HGes. = Erforderliche Schornstein-Mündungshöhe über der Bezugsebene, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }],
            items: [{
                label: "bv = Breite der Giebelseite des vorgelagerten Einzel- bzw. Gruppengebäudes, gemessen von First zu Traufe",
                value: "",
                name: "TextFlach66",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "lv = Seitenlänge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                value: "",
                name: "TextFlach61",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HFirst.V = Firsthöhe des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextFlach65",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "β = horizontaler Winkel von der Außenkante des vorgelagerten Gebäudes zu der \"Verbindungslinie von der Mitte des vorgelagerten Gebäude zum Schornstein\"",
                value: "",
                name: "TextFlach62",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "lA = horizontale Entfernung von Mitte-Schornsteinmündung bis zum vorgelagerten Gebäude bzw. Gebäudegruppe",
                value: "",
                name: "TextFlach68",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "Δh = Höhendifferenz zwischen vorgelagertem Gebäude und Gebäude mit Schornstein (wenn nicht zutreffend = 0, wenn vorgel. Gebäude niedriger, Minuszeichen vor Wert setzen)",
                value: "",
                name: "TextFlach136",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }]
        },    {
            name: "Sheddach",
            slug: "sheddach",
            image: "Scheddach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextShed74",
                label: "leff = Effektive Länge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                subfix: "m",
            }, {
                value: "",
                name: "TextShed78",
                label: "Horizontale Ausdehnung lRZ der Rezirkulationsszone",
                subfix: "m",
            }, {
                value: "",
                name: "TextShed79",
                label: "HA2 = Erforderliche Schornstein-Mündungshöhe über dem First, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }, {
                value: "",
                name: "TextShed128",
                label: "Zusätzliche Schornsteinhöhe durch das vorgelagerte Gebäude ",
                subfix: "m",
            },{
                value: "",
                name: "TextShed130",
                label: "Erforderliche Schornstein-Mündungshöhe über der Dachfläche, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }, {
                value: "",
                name: "TextShed129",
                label: "HGes. = Erforderliche Schornstein-Mündungshöhe über der Bezugsebene, aufgrund eines vorgelagerten Gebäudes bzw. einer vorgelagerten geschlossenen Bauweise",
                subfix: "m",
            }],
            items: [{
                label: "bv = Breite der Giebelseite des vorgelagerten Einzel- bzw. Gruppengebäudes, gemessen von First zu Traufe",
                value: "",
                name: "TextShed76",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "lv = Seitenlänge des vorgelagerten Gebäudes (entfällt bei geschlossener Bauweise)",
                value: "",
                name: "TextShed72",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HFirst.V = Firsthöhe des vorgelagerten Gebäudes (höchstes Gebäude bei geschlossener Bauweise)",
                value: "",
                name: "TextShed75",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "β = horizontaler Winkel von der Außenkante des vorgelagerten Gebäudes zu der \"Verbindungslinie von der Mitte des vorgelagerten Gebäude zum Schornstein\"",
                value: "",
                name: "TextShed73",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "lA = horizontale Entfernung von Mitte-Schornsteinmündung bis zum vorgelagerten Gebäude bzw. Gebäudegruppe",
                value: "",
                name: "TextShed77",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "Δh = Höhendifferenz zwischen vorgelagertem Gebäude und Gebäude mit Schornstein (wenn nicht zutreffend = 0, wenn vorgel. Gebäude niedriger, Minuszeichen vor Wert setzen)",
                value: "",
                name: "TextShed137",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }]
        }],
        daecher: [{
            name: "Satteldach",
            slug: "satteldach",
            image: "Satteldach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextSattel10",
            }, {
                value: "",
                name: "TextSattel12",
            }, {
                value: "",
                name: "TextSattel17",
            },{
                value: "",
                name: "TextSattel11",
            }, {
                value: "",
                name: "TextSattel13",
            }, {
                value: "",
                name: "TextSattel18",
            }, {
                value: "",
                name: "TextSattel19",
            }, {
                value: "",
                name: "TextSattel20",
            }],
            items: [{
                label: "b = Breite der Giebelseite, gemessen von Traufe zu Traufe",
                value: "",
                name: "TextSattel7",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            },{
                label: "α = Dachneigungswinkel",
                value: "",
                name: "TextSattel1",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "a = Der horiz. Abstand von First bis zur Mitte der Schornstein-Mündung",
                value: "",
                name: "TextSattel4",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "Maximale Nennwärmeleistung der Feuerungsanlage",
                value: "",
                name: "TextSattel21",
                disabled: false,
                subfix: "kW",
                type: "input",
                error: false
            }, {
                label: "HTraufe = Höhe der Traufe",
                value: "",
                name: "TextSattel14",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HDach = Höhenunterschied von First zur Traufe (wird errechnet)",
                value: "",
                name: "TextSattel15",
                disabled: true,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HFirst = Höhe des Firstes über der Bezugsebene (wird errechnet)",
                value: "",
                name: "TextSattel16",
                disabled: true,
                subfix: "m",
                type: "input",
                error: false
            }]
        },{
            name: "asym Satteldach",
            slug: "assymsattelsach",
            image: "assym.Satteldach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextAsym7",
            }, {
                value: "",
                name: "TextAsym9",
            }, {
                value: "",
                name: "TextAsym15",
            },{
                value: "",
                name: "TextAsym8",
            }, {
                value: "",
                name: "TextAsym10",
            }, {
                value: "",
                name: "TextAsym16",
            }, {
                value: "",
                name: "TextAsym17",
            }, {
                value: "",
                name: "TextAsym18",
            }],
            items: [{
                label: "b1 = Breite der Giebelseite auf der Dachseite ohne Schornstein, gemessen von Traufe zu First",
                value: "",
                name: "TextAsym3",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "b2 = Breite der Giebelseite auf der Dachseite mit Schornstein, gemessen von First zu Traufe",
                value: "",
                name: "TextAsym4",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            },{
              label: "α1 = Dachneigungswinkel der Dachseite ohne Schornstein (wird errechnet)",
              value: "",
              name: "TextAsym1",
              disabled: true,
              subfix: "°",
              type: "input",
              error: false
            }, {
                label: "α2 = Dachneigungswinkel der Dachseite mit Schornstein",
                value: "",
                name: "TextAsym2",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            },  {
                label: "a = Der horiz. Abstand von First bis zur Mitte der Schornstein-Mündung",
                value: "",
                name: "TextAsym5",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "Maximale Nennwärmeleistung der Feuerungsanlage",
                value: "",
                name: "TextAsym11",
                disabled: false,
                subfix: "kW",
                type: "input",
                error: false
            }, {
                label: "Notwendige Eingaben. wenn Lüftungsöffnungen, Fenster und Türen vom Nachbargebäude nach 1. BlmSchV zu beachten ist",
                value: " ",
                name: "",
                disabled: false,
                subfix: "",
                type: "text",
                error: false
            }, {
                label: "HTraufe = Höhe der Traufe",
                value: "",
                name: "TextAsym12",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HDach = Höhenunterschied von First zur Traufe (wird errechnet)",
                value: "",
                name: "TextAsym13",
                disabled: true,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HFirst = Höhe des Firstes über der Bezugsebene (wird errechnet)",
                value: "",
                name: "TextAsym14",
                disabled: true,
                subfix: "m",
                type: "input",
                error: false
            }]
        }, {
            name: "Walmdach",
            slug: "walmdach",
            image: "Walmdach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextWalm8",
            }, {
                value: "",
                name: "TextWalm10",
            }, {
                value: "",
                name: "TextWalm16",
            }, {
                value: "",
                name: "TextWalm12",
            },{
                value: "",
                name: "TextWalm13",
            }, {
                value: "",
                name: "TextWalm17",
            }, {
                value: "",
                name: "TextWalm9",
            }, {
                value: "",
                name: "TextWalm11",
            }, {
                value: "",
                name: "TextWalm18",
            }, {
                value: "",
                name: "TextWalm19",
            }, {
                value: "",
                name: "TextWalm20",
            }],
            items: [{
                label: "b = Breite der Giebelseite, gemessen von Traufe zu Traufe",
                value: "",
                name: "TextWalm3",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "L = Länge der ggf. längeren Gebäudeseite",
                value: "",
                name: "TextWalm3",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            },{
                label: "α1 = Dachneigungswinkel der Dachseite b ",
                value: "",
                name: "TextWalm1",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "α2 = Dachneigungswinkel der Dachseite L",
                value: "",
                name: "TextWalm2",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            },{
                label: "L2 = Länge des Dachwalms (wird errechnet)",
                value: "",
                name: "TextWalm4",
                disabled: true,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "a1 = Der horiz. Abstand von First bis zur Mitte der Schornstein-Mündung; a1 darf nicht größer als: <<Variable>> m sein, wenn der Schornstein aus der Dachseite b austritt und Abstand a2 > 0 ist.",
                label2: "a1 = Der horiz. Abstand von First bis zur Mitte der Schornstein-Mündung; a1 darf nicht größer als: <<Variable>> m sein, wenn der Schornstein aus der Dachseite b austritt und Abstand a2 > 0 ist.",
                value: "",
                name: "TextWalm6",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "a2 = Der horiz. Abstand von First bis zur Mitte der Schornstein-Mündung, wenn Schornstein auf Walmseite austritt. Ansonsten = 0",
                value: "",
                name: "TextWalm7",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "Maximale Nennwärmeleistung der Feuerungsanlage",
                value: "",
                name: "TextWalm14",
                disabled: false,
                subfix: "kW",
                type: "input",
                error: false
            }, {
                label: "HTraufe = Höhe der Traufe",
                value: "",
                name: "TextWalm21",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HDach = Höhenunterschied von First zur Traufe (wird errechnet)",
                value: "",
                name: "TextWalm22",
                disabled: true,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HFirst = Höhe des Firstes über der Bezugsebene (wird errechnet)",
                value: "",
                name: "TextWalm15",
                disabled: true,
                subfix: "m",
                type: "input",
                error: false
            }]
        }, {
            name: "Pultdach",
            slug: "pultdach",
            image: "Pultdach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextPult4",
            }, {
                value: "",
                name: "TextPult6",
            }, {
                value: "",
                name: "TextPult12",
            },{
                value: "",
                name: "TextPult5",
            }, {
                value: "",
                name: "TextPult7",
            }, {
                value: "",
                name: "TextPult13",
            }, {
                value: "",
                name: "TextPult14",
            }, {
                value: "",
                name: "TextPult15",
            }],
            items: [{
                label: "b = Breite der Giebelseite, gemessen von Traufe zu Traufe",
                value: "",
                name: "TextPult2",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            },{
                label: "α = Dachneigungswinkel",
                value: "",
                name: "TextPult1",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "a = Der horiz. Abstand von First bis zur Mitte der Schornstein-Mündung",
                value: "",
                name: "TextPult3",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "Maximale Nennwärmeleistung der Feuerungsanlage",
                value: "",
                name: "TextPult8",
                disabled: false,
                subfix: "kW",
                type: "input",
                error: false
            }, {
                label: "HTraufe = Höhe der Traufe",
                value: "",
                name: "TextPult9",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HDach = Höhenunterschied von First zur Traufe (wird errechnet)",
                value: "",
                name: "TextPult10",
                disabled: true,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HFirst = Höhe des Firstes über der Bezugsebene (wird errechnet)",
                value: "",
                name: "TextPult11",
                disabled: true,
                subfix: "m",
                type: "input",
                error: false
            }]
        }, {
            name: "Mansarddach",
            slug: "masarddach",
            image: "Mansarddach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextMansard6",
            }, {
                value: "",
                name: "TextMansard8",
            }, {
                value: "",
                name: "TextMansard14",
            },{
                value: "",
                name: "TextMansard7",
            }, {
                value: "",
                name: "TextMansard9",
            }, {
                value: "",
                name: "TextMansard15",
            }, {
                value: "",
                name: "TextMansard16",
            }, {
                value: "",
                name: "TextMansard17",
            }],

            items: [{
                label: "b = untere Breite der Giebelseite, gemessen von der unteren Traufe zur unteren Traufe",
                value: "",
                name: "TextMansard4",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            },{
                label: "bO = obere Breite der Giebelseite in Höhe des Knicks der Dachflächen",
                value: "",
                name: "TextMansard3",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "αU = unterer Dachneigungswinkel der Dachseite b ",
                value: "",
                name: "TextMansard2",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "αO = oberer Dachneigungswinkel der Dachseite b ",
                value: "",
                name: "TextMansard1",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "a = Der horiz. Abstand von First bis zur Mitte der Schornstein-Mündung",
                value: "",
                name: "TextMansard5",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "Maximale Nennwärmeleistung der Feuerungsanlage",
                value: "",
                name: "TextMansard10",
                disabled: false,
                subfix: "kW",
                type: "input",
                error: false
            }, {
                label: "Notwendige Eingaben. wenn Lüftungsöffnungen, Fenster und Türen vom Nachbargebäude nach 1. BlmSchV zu beachten ist",
                value: " ",
                name: "",
                disabled: false,
                subfix: "",
                type: "text"
            }, {
                label: "HTraufe = Höhe der Traufe",
                value: "",
                name: "TextMansard11",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HDach = Höhenunterschied von First zur Traufe (wird errechnet)",
                value: "",
                name: "TextMansard12",
                disabled: true,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "HFirst = Höhe des Firstes über der Bezugsebene (wird errechnet)",
                value: "",
                name: "TextMansard13",
                disabled: true,
                subfix: "m",
                type: "input",
                error: false
            }]
        }, {
            name: "Flachdach",
            slug: "flachdach",
            image: "Flachdach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextFlach4",
            }, {
                value: "",
                name: "TextFlach9",
            }, {
                value: "",
                name: "TextFlach5",
            },{
                value: "",
                name: "TextFlach10",
            }, {
                value: "",
                name: "TextFlach6",
            }, {
                value: "",
                name: "TextFlach11",
            }, {
                value: "",
                name: "TextFlach12",
            }, {
                value: "",
                name: "TextFlach13",
            }],
            items: [{
                label: "b = Breite der Gebäudeschmalseite",
                value: "",
                name: "TextFlach2",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "a = Der horiz. Abstand vom First eines eines fiktiven 20°-Daches (auf der Dachseite b) bis zur Mitte der Schornstein-Mündung",
                value: "",
                name: "TextFlach3",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "Maximale Nennwärmeleistung der Feuerungsanlage",
                value: "",
                name: "TextFlach8",
                disabled: false,
                subfix: "kW",
                type: "input",
                error: false
            }, {
                label: "HTraufe = Höhe der Traufe inkl. Attika (entspricht HFirst des Gebäudes)",
                value: "",
                name: "TextFlach1",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }]
        },    {
            name: "Sheddach",
            slug: "sheddach",
            image: "Scheddach_Button.jpg",
            selected: false,
            result: [{
                value: "",
                name: "TextShed6",
            }, {
                value: "",
                name: "TextShed9",
            }, {
                value: "",
                name: "TextShed13",
            }, {
                value: "",
                name: "TextShed7",
            },{
                value: "",
                name: "TextShed10",
            }, {
                value: "",
                name: "TextShed14",
            }, {
                value: "",
                name: "TextShed8",
            }, {
                value: "",
                name: "TextShed11",
            }, {
                value: "",
                name: "TextShed15",
            }, {
                value: "",
                name: "TextShed16",
            }, {
                value: "",
                name: "TextShed17",
            }],
            items: [{
                label: "b = Breite der Gebäudeschmalseite (unabhängig von der Ausrichtung des Sheddaches)",
                value: "",
                name: "TextShed2",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "a1 = Der horiz. Abstand vom First eines eines fiktiven 20°-Daches (auf der Dachseite b) bis zur Mitte der Schornstein-Mündung",
                value: "",
                name: "TextShed3",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "a2 = Der horiz. Abstand von der Sheddachspitze bis zur Mitte der Schornstein-Mündung",
                value: "",
                name: "TextShed4",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }, {
                label: "α = Dachneigungswinkel der Sheddachseite mit Schornsteinaustritt",
                value: "",
                name: "TextShed5",
                disabled: false,
                subfix: "°",
                type: "input",
                error: false
            }, {
                label: "Maximale Nennwärmeleistung der Feuerungsanlage",
                value: "",
                name: "TextShed12",
                disabled: false,
                subfix: "kW",
                type: "input",
                error: false
            }, {
                label: "HFirst = Höhe des Firstes über der Bezugsebene (inkl. flächiger Aufbauten, wie z.B. Solarpanele)",
                value: "",
                name: "TextShed1",
                disabled: false,
                subfix: "m",
                type: "input",
                error: false
            }]
        }]
    }

    constructor(props) {
        super(props);

        this.chooseFile = this.chooseFile.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.selectDaecher = this.selectDaecher.bind(this)
        this.selectVorgDaecher = this.selectVorgDaecher.bind(this)
        this.showDaecher = this.showDaecher.bind(this);
        this.selectView = this.selectView.bind(this);
        this.updateChange = this.updateChange.bind(this);

        this.createProject = this.createProject.bind(this);
    }

    selectDaecher(item) {
        let foundOld = this.state.daecher.filter(x => x.selected == true);
        if(foundOld.length > 0) {
            foundOld[0].selected = false;
        }

        let found = this.state.daecher.filter(x => x.slug == item.slug)[0];
        found.selected = !found.selected;

        if(item.slug == "flachdach") {
            this.load(item, null);
        }
        if(item.slug == "assymsattelsach") {
            this.calcassymsattelsach();
        }

        if(item.slug == "masarddach") {
            this.calcmasarddach();
        }

        if(item.slug == "pultdach") {
            this.calcapultdach();
        }

        if(item.slug == "satteldach") {
            this.calcsatteldach();
        }

        if(item.slug == "sheddach") {
            this.calcsheddach();
        }

        if(item.slug == "walmdach") {
            this.calcwalmdach();
        }

        this.setState({type_dach_selected: false, daecher: this.state.daecher})
    }

    selectVorgDaecher(item) {
        let foundOld = this.state.vorg_daecher.filter(x => x.selected == true);
        if(foundOld.length > 0) {
            foundOld[0].selected = false;
        }

        let found = this.state.vorg_daecher.filter(x => x.slug == item.slug)[0];
        found.selected = !found.selected;



        updateVorgSatteldach("D7", this.state.type_dach_selected_art ? "1" : "0")
        updateVorgAsyncSatteldach("G7", this.state.type_dach_selected_art ? "1" : "0")
        updateVorgFlachdach("S7", this.state.type_dach_selected_art ? "1" : "0")
        updateVorgMassarddach("P7", this.state.type_dach_selected_art ? "1" : "0")
        updateVorgPultdach("M7", this.state.type_dach_selected_art ? "1" : "0")
        updateVorgSheddach("V7", this.state.type_dach_selected_art ? "1" : "0")
        updateVorgWalmdach("J7", this.state.type_dach_selected_art ? "1" : "0")

        found.items.map(x => x.value = "");
        found.result.map(x => x.value = "");

        ////console.log(JSON.stringify(found));

        if(item.slug == "satteldach") {
            this.calcvorgsatteldach();
        }
        if(item.slug == "assymsattelsach") {
            this.calcvorgassymsattelsach();
        }
        if(item.slug == "walmdach") {
            this.calcvorgwalmdach();
        }
        if(item.slug == "pultdach") {
            this.calcvorgpultdach();
        }

        if(item.slug == "flachdach") {
            this.calcvorgflachdach();
        }

        if(item.slug == "masarddach") {
            this.calcvorgmassarddach();
        }

        if(item.slug == "sheddach") {
            this.calcvorgsheddach();
        }

        this.setState({vorg_daecher: this.state.vorg_daecher})
    }

    load(item, update) {

        let TextFlach2 = item.items.filter(x => x.name == "TextFlach2")[0];
        let TextFlach3 = item.items.filter(x => x.name == "TextFlach3")[0];
        let TextFlach8 = item.items.filter(x => x.name == "TextFlach8")[0];
        let TextFlach1 = item.items.filter(x => x.name == "TextFlach1")[0];

        updateFlachdach("T19", TextFlach1.value);
        updateFlachdach("T15", TextFlach2.value);
        updateFlachdach("T13", TextFlach3.value);
        updateFlachdach("T18", TextFlach8.value);

       /* setTimeout(function() {
            this.load(this.state.daecher.filter(x => x.selected == true)[0]);
        }.bind(this), 200)*/
        let TextFlach4 = item.result.filter(x => x.name == "TextFlach4")[0];
        let TextFlach9 = item.result.filter(x => x.name == "TextFlach9")[0];
        let TextFlach5 = item.result.filter(x => x.name == "TextFlach5")[0];
        let TextFlach6 = item.result.filter(x => x.name == "TextFlach6")[0];
        let TextFlach10 = item.result.filter(x => x.name == "TextFlach10")[0];
        let TextFlach11 = item.result.filter(x => x.name == "TextFlach11")[0];
        let TextFlach12 = item.result.filter(x => x.name == "TextFlach12")[0];
        let TextFlach13 = item.result.filter(x => x.name == "TextFlach13")[0];

        calcT23();
        calcT26();
        calcT37();
        calcT22();
        calcT45();
        calcT38();
        calcT42();
        calcT40();

        TextFlach4.value = loadFlachdach("T37");
        if(TextFlach4.value != null && TextFlach4.value != "") {
            TextFlach4.value = parseFloat(TextFlach4.value).toFixed(2)
        }

        let T45 = calcT45();

        TextFlach5.value = T45;
        if(TextFlach5.value != null && TextFlach5.value != "" && TextFlach5.value != "nicht relevant") {
            TextFlach5.value = parseFloat(TextFlach5.value).toFixed(2)
        }

        calcU35();
        TextFlach6.value = calcU37();
        if(TextFlach6.value != null && TextFlach6.value != "" && TextFlach6.value != "nicht möglich") {
            TextFlach6.value = parseFloat(TextFlach6.value).toFixed(2)
        }

        TextFlach9.value = calcT49();
        if(TextFlach9.value != null && TextFlach9.value != "" && TextFlach9.value != "nicht relevant") {
            TextFlach9.value = parseFloat(TextFlach9.value).toFixed(2)
        }

        TextFlach10.value = calcT46();
        if(TextFlach10.value != null && TextFlach10.value != "" && TextFlach10.value != "nicht relevant") {
            TextFlach10.value = parseFloat(TextFlach10.value).toFixed(2)
        }

        TextFlach11.value = calcU49();

        if(TextFlach11.value != null && TextFlach11.value != "" && TextFlach11.value != "nicht möglich") {

            TextFlach11.value =parseFloat(TextFlach11.value).toFixed(2)
        }

        TextFlach12.value = calcT47();
        if(TextFlach12.value != null && TextFlach12.value != "") {
            TextFlach12.value = parseFloat(TextFlach12.value).toFixed(2)
        }

        TextFlach13.value = calcT48();
        if(TextFlach13.value != null && TextFlach13.value != "" ) {
            TextFlach13.value = parseFloat(TextFlach13.value).toFixed(2)
        }


        let T13 = loadFlachdach("T13");
        let T15 = loadFlachdach("T15");

        if (T13 > T15 / 2) {
            TextFlach13.error = false;
        } else {
            TextFlach13.error = true;
        }
        let T19 = loadFlachdach("T19");
        let T21 = loadFlachdach("T21");
        let T23 = loadFlachdach("T23");
        let T18 = loadFlachdach("T18");
        let T24 = loadFlachdach("T24");

        console.log("T13T13!", T13+" "+T15+" "+T18+" "+T19);
        /*
        updateVorgFlachdach("D20", T21)
        updateVorgFlachdach("D21", T18)
        updateVorgFlachdach("D23", T23)
        updateVorgFlachdach("D24", T24)
*/

        if(T13 != null && !isNaN(T13) && T15 != null && !isNaN(T15)){
            if(T13 > T15 / 2) {
                TextFlach3.error = true;
            }else{
                TextFlach3.error = false;
            }
        }

        if(update != null) {
            this.setState({isLoading: true}, function () {
                this.setState({isLoading: false})
            })
        }


    }

    componentWillUnmount() {
        if(this.state.intervalId != null) {
            clearInterval(this.state.intervalId)
        }
    }


    componentDidMount() {

        if(this.props.location.search == "") {
            this.createProject();
            const intervalId = setInterval(function() {
                this.createProject();
            }.bind(this), 10000);

            this.setState({intervalId: intervalId});
        }else {
            this.loadData();
        }
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }

    deleteProject() {
         this.setState({isLoading: true})
        axios.get(BASE_URL+'/project/delete/'+this.state.weight).then(e => window.location.href = "/").catch((e) => console.log(JSON.stringify(e)));
    }

    createProject() {
        const formData = new FormData();
        formData.append('user_id', this.getCookie("user"));
        formData.append('partner', this.getCookie("partner"));
        formData.append('project_id', this.state.weight);
        formData.append('from', this.state.date_from);
        formData.append('name', this.state.name);
        formData.append('type_dach', this.state.type_dach);
        formData.append('vorg_daecher', this.state.type_dach_selected ? JSON.stringify(this.state.vorg_daecher) : null);
        formData.append('daecher', JSON.stringify(this.state.daecher));

        axios.post(BASE_URL+'/project/create', formData);
    }

    finale() {
        const formData = new FormData();
        formData.append('user_id', this.getCookie("user"));
        formData.append('partner', this.getCookie("partner"));
        formData.append('project_id', this.state.weight);
        formData.append('project_title', this.state.project_title);
        formData.append('project_firstname', this.state.project_firstname);
        formData.append('project_lastname', this.state.project_lastname);
        formData.append('project_zip', this.state.project_zip);
        formData.append('project_city', (this.state.project_city));
        formData.append('project_street', (this.state.project_street));
        formData.append('project_mobile', (this.state.project_mobile));
        formData.append('project_phone', (this.state.project_phone));
        formData.append('project_email', (this.state.project_email));
        formData.append('project_country', (this.state.project_country));

        this.createProject();

        axios.post(BASE_URL+'/project/save', formData).then(x => window.location.href="/").catch(x => alert("Fehler beim Speichern"));
    }

    loadData() {
        this.setState({isLoading: true})
        const id = (this.props.location.search).replace("?id=", "");

        axios.get(BASE_URL + '/project/show/'+id).then((response) => {
           // this.setState({draft: response.data.draft});

            let type_dach_selected = 0;

            if(response.data.project.vorg_daecher != "null") {
                type_dach_selected = JSON.parse(response.data.project.vorg_daecher).filter(x => x.selected).length > 0 ? 1 : 0;
            }

            let userdata = JSON.parse(response.data.project.user_data, true);

            ////console.log("vorg_daechervorg_daecher1", response.data.project.vorg_daecher)

            //project_title: userdata == null ? "" : userdata['project_title'],project_firstname: userdata == null ? "" : userdata['project_firstname'],project_lastname: userdata == null ? "" : userdata['project_lastname'],project_street: userdata == null ? "" : userdata['project_street'],project_zip: userdata == null ? "" : userdata['project_zip'],project_city: userdata == null ? "" : userdata['project_city'],project_phone:userdata == null ? "" :  userdata['project_phone'],project_mobile: userdata == null ? "" : userdata['project_mobile'],project_email: userdata == null ? "" : userdata['project_email'],  project_country:userdata == null ? "" :  userdata['project_country'],

            if(userdata != null) {

                userdata['project_title'] = userdata['project_title'] == null || userdata['project_title'] == "null" ? "" : userdata['project_title'];
                userdata['project_firstname'] = userdata['project_firstname'] == null || userdata['project_firstname'] == "null" ? "" : userdata['project_firstname'];
                userdata['project_lastname'] = userdata['project_lastname'] == null || userdata['project_lastname'] == "null" ? "" : userdata['project_lastname'];
                userdata['project_street'] = userdata['project_street'] == null || userdata['project_street'] == "null" ? "" : userdata['project_street'];
                userdata['project_zip'] = userdata['project_zip'] == null || userdata['project_zip'] == "null" ? "" : userdata['project_zip'];
                userdata['project_city'] = userdata['project_city'] == null || userdata['project_city'] == "null" ? "" : userdata['project_city'];
                userdata['project_phone'] = userdata['project_phone'] == null || userdata['project_phone'] == "null" ? "" : userdata['project_phone'];
                userdata['project_mobile'] = userdata['project_mobile'] == null || userdata['project_mobile'] == "null" ? "" : userdata['project_mobile'];
                userdata['project_email'] = userdata['project_email'] == null || userdata['project_email'] == "null" ? "" : userdata['project_email'];
                userdata['project_country'] = userdata['project_country'] == null || userdata['project_country'] == "null" ? "" : userdata['project_country'];
            }

            this.setState({ project_title: userdata == null ? "" : userdata['project_title'],project_firstname: userdata == null ? "" : userdata['project_firstname'],project_lastname: userdata == null ? "" : userdata['project_lastname'],project_street: userdata == null ? "" : userdata['project_street'],project_zip: userdata == null ? "" : userdata['project_zip'],project_city: userdata == null ? "" : userdata['project_city'],project_phone:userdata == null ? "" :  userdata['project_phone'],project_mobile: userdata == null ? "" : userdata['project_mobile'],project_email: userdata == null ? "" : userdata['project_email'] ,  project_country:userdata == null ? "" :  userdata['project_country'], type_dach_selected: type_dach_selected, vorg_daecher: response.data.project.vorg_daecher == "null" ? this.state.vorg_daecher : JSON.parse(response.data.project.vorg_daecher),daecher: JSON.parse(response.data.project.daecher),isLoading: false, type_dach: response.data.project.type_dach, weight: response.data.project.project_id, date_from: response.data.project.date_from, name: response.data.project.name }, function() {

                JSON.parse(response.data.project.daecher).map(ite => {
                    ite.items.map((x,y) => {
                        if(x.type == "input") {
                            let target = x.value;
                            let e = {target: {value: target}}

                            if(e != "") {
                                this.handleChange(e, ite, y);
                            }
                        }
                    });
                })
            })


            if(response.data.project.vorg_daecher != "null") {
                JSON.parse(response.data.project.vorg_daecher).map(ite => {
                    ite.items.map((x, y) => {
                        if (x.type == "input") {
                            let target = x.value;

                            let e = {target: {value: target}}

                            if (e != "") {
                                this.handleVorgChange(e, ite, y);
                            }
                        }
                    });
                })
            }
            //

            setInterval(function() {
                this.createProject();
            }.bind(this), 10000);
        });
    }

    chooseFile() {
        this.state.inputElement.click();
    }

    onFileChange(event) {
        this.state.newImagePreview.push(URL.createObjectURL(event.target.files[0]))

        this.state.newImage.push(event.target.files[0]);
        this.setState({
            newImage: this.state.newImage,
            newImagePreview: this.state.newImagePreview
        });
    }

    calcwalmdach() {

        let dach = this.state.daecher.filter(x => x.slug == "walmdach")[0];

        let TextWalm1 = dach.items.filter(x => x.name == "TextWalm1")[0];
        let TextWalm2 = dach.items.filter(x => x.name == "TextWalm2")[0];
        let TextWalm3 = dach.items.filter(x => x.name == "TextWalm3")[0];
        let TextWalm4 = dach.items.filter(x => x.name == "TextWalm4")[0];
        let TextWalm5 = dach.items.filter(x => x.name == "TextWalm5")[0];
        let TextWalm6 = dach.items.filter(x => x.name == "TextWalm6")[0];
        let TextWalm7 = dach.items.filter(x => x.name == "TextWalm7")[0];
        let TextWalm14 = dach.items.filter(x => x.name == "TextWalm14")[0];
        let TextWalm21 = dach.items.filter(x => x.name == "TextWalm21")[0];
        let TextWalm22 = dach.items.filter(x => x.name == "TextWalm22")[0];
        let TextWalm15 = dach.items.filter(x => x.name == "TextWalm15")[0];

        let TextWalm8 = dach.result.filter(x => x.name == "TextWalm8")[0];
        let TextWalm10 = dach.result.filter(x => x.name == "TextWalm10")[0];
        let TextWalm16 = dach.result.filter(x => x.name == "TextWalm16")[0];
        let TextWalm12 = dach.result.filter(x => x.name == "TextWalm12")[0];
        let TextWalm13 = dach.result.filter(x => x.name == "TextWalm13")[0];
        let TextWalm17 = dach.result.filter(x => x.name == "TextWalm17")[0];
        let TextWalm9 = dach.result.filter(x => x.name == "TextWalm9")[0];
        let TextWalm11 = dach.result.filter(x => x.name == "TextWalm11")[0];
        let TextWalm18 = dach.result.filter(x => x.name == "TextWalm18")[0];
        let TextWalm19 = dach.result.filter(x => x.name == "TextWalm19")[0];
        let TextWalm20 = dach.result.filter(x => x.name == "TextWalm20")[0];

        let J11 = loadWalmdach("J11");
        let J12 = loadWalmdach("J12");
        let J13 = loadWalmdach("J13");
        let J14 = loadWalmdach("J14");
        let J15 = loadWalmdach("J15");
        let J16 = loadWalmdach("J16");
        let J36 = loadWalmdach("J36");
        let J37 = loadWalmdach("J37");
        let K36 = loadWalmdach("K36");
        let K37 = loadWalmdach("K37");
        let J39 = loadWalmdach("J39");
        let J40 = loadWalmdach("J40");
        let J41 = loadWalmdach("J41");
        let J50 = loadWalmdach("J50");
        let J21 = loadWalmdach("J21");
        let J49 = loadWalmdach("J49");
        let K49 = loadWalmdach("K49");
        let J47 = loadWalmdach("J47");
        let J20 = loadWalmdach("J20");
        let J43 = loadWalmdach("J43");
        let J48 = loadWalmdach("J48");

        console.log(J11+" "+ J12+" "+J13+" "+J14+" "+J15+" "+J15+" "+J16);
         //
        let J18 = loadWalmdach("J18");

        //TextWalm21.

        TextWalm6.label = TextWalm6.label2.replace("<<Variable>>", J43 == "nicht relevant" ?"0": (Math.round(J43*100)/100).toString().replace(".", ".") );
       /* updateVorgWalmdach("D20", J21)
        updateVorgWalmdach("D21", J18)
        updateVorgWalmdach("D23", J37)
        updateVorgWalmdach("D24", J36)*/

        TextWalm4.value = Math.round(J16 * 100) / 100;
        TextWalm8.value =  Math.round(J36 * 100) / 100;
        TextWalm10.value =  Math.round(J37 * 100) / 100;

        if (K36 == "nicht möglich") {
            TextWalm9.value = K36;
        } else {
            TextWalm9.value = Math.round(K36 * 100) / 100;
            //.toFixed(2)
        }
        ////console.log("K36", K36);
        if (K37 == "nicht möglich") {
            TextWalm11.value = K37;
        } else {
            TextWalm11.value = Math.round(K37 * 100) / 100;
            //.toFixed(2)
        }
        ////console.log("K37", K37);
        if (J40 == "nicht relevant") {
            TextWalm12.value = J40;
        } else {
            TextWalm12.value = Math.round(J40 * 100) / 100;
            //.toFixed(2);
        }

        ////console.log("J40", J40);

        if (J41 == "nicht relevant") {
            TextWalm13.value = J41;
        } else {
            TextWalm13.value = Math.round(J41 * 100) / 100;
            //.toFixed(2)
        }

        if (J50 == "nicht relevant") {
            TextWalm17.value = J50;
        } else {
            TextWalm17.value = Math.round(J50 * 100) / 100;
            //.toFixed(2)
        }

        TextWalm15.value = Math.round(J21 * 100) / 100;
        TextWalm16.value = Math.round(J49 * 100) / 100;
        ////console.log("J49", J49);
        ////console.log("J50", J50);
        ////console.log("K49", K49);

        if (J50 == "nicht relevant") {
            TextWalm17.value = J50;
        } else {
            TextWalm17.value = Math.round(J50 * 100) / 100;
            //.toFixed(2)
        }

        if (K49 == "nicht möglich") {
            TextWalm18.value = K49;
        } else {
            TextWalm18.value = Math.round(K49 * 100) / 100;
            //.toFixed(2)
        }


        TextWalm19.value = Math.round(J47 * 100) / 100;
        TextWalm20.value = Math.round(J48 * 100) / 100;
        TextWalm22.value = Math.round(J20 * 100) / 100;
//TextWalm3",TextWalm7

        if(J14 != null && !isNaN(J14) && J15 != null && !isNaN(J15)){
            if(J14 > J15 / 2) {
                TextWalm7.error = true;
            }else{
                TextWalm7.error = false;
            }
        }
/*
        if(TextWalm2.value != "") {
            if (TextWalm2.value < 1 || TextWalm2.value > 89) {
                TextWalm2.error = true;
            } else {
                TextWalm2.error = false;
            }
        }
        if(TextWalm4.value != "") {
            if (TextWalm4.value < 1 || TextWalm4.value > 180) {
                TextWalm4.error = true;
            } else {
                TextWalm4.error = false;
            }
        }*/

        /*if (J13 > J15 / 2) {
            TextWalm3.error = true;
            TextWalm6.error = true;
        } else {
            TextWalm3.error = false;
            TextWalm6.error = false;
        }*/
/*
        if (J14 > J16 / 2) {
            TextWalm7.error = true;
        } else {
            TextWalm7.error = false;
        }*/
        /*
        if (J43 == "nicht relevant") {
            TextWalm23.value = J43;
        } else {
            TextWalm23.value = J43.toFixed(2);
        }*/
    }

    calcvorgflachdach() {
        let dach = this.state.vorg_daecher.filter(x => x.slug == "flachdach")[0];

        this.calcVorg("flachdach");

        let TextFlach66 = dach.items.filter(x => x.name == "TextFlach66")[0];
        let S9 = loadVorgFlachdach("S9");
        TextFlach66.value = S9 == null ? "" : S9;

        let TextFlach61 = dach.items.filter(x => x.name == "TextFlach61")[0];
        let S10 = loadVorgFlachdach("S10");
        TextFlach61.value = S10 == null ? "" : S10;

        let TextFlach65 = dach.items.filter(x => x.name == "TextFlach65")[0];
        let S11 = loadVorgFlachdach("S11");
        TextFlach65.value = S11 == null ? "" : S11;

        let TextFlach62 = dach.items.filter(x => x.name == "TextFlach62")[0];
        let S14 = loadVorgFlachdach("S14");
        TextFlach62.value = S14 == null ? "" : S14;
        if(TextFlach62.value !== "") {
            if(TextFlach62.value <= 0 || TextFlach62.value >= 181) {
                TextFlach62.error = true;
            }else{
                TextFlach62.error = false;
            }
        }

        let TextFlach68 = dach.items.filter(x => x.name == "TextFlach68")[0];
        let S16 = loadVorgFlachdach("S16");
        TextFlach68.value = S16 == null ? "" : S16;

        let TextFlach136 = dach.items.filter(x => x.name == "TextFlach136")[0];
        let S17 = loadVorgFlachdach("S17");
        TextFlach136.value = S17 == null ? "" : S17;

        let TextFlach64 = dach.result.filter(x => x.name == "TextFlach64")[0];
        let S35 = loadVorgFlachdach("S35");
        TextFlach64.value = S35 == null ? "" : S35;

        let TextFlach69 = dach.result.filter(x => x.name == "TextFlach69")[0];
        let S36 = loadVorgFlachdach("S36");
        TextFlach69.value = S36 == null ? "" : S36;

        let TextFlach70 = dach.result.filter(x => x.name == "TextFlach70")[0];
        let S42 = loadVorgFlachdach("S42");
        TextFlach70.value = S42 == null ? "" : S42;

        let TextFlach125 = dach.result.filter(x => x.name == "TextFlach125")[0];
        let S43 = loadVorgFlachdach("S43");
        TextFlach125.value = S43 == null ? "" : S43;

        let TextFlach127 = dach.result.filter(x => x.name == "TextFlach127")[0];
        let S44 = loadVorgFlachdach("S44");
        TextFlach127.value = S44 == null ? "" : S44;

        let TextFlach126 = dach.result.filter(x => x.name == "TextFlach126")[0];
        let S45 = loadVorgFlachdach("S45");
        TextFlach126.value = S45 == null ? "" : S45;
    }

    calcvorgmassarddach() {
        let dach = this.state.vorg_daecher.filter(x => x.slug == "masarddach")[0];

        this.calcVorg("masarddach");

        let TextMansard53 = dach.items.filter(x => x.name == "TextMansard53")[0];
        let P9 = loadVorgMassarddach("P9");
        TextMansard53.value = P9 == null ? "" : P9;

        let TextMansard60 = dach.items.filter(x => x.name == "TextMansard60")[0];
        let P10 = loadVorgMassarddach("P10");
        TextMansard60.value = P10 == null ? "" : P10;

        if(TextMansard53.value !== "" && TextMansard60.value !== "") {
            if(TextMansard53.value >= TextMansard60.value) {
                TextMansard53.error = true;
            }else{
                TextMansard53.error = false;
            }
        }

        let TextMansard48 = dach.items.filter(x => x.name == "TextMansard48")[0];
        let P11 = loadVorgMassarddach("P11");
        TextMansard48.value = P11 == null ? "" : P11;

        let TextMansard52 = dach.items.filter(x => x.name == "TextMansard52")[0];
        let P12 = loadVorgMassarddach("P12");
        TextMansard52.value = P12 == null ? "" : P12;

        let TextMansard59 = dach.items.filter(x => x.name == "TextMansard59")[0];
        let P13 = loadVorgMassarddach("P13");
        TextMansard59.value = P13 == null ? "" : P13;

        let TextMansard54 = dach.items.filter(x => x.name == "TextMansard54")[0];
        let P14 = loadVorgMassarddach("P14");
        TextMansard54.value = P14 == null ? "" : P14;

        if(TextMansard54.value !== "" && TextMansard59.value !== "") {
            if(TextMansard54.value <= TextMansard59.value) {
                TextMansard59.error = true;
            }else{
                TextMansard59.error = false;
            }
        }

        if(TextMansard54.value !== "") {
            if(TextMansard54.value <= 0 || TextMansard54.value >= 90) {
                TextMansard54.error = true;
            }else{
                TextMansard54.error = false;
            }
        }

        let TextMansard49 = dach.items.filter(x => x.name == "TextMansard49")[0];
        let P15 = loadVorgMassarddach("P15");
        TextMansard49.value = P15 == null ? "" : P15;
        if(TextMansard49.value !== "") {
            if(TextMansard49.value <= 0 || TextMansard49.value >= 181) {
                TextMansard49.error = true;
            }else{
                TextMansard49.error = false;
            }
        }

        let TextMansard55 = dach.items.filter(x => x.name == "TextMansard55")[0];
        let P16 = loadVorgMassarddach("P16");
        TextMansard55.value = P16 == null ? "" : P16;

        let TextMansard135 = dach.items.filter(x => x.name == "TextMansard135")[0];
        let P17 = loadVorgMassarddach("P17");

        TextMansard135.value = P17 == null ? "" : P17;

        let TextMansard50 = dach.result.filter(x => x.name == "TextMansard50")[0];
        let P31 = loadVorgMassarddach("P31");
        TextMansard50.value = P31 == null ? "" : P31;

        let TextMansard51 = dach.result.filter(x => x.name == "TextMansard51")[0];
        let P35 = loadVorgMassarddach("P35");
        TextMansard51.value = P35 == null ? "" : P35;

        let TextMansard56 = dach.result.filter(x => x.name == "TextMansard56")[0];
        let P36 = loadVorgMassarddach("P36");
        TextMansard56.value = P36 == null ? "" : P36;

        let TextMansard57 = dach.result.filter(x => x.name == "TextMansard57")[0];
        let P42 = loadVorgMassarddach("P42");
        TextMansard57.value = P42 == null ? "" : P42;

        let TextMansard122 = dach.result.filter(x => x.name == "TextMansard122")[0];
        let P43 = loadVorgMassarddach("P43");
        TextMansard122.value = P43 == null ? "" : P43;

        let TextMansard124 = dach.result.filter(x => x.name == "TextMansard124")[0];
        let P44 = loadVorgMassarddach("P44");
        TextMansard124.value = P44 == null ? "" : P44;

        let TextMansard123 = dach.result.filter(x => x.name == "TextMansard123")[0];
        let P45 = loadVorgMassarddach("P45");
        TextMansard123.value = P45 == null ? "" : P45;


    }

    calcvorgpultdach() {
        let dach = this.state.vorg_daecher.filter(x => x.slug == "pultdach")[0];
        this.calcVorg("pultdach");
        let TextPult42 = dach.items.filter(x => x.name == "TextPult42")[0];
        let M9 = loadVorgPultdach("M9");
        TextPult42.value = M9 == null ? "" : M9;

        let TextPult37 = dach.items.filter(x => x.name == "TextPult37")[0];
        let M10 = loadVorgPultdach("M10");
        TextPult37.value = M10 == null ? "" : M10;

        let TextPult41 = dach.items.filter(x => x.name == "TextPult41")[0];
        let M11 = loadVorgPultdach("M11");
        TextPult41.value = M11 == null ? "" : M11;

        let TextPult43 = dach.items.filter(x => x.name == "TextPult43")[0];
        let M12 = loadVorgPultdach("M12");
        TextPult43.value = M12 == null ? "" : M12;
        if(TextPult43.value !== "") {
            if(TextPult43.value <= 0 || TextPult43.value >= 90) {
                TextPult43.error = true;
            }else{
                TextPult43.error = false;
            }
        }

        let TextPult38 = dach.items.filter(x => x.name == "TextPult38")[0];
        let M14 = loadVorgPultdach("M14");
        TextPult38.value = M14 == null ? "" : M14;
        if(TextPult38.value !== "") {
            if(TextPult38.value <= 0 || TextPult38.value >= 181) {
                TextPult38.error = true;
            }else{
                TextPult38.error = false;
            }
        }

        let TextPult44 = dach.items.filter(x => x.name == "TextPult44")[0];
        let M16 = loadVorgPultdach("M16");
        TextPult44.value = M16 == null ? "" : M16;

        let TextPult134 = dach.items.filter(x => x.name == "TextPult134")[0];
        let M17 = loadVorgPultdach("M17");
        TextPult134.value = M17 == null ? "" : M17;

        let TextPult39 = dach.result.filter(x => x.name == "TextPult39")[0];
        let M31 = loadVorgPultdach("M31");
        TextPult39.value = M31 == null ? "" : M31;

        let TextPult40 = dach.result.filter(x => x.name == "TextPult40")[0];
        let M35 = loadVorgPultdach("M35");
        TextPult40.value = M35 == null ? "" : M35;

        let TextPult45 = dach.result.filter(x => x.name == "TextPult45")[0];
        let M36 = loadVorgPultdach("M36");
        TextPult45.value = M36 == null ? "" : M36;

        let TextPult46 = dach.result.filter(x => x.name == "TextPult46")[0];
        let M42 = loadVorgPultdach("M42");
        TextPult46.value = M42 == null ? "" : M42;

        let TextPult119 = dach.result.filter(x => x.name == "TextPult119")[0];
        let M43 = loadVorgPultdach("M43");
        TextPult119.value = M43 == null ? "" : M43;

        let TextPult121 = dach.result.filter(x => x.name == "TextPult121")[0];
        let M44 = loadVorgPultdach("M44");
        TextPult121.value = M44 == null ? "" : M44;

        let TextPult120 = dach.result.filter(x => x.name == "TextPult120")[0];
        let M45 = loadVorgPultdach("M45");
        TextPult120.value = M45 == null ? "" : M45;
    }

    calcvorgwalmdach() {
        let dach = this.state.vorg_daecher.filter(x => x.slug == "walmdach")[0];
        this.calcVorg("walmdach");
        let TextWalm30 = dach.items.filter(x => x.name == "TextWalm30")[0];
        let J9 = loadVorgWalmdach("J9");
        TextWalm30.value = J9 == null ? "" : J9;

        let TextWalm25 = dach.items.filter(x => x.name == "TextWalm25")[0];
        let J10 = loadVorgWalmdach("J10");
        TextWalm25.value = J10 == null ? "" : J10;

        let TextWalm29 = dach.items.filter(x => x.name == "TextWalm29")[0];
        let J11 = loadVorgWalmdach("J11");
        TextWalm29.value = J11 == null ? "" : J11;

        let TextWalm36 = dach.items.filter(x => x.name == "TextWalm36")[0];
        let J12 = loadVorgWalmdach("J12");

        TextWalm36.value = J12 == null ? "" : J12;
        if(TextWalm36.value !== "") {
            if(TextWalm36.value <= 0 || TextWalm36.value >= 90) {
                TextWalm36.error = true;
            }else{
                TextWalm36.error = false;
            }
        }

        let TextWalm26 = dach.items.filter(x => x.name == "TextWalm26")[0];
        let J14 = loadVorgWalmdach("J14");
        TextWalm26.value = J14 == null ? "" : J14;
        if(TextWalm26.value !== "") {
            if(TextWalm26.value <= 0 || TextWalm26.value >= 90) {
                TextWalm26.error = true;
            }else{
                TextWalm26.error = false;
            }
        }

        let TextWalm32 = dach.items.filter(x => x.name == "TextWalm32")[0];
        let J16 = loadVorgWalmdach("J16");
        TextWalm32.value = J16 == null ? "" : J16;

        let TextWalm133 = dach.items.filter(x => x.name == "TextWalm133")[0];
        let J17 = loadVorgWalmdach("J17");
        TextWalm133.value = J17 == null ? "" : J17;


        let TextWalm27 = dach.result.filter(x => x.name == "TextWalm27")[0];
        let J31 = loadVorgWalmdach("J31");
        TextWalm27.value = J31 == null ? "" : J31;

        let TextWalm28 = dach.result.filter(x => x.name == "TextWalm28")[0];
        let J35 = loadVorgWalmdach("J35");
        TextWalm28.value = J35 == null ? "" : J35;

        let TextWalm33 = dach.result.filter(x => x.name == "TextWalm33")[0];
        let J36 = loadVorgWalmdach("J36");
        TextWalm33.value = J36 == null ? "" : J36;

        let TextWalm34 = dach.result.filter(x => x.name == "TextWalm34")[0];
        let J42 = loadVorgWalmdach("J42");
        TextWalm34.value = J42 == null ? "" : J42;

        let TextWalm116 = dach.result.filter(x => x.name == "TextWalm116")[0];
        let J43 = loadVorgWalmdach("J43");
        TextWalm116.value = J43 == null ? "" : J43;

        let TextWalm118 = dach.result.filter(x => x.name == "TextWalm118")[0];
        let J44 = loadVorgWalmdach("J44");
        TextWalm118.value = J44 == null ? "" : J44;


        let TextWalm117 = dach.result.filter(x => x.name == "TextWalm117")[0];
        let J45 = loadVorgWalmdach("J45");
        TextWalm117.value = J45 == null ? "" : J45;



    }

    calcvorgsheddach() {
        let dach = this.state.vorg_daecher.filter(x => x.slug == "sheddach")[0];
        this.calcVorg("sheddach");
        let TextShed76 = dach.items.filter(x => x.name == "TextShed76")[0];
        let V9 = loadVorgSheddach("V9");
        TextShed76.value = V9 == null ? "" : V9;

        let TextShed72 = dach.items.filter(x => x.name == "TextShed72")[0];
        let V10 = loadVorgSheddach("V10");
        TextShed72.value = V10 == null ? "" : V10;

        let TextShed75 = dach.items.filter(x => x.name == "TextShed75")[0];
        let V11 = loadVorgSheddach("V11");
        TextShed75.value = V11 == null ? "" : V11;

        let TextShed73 = dach.items.filter(x => x.name == "TextShed73")[0];
        let V14 = loadVorgSheddach("V14");
        TextShed73.value = V14 == null ? "" : V14;
        if(TextShed73.value !== "") {
            if(TextShed73.value <= 0 || TextShed73.value >= 181) {
                TextShed73.error = true;
            }else{
                TextShed73.error = false;
            }
        }

        let TextShed77 = dach.items.filter(x => x.name == "TextShed77")[0];
        let V16 = loadVorgSheddach("V16");
        TextShed77.value = V16 == null ? "" : V16;

        let TextShed137 = dach.items.filter(x => x.name == "TextShed137")[0];
        let V17 = loadVorgSheddach("V17");
        TextShed137.value = V17 == null ? "" : V17;

        let TextShed74 = dach.result.filter(x => x.name == "TextShed74")[0];
        let V35 = loadVorgSheddach("V35");
        TextShed74.value = V35 == null ? "" : V35;

        let TextShed78 = dach.result.filter(x => x.name == "TextShed78")[0];
        let V36 = loadVorgSheddach("V36");
        TextShed78.value = V36 == null ? "" : V36;

        let TextShed79 = dach.result.filter(x => x.name == "TextShed79")[0];
        let V42 = loadVorgSheddach("V42");
        TextShed79.value = V42 == null ? "" : V42;


        let TextShed128 = dach.result.filter(x => x.name == "TextShed128")[0];
        let V43 = loadVorgSheddach("V43");
        TextShed128.value = V43 == null ? "" : V43;

        let TextShed130 = dach.result.filter(x => x.name == "TextShed130")[0];
        let V44 = loadVorgSheddach("V44");
        TextShed130.value = V44 == null ? "" : V44;

        let TextShed129 = dach.result.filter(x => x.name == "TextShed129")[0];
        let V45 = loadVorgSheddach("V45");
        TextShed129.value = V45 == null ? "" : V45;

    }

    calcsheddach() {
        let dach = this.state.daecher.filter(x => x.slug == "sheddach")[0];
        let TextShed2 = dach.items.filter(x => x.name == "TextShed2")[0];
        let TextShed3 = dach.items.filter(x => x.name == "TextShed3")[0];
        let TextShed4 = dach.items.filter(x => x.name == "TextShed4")[0];
        let TextShed5 = dach.items.filter(x => x.name == "TextShed5")[0];
        let TextShed12 = dach.items.filter(x => x.name == "TextShed12")[0];
        let TextShed1 = dach.items.filter(x => x.name == "TextShed1")[0];

        let TextShed6 = dach.result.filter(x => x.name == "TextShed6")[0];
        let TextShed9 = dach.result.filter(x => x.name == "TextShed9")[0];
        let TextShed13 = dach.result.filter(x => x.name == "TextShed13")[0];
        let TextShed7 = dach.result.filter(x => x.name == "TextShed7")[0];
        let TextShed10 = dach.result.filter(x => x.name == "TextShed10")[0];
        let TextShed14 = dach.result.filter(x => x.name == "TextShed14")[0];
        let TextShed8 = dach.result.filter(x => x.name == "TextShed8")[0];
        let TextShed11 = dach.result.filter(x => x.name == "TextShed11")[0];
        let TextShed15 = dach.result.filter(x => x.name == "TextShed15")[0];
        let TextShed16 = dach.result.filter(x => x.name == "TextShed16")[0];
        let TextShed17 = dach.result.filter(x => x.name == "TextShed17")[0];

        let W39 = loadSheddach("W39");
        let W44 = loadSheddach("W44");
        let X36 = loadSheddach("X36");
        let W40 = loadSheddach("W40");
        let W45 = loadSheddach("W45");
        let X37 = loadSheddach("X37");
        let W18 = loadSheddach("W18");
        let W49 = loadSheddach("W49");
        let W46 = loadSheddach("W46");
        let X49 = loadSheddach("X49");
        let W47 = loadSheddach("W47");
        let W48 = loadSheddach("W48");

        let W21 = loadSheddach("W21");
        let W36 = loadSheddach("W36");
        let W37 = loadSheddach("W37");


       // updateVorgSheddach("D20", W21)
        // updateVorgSheddach("D21", W18)
        // updateVorgSheddach("D23", W37)
        // updateVorgSheddach("D24", W36)


//////console.log("W39", W39);
        TextShed6.value = W36;

        //////console.log("W44", W44);
        if (W44 == "nicht relevant") {
            TextShed7.value = W44;
        } else {

            TextShed7.value = Math.round(W44 * 100) / 100;

            //.toFixed(2)
        }

        if (X36 == "nicht möglich") {
            TextShed8.value = X36;
        } else {
            TextShed8.value = Math.round(X36 * 100) / 100;
            //.toFixed(2)
        }

        //////console.log(W40)
        TextShed9.value = W37;

        if (W45 == "nicht relevant") {
            TextShed10.value = W45;
        } else {

            TextShed10.value = Math.round(W45 * 100) / 100;
            //.toFixed(2)
        }


        if (X37 == "nicht möglich") {

            TextShed11.value = X37;
        } else {

            TextShed11.value = Math.round(X37 * 100) / 100;
            //.toFixed(2)
        }

        if(W18 != null) {
            TextShed12.value = Math.round(W18 * 100) / 100;
        }
        if(W49 != null) {
            TextShed13.value = Math.round(W49 * 100) / 100;
        }
        if (W46 == "nicht relevant") {
            TextShed14.value = W46;
        } else {

            TextShed14.value = Math.round(W46 * 100) / 100;
            //.toFixed(2)
        }


        if (X49 == "nicht möglich") {
            TextShed15.value = X49;
        } else {
            TextShed15.value = Math.round(X49 * 100) / 100;
            //.toFixed(2)
        }



        TextShed16.value = Math.round(W47 * 100) / 100;
        TextShed17.value = Math.round(W48 * 100) / 100;

        let W11 = loadSheddach("W11");
        let W12 = loadSheddach("W12");
        let W13 = loadSheddach("W13");
        let W14 = loadSheddach("W14");
        let W15 = loadSheddach("W15");

        console.log("asdsa-", W11+" "+W12+" "+W13+" "+W14+" "+W15);

        if(W15 != null && !isNaN(W15) && W13 != null && !isNaN(W13)){
            if(W13 > W15 / 2) {
                TextShed3.error = true;
            }else{
                TextShed3.error = false;
            }
        }

        /**
         *
         * TextShed6.Value = Round(Worksheets("Tabelle1").Range("W39").Value, 2)
         * If Worksheets("Tabelle1").Range("W44").Value = "nicht relevant" Then TextShed7.Value = Worksheets("Tabelle1").Range("W44").Value Else TextShed7.Value = Round(Worksheets("Tabelle1").Range("W44").Value, 2)
         * If Worksheets("Tabelle1").Range("X36").Value = "nicht möglich" Then TextShed8.Value = Worksheets("Tabelle1").Range("X36").Value Else TextShed8.Value = Round(Worksheets("Tabelle1").Range("X36").Value, 2)
         * TextShed9.Value = Round(Worksheets("Tabelle1").Range("W40").Value, 2)
         * If Worksheets("Tabelle1").Range("W45").Value = "nicht relevant" Then TextShed10.Value = Worksheets("Tabelle1").Range("W45").Value Else TextShed10.Value = Round(Worksheets("Tabelle1").Range("W45").Value, 2)
         * If Worksheets("Tabelle1").Range("X37").Value = "nicht möglich" Then TextShed11.Value = Worksheets("Tabelle1").Range("X37").Value Else TextShed11.Value = Round(Worksheets("Tabelle1").Range("X37").Value, 2)
         * TextShed12.Value = Round(Worksheets("Tabelle1").Range("W18").Value, 2)
         * TextShed13.Value = Round(Worksheets("Tabelle1").Range("W49").Value, 2)
         * If Worksheets("Tabelle1").Range("W46").Value = "nicht relevant" Then TextShed14.Value = Worksheets("Tabelle1").Range("W46").Value Else TextShed14.Value = Round(Worksheets("Tabelle1").Range("W46").Value, 2)
         * If Worksheets("Tabelle1").Range("X49").Value = "nicht möglich" Then TextShed15.Value = Worksheets("Tabelle1").Range("X49").Value Else TextShed15.Value = Round(Worksheets("Tabelle1").Range("X49").Value, 2)
         * TextShed16.Value = Round(Worksheets("Tabelle1").Range("W47").Value, 2)
         * TextShed17.Value = Round(Worksheets("Tabelle1").Range("W48").Value, 2)
         *
         */
    }

    calcVorgOld() {
        const selected = this.state.daecher.filter(x => x.selected == true)[0];

        if(selected.slug == "satteldach") {
            updateVorgSatteldach("D20", loadSatteldach("D21"))
            updateVorgSatteldach("D21", loadSatteldach("D18"))
            updateVorgSatteldach("D23", loadSatteldach("D37"))
            updateVorgSatteldach("D24", loadSatteldach("D36"))
        }else if(selected.slug == "assymsattelsach") {
            updateVorgSatteldach("D20", loadAsyncSatteldach("G21"))
            updateVorgSatteldach("D21", loadAsyncSatteldach("G18"))
            updateVorgSatteldach("D23", loadAsyncSatteldach("G37"))
            updateVorgSatteldach("D24", loadAsyncSatteldach("G36"))
        }else if(selected.slug == "walmdach") {
            updateVorgSatteldach("D20", loadWalmdach("J21"))
            updateVorgSatteldach("D21", loadWalmdach("J18"))
            updateVorgSatteldach("D23", loadWalmdach("J37"))
            updateVorgSatteldach("D24", loadWalmdach("J36"))
        }else if(selected.slug == "pultdach") {
            updateVorgSatteldach("D20", loadPultdach("M21"))
            updateVorgSatteldach("D21", loadPultdach("M18"))
            updateVorgSatteldach("D23", loadPultdach("M37"))
            updateVorgSatteldach("D24", loadPultdach("M36"))
        }else if(selected.slug == "masarddach") {
            updateVorgSatteldach("D20", loadMassarddach("P21"))
            updateVorgSatteldach("D21", loadMassarddach("P18"))
            updateVorgSatteldach("D23", loadMassarddach("P37"))
            updateVorgSatteldach("D24", loadMassarddach("P36"))
        }else if(selected.slug == "flachdach") {
            updateVorgSatteldach("D20", loadFlachdach("T21"))
            updateVorgSatteldach("D21", loadFlachdach("T18"))
            updateVorgSatteldach("D23", loadFlachdach("T37"))
            updateVorgSatteldach("D24", loadFlachdach("T36"))
        }else if(selected.slug == "sheddach") {
            updateVorgSatteldach("D20", loadSheddach("W21"))
            updateVorgSatteldach("D21", loadSheddach("W18"))
            updateVorgSatteldach("D23", loadSheddach("W37"))
            updateVorgSatteldach("D24", loadSheddach("W36"))
        }
    }

    calcvorgsatteldach() {
        let dach = this.state.vorg_daecher.filter(x => x.slug == "satteldach")[0];

        this.calcVorg("satteldach");

        let TextSattel7 = dach.items.filter(x => x.name == "TextSattel7")[0];
        let TextSattel4 = dach.items.filter(x => x.name == "TextSattel4")[0];
        let TextSattel21 = dach.items.filter(x => x.name == "TextSattel21")[0];
        let TextSattel1 = dach.items.filter(x => x.name == "TextSattel1")[0];
        let TextSattel14 = dach.items.filter(x => x.name == "TextSattel14")[0];
        let TextSattel9 = dach.items.filter(x => x.name == "TextSattel9")[0];
        let TextSattel131 = dach.items.filter(x => x.name == "TextSattel131")[0];

        let D9 = loadVorgSatteldach("D9");
        TextSattel7.value = D9 == null ? "" : D9;

        let D10 = loadVorgSatteldach("D10");
        TextSattel4.value = D10 == null ? "" : D10;

        let D11 = loadVorgSatteldach("D11");
        TextSattel21.value = D11 == null ? "" : D11;

        let D12 = loadVorgSatteldach("D12");
        TextSattel1.value = D12 == null ? "" : D12;

        if(TextSattel1.value !== "") {
            if(TextSattel1.value <= 0 || TextSattel1.value >= 90) {
                TextSattel1.error = true;
            }else{
                TextSattel1.error = false;
            }
        }

        let D14 = loadVorgSatteldach("D14");
        TextSattel14.value = D14 == null ? "" : D14;

        if(TextSattel14.value !== "") {
            if(TextSattel14.value <= 0 || TextSattel14.value >= 181) {
                TextSattel14.error = true;
            }else{
                TextSattel14.error = false;
            }
        }

        let D16 = loadVorgSatteldach("D16");
        TextSattel9.value = D16 == null ? "" : D16;

        let D17 = loadVorgSatteldach("D17");
        TextSattel131.value = D17 == null ? "" : D17;

        let D31 = loadVorgSatteldach("D31");
        let D35 = loadVorgSatteldach("D35");
        let D36 = loadVorgSatteldach("D36");
        let D42 = loadVorgSatteldach("D42");
        let D43 = loadVorgSatteldach("D43");
        let D44 = loadVorgSatteldach("D44");
        let D45 = loadVorgSatteldach("D45");

        let TextSattel15 = dach.result.filter(x => x.name == "TextSattel15")[0];
        TextSattel15.value = D31;


        let TextSattel16 = dach.result.filter(x => x.name == "TextSattel16")[0];
        if (D35 == "entfällt") {
            TextSattel16.value = D35;
        } else {
            TextSattel16.value = D35;
            //.toFixed(2)
        }

        let TextSattel10 = dach.result.filter(x => x.name == "TextSattel10")[0];
        TextSattel10.value = D36;

        let TextSattel11 = dach.result.filter(x => x.name == "TextSattel11")[0];
        if (D42 == "nicht relevant") {
            TextSattel11.value = D42;
        } else {
            TextSattel11.value = D42;
            //.toFixed(2)
        }

        let TextSattel82 = dach.result.filter(x => x.name == "TextSattel82")[0];
        if (D43 == "nicht relevant") {
            TextSattel82.value = D43;
        } else {
            TextSattel82.value = D43;
            //.toFixed(2)
        }

        let TextSattel94 = dach.result.filter(x => x.name == "TextSattel94")[0];
        if (D44 == "nicht relevant") {
            TextSattel94.value = D44;
        } else {
            TextSattel94.value = D44;
            //.toFixed(2)
        }

        let TextSattel83 = dach.result.filter(x => x.name == "TextSattel83")[0];
        if (D45 == "nicht relevant") {
            TextSattel83.value = D45;
        } else {
            TextSattel83.value = D45;
            //.toFixed(2)
        }
    }

    calcsatteldach() {
        let dach = this.state.daecher.filter(x => x.slug == "satteldach")[0];
        let TextSattel1 = dach.items.filter(x => x.name == "TextSattel1")[0];
        let TextSattel7 = dach.items.filter(x => x.name == "TextSattel7")[0];
        let TextSattel4 = dach.items.filter(x => x.name == "TextSattel4")[0];
        let TextSattel21 = dach.items.filter(x => x.name == "TextSattel21")[0];
        let TextSattel14 = dach.items.filter(x => x.name == "TextSattel14")[0];
        let TextSattel15 = dach.items.filter(x => x.name == "TextSattel15")[0];
        let TextSattel16 = dach.items.filter(x => x.name == "TextSattel16")[0];

        let TextSattel10 = dach.result.filter(x => x.name == "TextSattel10")[0];
        let TextSattel12 = dach.result.filter(x => x.name == "TextSattel12")[0];
        let TextSattel17 = dach.result.filter(x => x.name == "TextSattel17")[0];
        let TextSattel11 = dach.result.filter(x => x.name == "TextSattel11")[0];
        let TextSattel13 = dach.result.filter(x => x.name == "TextSattel13")[0];
        let TextSattel18 = dach.result.filter(x => x.name == "TextSattel18")[0];
        let TextSattel19 = dach.result.filter(x => x.name == "TextSattel19")[0];
        let TextSattel20 = dach.result.filter(x => x.name == "TextSattel20")[0];

        let D13 = loadSatteldach("D13");
        let D15 = loadSatteldach("D15");

        let D36 = loadSatteldach("D36");
        let D37 = loadSatteldach("D37");
        let E36 = loadSatteldach("E36");
        let E37 = loadSatteldach("E37");
        let D18 = loadSatteldach("D18");
        let D20 = loadSatteldach("D20");
        let D21 = loadSatteldach("D21");
        let D23 = loadSatteldach("D23");
        let D24 = loadSatteldach("D24");
        let D49 = loadSatteldach("D49");
        let D47 = loadSatteldach("D47");
        let D48 = loadSatteldach("D48");
        let E49 = loadSatteldach("E49");

        TextSattel10.value = Math.round(D36 * 100) / 100;
        TextSattel12.value = Math.round(D37 * 100) / 100;

        if (E36 == "nicht möglich") {
            TextSattel11.value = E36;
        } else {
            TextSattel11.value = Math.round(E36 * 100) / 100;
            //.toFixed(2)
        }

        if (E37 == "nicht möglich") {
            TextSattel13.value = E37;
        } else {
            TextSattel13.value = Math.round(E37 * 100) / 100;
            //.toFixed(2)
        }



        TextSattel16.value = Math.round(D21 * 100) / 100;

        TextSattel17.value = Math.round(D49 * 100) / 100;

        if (E49 == "nicht möglich") {
            TextSattel18.value = E49;
        } else {
            TextSattel18.value = Math.round(E49 * 100) / 100;
            //.toFixed(2)
        }

        TextSattel19.value = Math.round(D47 * 100) / 100;
        TextSattel20.value = Math.round(D48 * 100) / 100;
        TextSattel15.value = Math.round(D20 * 100) / 100;

        if (D13 > D15 / 2) {
            TextSattel7.error = true;
            TextSattel4.error = true;
        } else {
            TextSattel7.error = false;
            TextSattel4.error = false;
        }

        /*
        if(TextSattel21.value != "") {
            if (TextSattel21.value < 1 || TextSattel21.value > 89) {
                TextSattel21.error = true;
            } else {
                TextSattel21.error = false;
            }
        }
        if(TextSattel14.value != "") {
            if (TextSattel14.value < 1 || TextSattel14.value > 180) {
                TextSattel14.error = true;
            } else {
                TextSattel14.error = false;
            }
        }*/
    }

    calcapultdach() {
        let dach = this.state.daecher.filter(x => x.slug == "pultdach")[0];
        let TextPult1 = dach.items.filter(x => x.name == "TextPult1")[0];
        let TextPult2 = dach.items.filter(x => x.name == "TextPult2")[0];
        let TextPult3 = dach.items.filter(x => x.name == "TextPult3")[0];
        let TextPult8 = dach.items.filter(x => x.name == "TextPult8")[0];
        let TextPult9 = dach.items.filter(x => x.name == "TextPult9")[0];
        let TextPult10 = dach.items.filter(x => x.name == "TextPult10")[0];
        let TextPult11 = dach.items.filter(x => x.name == "TextPult11")[0];

        let TextPult4 = dach.result.filter(x => x.name == "TextPult4")[0];
        let TextPult6 = dach.result.filter(x => x.name == "TextPult6")[0];
        let TextPult12 = dach.result.filter(x => x.name == "TextPult12")[0];

        let TextPult5 = dach.result.filter(x => x.name == "TextPult5")[0];
        let TextPult7 = dach.result.filter(x => x.name == "TextPult7")[0];
        let TextPult13 = dach.result.filter(x => x.name == "TextPult13")[0];

        let TextPult14 = dach.result.filter(x => x.name == "TextPult14")[0];
        let TextPult15 = dach.result.filter(x => x.name == "TextPult15")[0];

        let M11 = loadPultdach("M11");
        let M13 = loadPultdach("M13");
        let M15 = loadPultdach("M15");

        let M36 = loadPultdach("M36");
        let M37 = loadPultdach("M37");

        console.log("M11M11!", M11+" "+M13+" "+M15+" "+M36+" "+M37);
        let N36 = loadPultdach("N36");
        let N37 = loadPultdach("N37");
        let M22 =  loadPultdach("M22");

        let M49 =  loadPultdach("M49");
        let N49 =  loadPultdach("N49");
        let M47 =  loadPultdach("M47");
        let M48 =  loadPultdach("M48");
        let M20 =  loadPultdach("M20");
        let M21 =  loadPultdach("M21");
        let M18 =  loadPultdach("M18");


        TextPult4.value = Math.round(M36 * 100) / 100;
        TextPult6.value = Math.round(M37 * 100) / 100;

        if (N36 == "nicht möglich") {
            TextPult5.value = N36;
        } else {
            TextPult5.value = Math.round(N36 * 100) / 100;
            //.toFixed(2)
        }

        if (N37 == "nicht möglich") {
            TextPult7.value = N37;
        } else {
            TextPult7.value = Math.round(N37 * 100) / 100;
            //.toFixed(2)
        }

        TextPult11.value = this.checkParser(M21);

        if (M49 == "nicht möglich") {
            TextPult12.value = M49;
        } else {
            TextPult12.value = Math.round(M49 * 100) / 100;
            //.toFixed(2)
        }

        if (N49 == "nicht möglich") {
            TextPult13.value = N49;
        } else {
            TextPult13.value = Math.round(N49 * 100) / 100;
            //.toFixed(2)
        }

        TextPult14.value = Math.round(M47 * 100) / 100;
        TextPult15.value = Math.round(M48 * 100) / 100;
        TextPult10.value = Math.round(M20 * 100) / 100;

        // TextPult3
        // TextPult2


        if(M13 != null && !isNaN(M13) && M15 != null && !isNaN(M15)){
            console.log("M15M15M15!",M13+"" +M15)
            if(M13 > M15) {
                TextPult3.error = true;
            }else{
                TextPult3.error = false;
            }
        }


/*
        updateVorgPultdach("D20", M21)
        updateVorgPultdach("D21", M18)
        updateVorgPultdach("D23", M37)
        updateVorgPultdach("D24", M36)
*/
        /**
         * TextPult4.Value = Round(Worksheets("Tabelle1").Range("M36").Value, 2)
         * TextPult6.Value = Round(Worksheets("Tabelle1").Range("M37").Value, 2)
         * If Worksheets("Tabelle1").Range("N36").Value = "nicht möglich" Then TextPult5.Value = Worksheets("Tabelle1").Range("N36").Value Else TextPult5.Value = Round(Worksheets("Tabelle1").Range("N36").Value, 2)
         * If Worksheets("Tabelle1").Range("N37").Value = "nicht möglich" Then TextPult7.Value = Worksheets("Tabelle1").Range("N37").Value Else TextPult7.Value = Round(Worksheets("Tabelle1").Range("N37").Value, 2)
         * TextPult11.Value = Round(Worksheets("Tabelle1").Range("M21").Value, 2)
         * If Worksheets("Tabelle1").Range("M49").Value = "nicht möglich" Then TextPult12.Value = Worksheets("Tabelle1").Range("M49").Value Else TextPult12.Value = Round(Worksheets("Tabelle1").Range("M49").Value, 2)
         * If Worksheets("Tabelle1").Range("N49").Value = "nicht möglich" Then TextPult13.Value = Worksheets("Tabelle1").Range("N49").Value Else TextPult13.Value = Round(Worksheets("Tabelle1").Range("N49").Value, 2)
         * TextPult14.Value = Round(Worksheets("Tabelle1").Range("M47").Value, 2)
         * TextPult15.Value = Round(Worksheets("Tabelle1").Range("M48").Value, 2)
         * TextPult10.Value = Round(Worksheets("Tabelle1").Range("M20").Value, 2)
         */
    }

    calcmasarddach() {
        let dach = this.state.daecher.filter(x => x.slug == "masarddach")[0];

        let TextMansard1 = dach.items.filter(x => x.name == "TextMansard1")[0];
        let TextMansard2 = dach.items.filter(x => x.name == "TextMansard2")[0];
        let TextMansard3 = dach.items.filter(x => x.name == "TextMansard3")[0];
        let TextMansard4 = dach.items.filter(x => x.name == "TextMansard4")[0];
        let TextMansard5 = dach.items.filter(x => x.name == "TextMansard5")[0];
        let TextMansard6 = dach.result.filter(x => x.name == "TextMansard6")[0];
        let TextMansard7 = dach.result.filter(x => x.name == "TextMansard7")[0];
        let TextMansard8 = dach.result.filter(x => x.name == "TextMansard8")[0];
        let TextMansard9 = dach.result.filter(x => x.name == "TextMansard9")[0];
        let TextMansard10 = dach.items.filter(x => x.name == "TextMansard10")[0];
        let TextMansard11 = dach.items.filter(x => x.name == "TextMansard11")[0];
        let TextMansard12 = dach.items.filter(x => x.name == "TextMansard12")[0];
        let TextMansard13 = dach.items.filter(x => x.name == "TextMansard13")[0];
        let TextMansard14 = dach.result.filter(x => x.name == "TextMansard14")[0];
        let TextMansard15 = dach.result.filter(x => x.name == "TextMansard15")[0];
        let TextMansard16 = dach.result.filter(x => x.name == "TextMansard16")[0];
        let TextMansard17 = dach.result.filter(x => x.name == "TextMansard17")[0];

        let P11 = loadMassarddach("P11");
        let P12 = loadMassarddach("P12");
        let P14 = loadMassarddach("P14");
        let P15 = loadMassarddach("P15");
        let P16 = loadMassarddach("P16");

        let Q36 = loadMassarddach("Q36");
        let P36 = loadMassarddach("P36");
        let P37 = loadMassarddach("P37");

        let Q37 = loadMassarddach("Q37");
        let P42 = loadMassarddach("P42");
        let P49 = loadMassarddach("P49");
        let Q49 = loadMassarddach("Q49");

        let P47 = loadMassarddach("P47");
        let P48 = loadMassarddach("P48");
        let P41 = loadMassarddach("P41");


        let P21 = loadMassarddach("P21");
        let P18 = loadMassarddach("P18");
        /*updateVorgMassarddach("D20", P21)
        updateVorgMassarddach("D21", P18)
        updateVorgMassarddach("D23", P37)
        updateVorgMassarddach("D24", P36)*/


        TextMansard6.value = Math.round(P36 * 100) / 100;
        TextMansard8.value = Math.round(P37 * 100) / 100;

        if (Q36 == "nicht möglich") {
            TextMansard7.value = Q36;
        } else {
            TextMansard7.value = Math.round(Q36 * 100) / 100;
        }

        if (Q37 == "nicht möglich") {
            TextMansard9.value = Q37;
        } else {
            TextMansard9.value = Math.round(Q37 * 100) / 100;
            //.toFixed(2);
        }

        TextMansard13.value = Math.round(P42 * 100) / 100;

        TextMansard14.value = Math.round(P49 * 100) / 100;

        if (Q49 == "nicht möglich") {
            TextMansard15.value = Q49;
        } else {
            TextMansard15.value = Math.round(Q49 * 100) / 100;
        }

        TextMansard16.value = Math.round(P47 * 100) / 100;
        TextMansard17.value = Math.round(P48 * 100) / 100;
        TextMansard12.value = Math.round(P41 * 100) / 100;

        if(P14 != null && !isNaN(P14) && P16 != null && !isNaN(P16)){
            if(P14 > P16 / 2) {
                TextMansard5.error = true;
            }else{
                TextMansard5.error = false;
            }
        }
    }

    calcassymsattelsach() {
        let dach = this.state.daecher.filter(x => x.slug == "assymsattelsach")[0];

        let TextAsym1 = dach.items.filter(x => x.name == "TextAsym1")[0];
        let TextAsym2 = dach.items.filter(x => x.name == "TextAsym2")[0];
        let TextAsym3 = dach.items.filter(x => x.name == "TextAsym3")[0];
        let TextAsym4 = dach.items.filter(x => x.name == "TextAsym4")[0];
        let TextAsym5 = dach.items.filter(x => x.name == "TextAsym5")[0];
        let TextAsym11 = dach.items.filter(x => x.name == "TextAsym11")[0];
        let TextAsym12 = dach.items.filter(x => x.name == "TextAsym12")[0];
        let TextAsym13 = dach.items.filter(x => x.name == "TextAsym13")[0];
        let TextAsym14 = dach.items.filter(x => x.name == "TextAsym14")[0];


        let TextAsym7 = dach.result.filter(x => x.name == "TextAsym7")[0];
        let TextAsym9 = dach.result.filter(x => x.name == "TextAsym9")[0];
        let TextAsym15 = dach.result.filter(x => x.name == "TextAsym15")[0];
        let TextAsym17 = dach.result.filter(x => x.name == "TextAsym17")[0];
        let TextAsym18 = dach.result.filter(x => x.name == "TextAsym18")[0];

        let TextAsym8 = dach.result.filter(x => x.name == "TextAsym8")[0];
        let TextAsym10 = dach.result.filter(x => x.name == "TextAsym10")[0];
        let TextAsym16 = dach.result.filter(x => x.name == "TextAsym16")[0];

        let G11 = load("G11");

        TextAsym1.value = G11 == null || isNaN(G11) ? "" : G11.toString().replace(".", ",");

        let G12 = load("G12");
        TextAsym2.value = G12 == null || isNaN(G12) ? "" : G12.toString().replace(".", ",");

        let G16 = load("G16");
        TextAsym3.value = G16 == null || isNaN(G16)  ? "" : G16.toString().replace(".", ",");

        let G17 = load("G17");
        TextAsym4.value = G17 == null || isNaN(G17)  ? "" : G17.toString().replace(".", ",");

        let G15 = load("G15");
        TextAsym5.value = G15 == null || isNaN(G16)  ? "" : G15.toString().replace(".", ",");


        let G18 = load("G18");
        TextAsym11.value = G18 == null || isNaN(G18)  ? "" : G18.toString().replace(".", ",");

        let G19 = load("G19");
        TextAsym12.value = G19 == null || isNaN(G19)  ? "" :  G19.toString().replace(".", ",");


        let G20 = load("G20");

        if(G20 != null && !isNaN(G20) ) {
            TextAsym13.value = G20.toFixed(2).toString().replace(".", ",");
        }else{
            TextAsym13.value = "";
        }

        let G21 = load("G21");

        if(G21 != null && !isNaN(G21) ) {
            TextAsym14.value = G21.toFixed(2).toString().replace(".", ",");
        }else{
            TextAsym14.value = "";
        }

        let G36 = load("G36");
        TextAsym7.value = G36 == null || isNaN(G36)  ? "" :  G36;

        if(typeof G36 != "string") {
            TextAsym7.value = G36.toFixed(2).toString().replace(".", ",");
        }

        let G37 = load("G37");
        TextAsym9.value = G37 == null || isNaN(G37)  ? "" :  G37;

        if(typeof G37 != "string") {
            TextAsym9.value = G37.toFixed(2).toString().replace(".", ",")
        }

        let G49 = load("G49");
        TextAsym15.value = G49 == null || isNaN(G49)  ? "" : G49;

        if(typeof G49 != "string") {
            TextAsym15.value = G49.toFixed(2).toString().replace(".", ",")
        }

        let G47 = load("G47");
        TextAsym17.value = G47 == null || isNaN(G47)  ? "" : G47;

        if(typeof G47 != "string") {
            TextAsym17.value = G47.toFixed(2).toString().replace(".", ",")
        }

        let G48 = load("G48");
        TextAsym18.value = G48 == null || isNaN(G48)  ? "" : G48;

        if(typeof G48 != "string") {
            TextAsym18.value = G48.toFixed(2).toString().replace(".", ",")
        }

        let H36 = load("H36");
        TextAsym8.value = H36 == null || isNaN(H36)  ? "" : H36;

        if(typeof H36 != "string") {
            TextAsym8.value = H36.toFixed(2).toString().replace(".", ",")
        }

        let H37 = load("H37");
        TextAsym10.value = H37 == null || isNaN(H37)  ? "" : H37;

        if(typeof H37 != "string") {
            TextAsym10.value = H37.toFixed(2).toString().replace(".", ",")
        }

        let H49 = load("H49");
        TextAsym16.value =  H49 == null || isNaN(H49)  ? "" :H49;

        if(typeof H49 != "string") {
            TextAsym16.value = H49.toFixed(2).toString().replace(".", ",");
        }

        if(G15 != null && !isNaN(G16) && G17 != null && !isNaN(G17)){
            if(G15 > G17) {
                TextAsym5.error = true;
            }else{
                TextAsym5.error = false;
            }
        }else{
            TextAsym5.error = false;
        }

        /* if(TextAsym2.value != "") {
             if (TextAsym2.value < 1 || TextAsym2.value > 89) {
                 TextAsym2.error = true;
             } else {
                 TextAsym2.error = false;
             }
         }
         if(TextAsym5.value != "") {
             if (TextAsym5.value < 1 || TextAsym5.value > 89) {
                 TextAsym5.error = true;
             } else {
                 TextAsym5.error = false;
             }
         }
         if(TextAsym11.value != "") {
             if (TextAsym11.value < 1 || TextAsym11.value > 180) {
                 TextAsym11.error = true;
             } else {
                 TextAsym11.error = false;
             }
         }*/

        /*
        if(TextSattel21.value != "") {
            if (TextSattel21.value < 1 || TextSattel21.value > 89) {
                TextSattel21.error = true;
            } else {
                TextSattel21.error = false;
            }
        }
        if(TextSattel14.value != "") {
            if (TextSattel14.value < 1 || TextSattel14.value > 180) {
                TextSattel14.error = true;
            } else {
                TextSattel14.error = false;
            }
        }*/
    }

    calcassymsattelsach_old() {
        let dach = this.state.daecher.filter(x => x.slug == "assymsattelsach")[0];

        let TextAsym1 = dach.items.filter(x => x.name == "TextAsym1")[0];
        let TextAsym2 = dach.items.filter(x => x.name == "TextAsym2")[0];
        let TextAsym3 = dach.items.filter(x => x.name == "TextAsym3")[0];
        let TextAsym4 = dach.items.filter(x => x.name == "TextAsym4")[0];
        let TextAsym5 = dach.items.filter(x => x.name == "TextAsym5")[0];
        let TextAsym11 = dach.items.filter(x => x.name == "TextAsym11")[0];
        let TextAsym12 = dach.items.filter(x => x.name == "TextAsym12")[0];
        let TextAsym13 = dach.items.filter(x => x.name == "TextAsym13")[0];
        let TextAsym14 = dach.items.filter(x => x.name == "TextAsym14")[0];

        let TextAsym7 = dach.result.filter(x => x.name == "TextAsym7")[0];
        let TextAsym9 = dach.result.filter(x => x.name == "TextAsym9")[0];
        let TextAsym15 = dach.result.filter(x => x.name == "TextAsym15")[0];
        let TextAsym17 = dach.result.filter(x => x.name == "TextAsym17")[0];
        let TextAsym18 = dach.result.filter(x => x.name == "TextAsym18")[0];

        let TextAsym8 = dach.result.filter(x => x.name == "TextAsym8")[0];
        let TextAsym10 = dach.result.filter(x => x.name == "TextAsym10")[0];
        let TextAsym16 = dach.result.filter(x => x.name == "TextAsym16")[0];

        if (TextAsym2.value !== "") {
            const numericValue = parseFloat(TextAsym2.value.replace(",", "."));
            if (!isNaN(numericValue)) {
                update("G12", numericValue);
               // G12 = numericValue;
            }
        }

        if (TextAsym3.value !== "") {
            const numericValue = parseFloat(TextAsym3.value.replace(",", "."));
            if (!isNaN(numericValue)) {
                update("G16", numericValue);
               // G16 = numericValue;
            }
        }

        if (TextAsym4.value !== "") {
            const numericValue = parseFloat(TextAsym4.value.replace(",", "."));
            if (!isNaN(numericValue)) {
                update("G17", numericValue);
               // G17 = numericValue;
            }
        }

        if (TextAsym5.value !== "") {
            const numericValue = parseFloat(TextAsym5.value.replace(",", "."));
            if (!isNaN(numericValue)) {
                update("G3", numericValue);
               // G13 = numericValue;
            }
        }

        if (TextAsym11.value !== "") {
            const numericValue = parseFloat(TextAsym11.value.replace(",", "."));
            if (!isNaN(numericValue)) {
                update("G18", numericValue);
             //   G18 = numericValue;
            }
        }

        if (TextAsym12.value !== "") {
            const numericValue = parseFloat(TextAsym12.value.replace(",", "."));
            if (!isNaN(numericValue)) {
                update("G19", numericValue);
              //  G19 = numericValue;
            }
        }

        setTimeout(function() {
            calcG20();
            TextAsym1.value  = calcG11();
            TextAsym13.value = calcG20().toFixed(2);
            TextAsym14.value = calcG21().toFixed(2);
            calcG24();
            calcG25();
            calcG27();
            calcG26();
            calcG23();
            calcG32();

            TextAsym7.value = calcG36().toFixed(2);
            TextAsym9.value = calcG37().toFixed(2);

            TextAsym15.value = calcG49().toFixed(2);

            TextAsym17.value = calcG47().toFixed(2);
            TextAsym18.value = calcG48().toFixed(2);

            TextAsym8.value = calcH36().toFixed(2);
            TextAsym10.value = calcH37().toFixed(2);
            TextAsym16.value = calcH49().toFixed(2);
        }.bind(this), 200);

        setTimeout(function() {
           let dd =  this.state.daecher.filter(x => x.slug == "assymsattelsach")[0];
            dd.items = dach.items;
            dd.result = dach.result;

            this.setState({daecher: this.state.daecher});

        }.bind(this), 300);
    }

    calcvorgassymsattelsach() {
        let dach = this.state.vorg_daecher.filter(x => x.slug == "assymsattelsach")[0];
        this.calcVorg("assymsattelsach");

        let TextAsym18 = dach.items.filter(x => x.name == "TextAsym18")[0];
        let G9 = loadVorgAsyncSatteldach("G9");
        TextAsym18.value = G9 == null ? "" : G9;

        let TextAsym13 = dach.items.filter(x => x.name == "TextAsym13")[0];
        let G10 = loadVorgAsyncSatteldach("G10");

        TextAsym13.value = G10 == null ? "" : G10;

        let TextAsym17 = dach.items.filter(x => x.name == "TextAsym17")[0];
        let G11 = loadVorgAsyncSatteldach("G11");
        TextAsym17.value = G11 == null ? "" : G11;

        let TextAsym24 = dach.items.filter(x => x.name == "TextAsym24")[0];
        let G12 = loadVorgAsyncSatteldach("G12");
        TextAsym24.value = G12 == null ? "" : G12;
        if(TextAsym24.value !== "") {
            if(TextAsym24.value <= 0 || TextAsym24.value >= 90) {
                TextAsym24.error = true;
            }else{
                TextAsym24.error = false;
            }
        }

        let TextAsym19 = dach.items.filter(x => x.name == "TextAsym19")[0];
        let G13 = loadVorgAsyncSatteldach("G13");
        TextAsym19.value = G13 == null ? "" : G13;
        if(TextAsym19.value !== "") {
            if(TextAsym19.value <= 0 || TextAsym19.value >= 90) {
                TextAsym19.error = true;
            }else{
                TextAsym19.error = false;
            }
        }

        let TextAsym14 = dach.items.filter(x => x.name == "TextAsym14")[0];
        let G14 = loadVorgAsyncSatteldach("G14");
        TextAsym14.value = G14 == null ? "" : G14;

        if(TextAsym14.value !== "") {
            if(TextAsym14.value <= 0 || TextAsym14.value >= 181) {
                TextAsym14.error = true;
            }else{
                TextAsym14.error = false;
            }
        }

        let TextAsym20 = dach.items.filter(x => x.name == "TextAsym20")[0];
        let G16 = loadVorgAsyncSatteldach("G16");
        TextAsym20.value = G16 == null ? "" : G16;

        let TextAsym132 = dach.items.filter(x => x.name == "TextAsym132")[0];
        let G17 = loadVorgAsyncSatteldach("G17");
        TextAsym132.value = G17 == null ? "" :  G17;

        let TextAsym15 = dach.result.filter(x => x.name == "TextAsym15")[0];
        let G31 = loadVorgAsyncSatteldach("G31");
        TextAsym15.value = G31 == null ? "" : G31;

        let TextAsym16 = dach.result.filter(x => x.name == "TextAsym16")[0];
        let G35 = loadVorgAsyncSatteldach("G35");
        if (G35 == "nicht relevant") {
            TextAsym16.value = G35;
        } else {
            TextAsym16.value = G35 == null ? "" : G35;
            //.toFixed(2)
        }

        let TextAsym21 = dach.result.filter(x => x.name == "TextAsym21")[0];
        let G36 = loadVorgAsyncSatteldach("G36");
        if (G36 == "nicht relevant") {
            TextAsym21.value = G36;
        } else {
            TextAsym21.value = G36 == null ? "" : G36;
            //.toFixed(2)
        }

        let TextAsym22 = dach.result.filter(x => x.name == "TextAsym22")[0];
        let G42 = loadVorgAsyncSatteldach("G42");
        if (G42 == "nicht relevant") {
            TextAsym22.value = G42;
        } else {
            TextAsym22.value = G42 == null ? "" : G42;
            //.toFixed(2)
        }

        let TextAsym113 = dach.result.filter(x => x.name == "TextAsym113")[0];
        let G43 = loadVorgAsyncSatteldach("G43");
        if (G43 == "nicht relevant") {
            TextAsym113.value = G43;
        } else {
            TextAsym113.value = G43 == null ? "" :  G43;
            //.toFixed(2)
        }

        let TextAsym115 = dach.result.filter(x => x.name == "TextAsym115")[0];
        let G44 = loadVorgAsyncSatteldach("G44");
        if (G44 == "nicht relevant") {
            TextAsym115.value = G44;
        } else {
            TextAsym115.value = G44 == null ? "" : G44;
            //.toFixed(2)
        }

        let TextAsym114 = dach.result.filter(x => x.name == "TextAsym114")[0];
        let G45 = loadVorgAsyncSatteldach("G45");
        //////console.log(G45);
        if (G45 == "nicht relevant") {
            TextAsym114.value = G45;
        } else {
            TextAsym114.value = G45 == null ? "" : G45;
            //.toFixed(2)
        }


    }

    calcVorg(type) {
        const selected = this.state.daecher.filter(x => x.selected == true)[0];


        if(type == "satteldach") {

            if(selected.slug == "satteldach") {

                updateVorgSatteldach("D20", loadSatteldach("D21"))
                updateVorgSatteldach("D21", loadSatteldach("D18"))
                updateVorgSatteldach("D23", loadSatteldach("D37"))
                updateVorgSatteldach("D24", loadSatteldach("D36"))
            }else if(selected.slug == "assymsattelsach") {
                updateVorgSatteldach("D20", loadAsyncSatteldach("G21"))
                updateVorgSatteldach("D21", loadAsyncSatteldach("G18"))
                updateVorgSatteldach("D23", loadAsyncSatteldach("G37"))
                updateVorgSatteldach("D24", loadAsyncSatteldach("G36"))
            }else if(selected.slug == "walmdach") {

                updateVorgSatteldach("D20", loadWalmdach("J21"))
                updateVorgSatteldach("D21", loadWalmdach("J18"))
                updateVorgSatteldach("D23", loadWalmdach("J37"))
                updateVorgSatteldach("D24", loadWalmdach("J36"))

            }else if(selected.slug == "pultdach") {
                updateVorgSatteldach("D20", loadPultdach("M21"))
                updateVorgSatteldach("D21", loadPultdach("M18"))
                updateVorgSatteldach("D23", loadPultdach("M37"))
                updateVorgSatteldach("D24", loadPultdach("M36"))
            }else if(selected.slug == "masarddach") {
                updateVorgSatteldach("D20", loadMassarddach("P42"))
                updateVorgSatteldach("D21", loadMassarddach("P18"))
                updateVorgSatteldach("D23", loadMassarddach("P37"))
                updateVorgSatteldach("D24", loadMassarddach("P36"))
            }else if(selected.slug == "flachdach") {
                updateVorgSatteldach("D20", loadFlachdach("T21"))
                updateVorgSatteldach("D21", loadFlachdach("T18"))
                updateVorgSatteldach("D23", loadFlachdach("T37"))
                updateVorgSatteldach("D24", loadFlachdach("T37"))
            }else if(selected.slug == "sheddach") {
                updateVorgSatteldach("D20", loadSheddach("W21"))
                updateVorgSatteldach("D21", loadSheddach("W18"))
                updateVorgSatteldach("D23", loadSheddach("W37"))
                updateVorgSatteldach("D24", loadSheddach("W36"))
            }



        }

         if(type == "assymsattelsach") {
            if(selected.slug == "satteldach") {
                updateVorgAsyncSatteldach("D20", loadSatteldach("D21"))
                updateVorgAsyncSatteldach("D21", loadSatteldach("D18"))
                updateVorgAsyncSatteldach("D23", loadSatteldach("D37"))
                updateVorgAsyncSatteldach("D24", loadSatteldach("D36"))
            }else if(selected.slug == "assymsattelsach") {
                updateVorgAsyncSatteldach("D20", loadAsyncSatteldach("G21"))
                updateVorgAsyncSatteldach("D21", loadAsyncSatteldach("G18"))
                updateVorgAsyncSatteldach("D23", loadAsyncSatteldach("G37"))
                updateVorgAsyncSatteldach("D24", loadAsyncSatteldach("G36"))

            }else if(selected.slug == "walmdach") {

                updateVorgAsyncSatteldach("D20", loadWalmdach("J21"))
                updateVorgAsyncSatteldach("D21", loadWalmdach("J18"))
                updateVorgAsyncSatteldach("D23", loadWalmdach("J37"))
                updateVorgAsyncSatteldach("D24", loadWalmdach("J36"))
            }else if(selected.slug == "pultdach") {
                updateVorgAsyncSatteldach("D20", loadPultdach("M21"))
                updateVorgAsyncSatteldach("D21", loadPultdach("M18"))
                updateVorgAsyncSatteldach("D23", loadPultdach("M37"))
                updateVorgAsyncSatteldach("D24", loadPultdach("M36"))
            }else if(selected.slug == "masarddach") {
                updateVorgAsyncSatteldach("D20", loadMassarddach("P42"))
                updateVorgAsyncSatteldach("D21", loadMassarddach("P18"))
                updateVorgAsyncSatteldach("D23", loadMassarddach("P37"))
                updateVorgAsyncSatteldach("D24", loadMassarddach("P36"))
            }else if(selected.slug == "flachdach") {
                updateVorgAsyncSatteldach("D20", loadFlachdach("T21"))
                updateVorgAsyncSatteldach("D21", loadFlachdach("T18"))
                updateVorgAsyncSatteldach("D23", loadFlachdach("T37"))
                updateVorgAsyncSatteldach("D24", loadFlachdach("T37"))
            }else if(selected.slug == "sheddach") {
                updateVorgAsyncSatteldach("D20", loadSheddach("W21"))
                updateVorgAsyncSatteldach("D21", loadSheddach("W18"))
                updateVorgAsyncSatteldach("D23", loadSheddach("W37"))
                updateVorgAsyncSatteldach("D24", loadSheddach("W36"))
            }
        }

        if(type == "walmdach") {

            if(selected.slug == "satteldach") {

                updateVorgWalmdach("D20", loadSatteldach("D21"))
                updateVorgWalmdach("D21", loadSatteldach("D18"))
                updateVorgWalmdach("D23", loadSatteldach("D37"))
                updateVorgWalmdach("D24", loadSatteldach("D36"))
            }else if(selected.slug == "assymsattelsach") {
                updateVorgWalmdach("D20", loadAsyncSatteldach("G21"))
                updateVorgWalmdach("D21", loadAsyncSatteldach("G18"))
                updateVorgWalmdach("D23", loadAsyncSatteldach("G37"))
                updateVorgWalmdach("D24", loadAsyncSatteldach("G36"))
            }else if(selected.slug == "walmdach") {
                updateVorgWalmdach("D20", loadWalmdach("J21"));
                updateVorgWalmdach("D21", loadWalmdach("J18"));
                updateVorgWalmdach("D23", loadWalmdach("J37"));
                updateVorgWalmdach("D24", loadWalmdach("J36"));
            }else if(selected.slug == "pultdach") {
                updateVorgWalmdach("D20", loadPultdach("M21"))
                updateVorgWalmdach("D21", loadPultdach("M18"))
                updateVorgWalmdach("D23", loadPultdach("M37"))
                updateVorgWalmdach("D24", loadPultdach("M36"))
            }else if(selected.slug == "masarddach") {
                ////console.log("xD20", loadMassarddach("P42"))
                ////console.log("xD21", loadMassarddach("P18"))
                ////console.log("xD23", loadMassarddach("P37"))
                ////console.log("xD24", loadMassarddach("P36"))

                updateVorgWalmdach("D20", loadMassarddach("P42"))
                updateVorgWalmdach("D21", loadMassarddach("P18"))
                updateVorgWalmdach("D23", loadMassarddach("P37"))
                updateVorgWalmdach("D24", loadMassarddach("P36"))
            }else if(selected.slug == "flachdach") {
                updateVorgWalmdach("D20", loadFlachdach("T21"))
                updateVorgWalmdach("D21", loadFlachdach("T18"))
                updateVorgWalmdach("D23", loadFlachdach("T37"))
                updateVorgWalmdach("D24", loadFlachdach("T37"))
            }else if(selected.slug == "sheddach") {
                updateVorgWalmdach("D20", loadSheddach("W21"))
                updateVorgWalmdach("D21", loadSheddach("W18"))
                updateVorgWalmdach("D23", loadSheddach("W37"))
                updateVorgWalmdach("D24", loadSheddach("W36"))
            }
        }

        if(type == "pultdach") {
            if(selected.slug == "satteldach") {
                updateVorgPultdach("D20", loadSatteldach("D21"))
                updateVorgPultdach("D21", loadSatteldach("D18"))
                updateVorgPultdach("D23", loadSatteldach("D37"))
                updateVorgPultdach("D24", loadSatteldach("D36"))
            }else if(selected.slug == "assymsattelsach") {
                updateVorgPultdach("D20", loadAsyncSatteldach("G21"))
                updateVorgPultdach("D21", loadAsyncSatteldach("G18"))
                updateVorgPultdach("D23", loadAsyncSatteldach("G37"))
                updateVorgPultdach("D24", loadAsyncSatteldach("G36"))
            }else if(selected.slug == "walmdach") {
               updateVorgPultdach("D20", loadWalmdach("J21"))
               updateVorgPultdach("D21", loadWalmdach("J18"))
               updateVorgPultdach("D23", loadWalmdach("J37"))
               updateVorgPultdach("D24", loadWalmdach("J36"))
            }else if(selected.slug == "pultdach") {
                updateVorgPultdach("D20", loadPultdach("M21"))
                updateVorgPultdach("D21", loadPultdach("M18"))
                updateVorgPultdach("D23", loadPultdach("M37"))
                updateVorgPultdach("D24", loadPultdach("M36"))
            }else if(selected.slug == "masarddach") {
                updateVorgPultdach("D20", loadMassarddach("P42"))
                updateVorgPultdach("D21", loadMassarddach("P18"))
                updateVorgPultdach("D23", loadMassarddach("P37"))
                updateVorgPultdach("D24", loadMassarddach("P36"))
            }else if(selected.slug == "flachdach") {
                updateVorgPultdach("D20", loadFlachdach("T21"))
                updateVorgPultdach("D21", loadFlachdach("T18"))
                updateVorgPultdach("D23", loadFlachdach("T37"))
                updateVorgPultdach("D24", loadFlachdach("T37"))
            }else if(selected.slug == "sheddach") {
                updateVorgPultdach("D20", loadSheddach("W21"))
                updateVorgPultdach("D21", loadSheddach("W18"))
                updateVorgPultdach("D23", loadSheddach("W37"))
                updateVorgPultdach("D24", loadSheddach("W36"))
            }
        }

        if(type == "masarddach") {
            if(selected.slug == "satteldach") {
                updateVorgMassarddach("D20", loadSatteldach("D21"))
                updateVorgMassarddach("D21", loadSatteldach("D18"))
                updateVorgMassarddach("D23", loadSatteldach("D37"))
                updateVorgMassarddach("D24", loadSatteldach("D36"))
            }else if(selected.slug == "assymsattelsach") {
                updateVorgMassarddach("D20", loadAsyncSatteldach("G21"))
                updateVorgMassarddach("D21", loadAsyncSatteldach("G18"))
                updateVorgMassarddach("D23", loadAsyncSatteldach("G37"))
                updateVorgMassarddach("D24", loadAsyncSatteldach("G36"))
            }else if(selected.slug == "walmdach") {

                updateVorgMassarddach("D20", loadWalmdach("J21"))
                updateVorgMassarddach("D21", loadWalmdach("J18"))
                updateVorgMassarddach("D23", loadWalmdach("J37"))
                updateVorgMassarddach("D24", loadWalmdach("J36"))
            }else if(selected.slug == "pultdach") {
                updateVorgMassarddach("D20", loadPultdach("M21"))
                updateVorgMassarddach("D21", loadPultdach("M18"))
                updateVorgMassarddach("D23", loadPultdach("M37"))
                updateVorgMassarddach("D24", loadPultdach("M36"))
            }else if(selected.slug == "masarddach") {
                updateVorgMassarddach("D20", loadMassarddach("P42"))
                updateVorgMassarddach("D21", loadMassarddach("P18"))
                updateVorgMassarddach("D23", loadMassarddach("P37"))
                updateVorgMassarddach("D24", loadMassarddach("P36"))
            }else if(selected.slug == "flachdach") {
                updateVorgMassarddach("D20", loadFlachdach("T21"))
                updateVorgMassarddach("D21", loadFlachdach("T18"))
                updateVorgMassarddach("D23", loadFlachdach("T37"))
                updateVorgMassarddach("D24", loadFlachdach("T37"))
            }else if(selected.slug == "sheddach") {
                updateVorgMassarddach("D20", loadSheddach("W21"))
                updateVorgMassarddach("D21", loadSheddach("W18"))
                updateVorgMassarddach("D23", loadSheddach("W37"))
                updateVorgMassarddach("D24", loadSheddach("W36"))
            }
        }

        if(type == "flachdach") {
            if(selected.slug == "satteldach") {
                updateVorgFlachdach("D20", loadSatteldach("D21"))
                updateVorgFlachdach("D21", loadSatteldach("D18"))
                updateVorgFlachdach("D23", loadSatteldach("D37"))
                updateVorgFlachdach("D24", loadSatteldach("D36"))
            }else if(selected.slug == "assymsattelsach") {
                updateVorgFlachdach("D20", loadAsyncSatteldach("G21"))
                updateVorgFlachdach("D21", loadAsyncSatteldach("G18"))
                updateVorgFlachdach("D23", loadAsyncSatteldach("G37"))
                updateVorgFlachdach("D24", loadAsyncSatteldach("G36"))
            }else if(selected.slug == "walmdach") {
                /*updateVorgWalmdach("D20", loadSatteldach("D21"))
                updateVorgWalmdach("D21", loadSatteldach("D18"))
                updateVorgWalmdach("D23", loadSatteldach("D37"))
                updateVorgWalmdach("D24", loadSatteldach("D36"))
                */
                updateVorgFlachdach("D20", loadWalmdach("J21"))
                updateVorgFlachdach("D21", loadWalmdach("J18"))
                updateVorgFlachdach("D23", loadWalmdach("J37"))
                updateVorgFlachdach("D24", loadWalmdach("J36"))
            }else if(selected.slug == "pultdach") {
                updateVorgFlachdach("D20", loadPultdach("M21"))
                updateVorgFlachdach("D21", loadPultdach("M18"))
                updateVorgFlachdach("D23", loadPultdach("M37"))
                updateVorgFlachdach("D24", loadPultdach("M36"))
            }else if(selected.slug == "masarddach") {
                updateVorgFlachdach("D20", loadMassarddach("P42"))
                updateVorgFlachdach("D21", loadMassarddach("P18"))
                updateVorgFlachdach("D23", loadMassarddach("P37"))
                updateVorgFlachdach("D24", loadMassarddach("P36"))
            }else if(selected.slug == "flachdach") {
                updateVorgFlachdach("D20", loadFlachdach("T21"))
                updateVorgFlachdach("D21", loadFlachdach("T18"))
                updateVorgFlachdach("D23", loadFlachdach("T37"))
                updateVorgFlachdach("D24", loadFlachdach("T37"))
            }else if(selected.slug == "sheddach") {
                updateVorgFlachdach("D20", loadSheddach("W21"))
                updateVorgFlachdach("D21", loadSheddach("W18"))
                updateVorgFlachdach("D23", loadSheddach("W37"))
                updateVorgFlachdach("D24", loadSheddach("W36"))
            }
        }

        if(type == "sheddach") {
            if(selected.slug == "satteldach") {
                updateVorgSheddach("D20", loadSatteldach("D21"))
                updateVorgSheddach("D21", loadSatteldach("D18"))
                updateVorgSheddach("D23", loadSatteldach("D37"))
                updateVorgSheddach("D24", loadSatteldach("D36"))
            }else if(selected.slug == "assymsattelsach") {

                updateVorgSheddach("D20", loadAsyncSatteldach("G21"))
                updateVorgSheddach("D21", loadAsyncSatteldach("G18"))
                updateVorgSheddach("D23", loadAsyncSatteldach("G37"))
                updateVorgSheddach("D24", loadAsyncSatteldach("G36"))
            }else if(selected.slug == "walmdach") {
                updateVorgSheddach("D20", loadWalmdach("J21"))
                updateVorgSheddach("D21", loadWalmdach("J18"))
                updateVorgSheddach("D23", loadWalmdach("J37"))
                updateVorgSheddach("D24", loadWalmdach("J36"))
            }else if(selected.slug == "pultdach") {
                updateVorgSheddach("D20", loadPultdach("M21"))
                updateVorgSheddach("D21", loadPultdach("M18"))
                updateVorgSheddach("D23", loadPultdach("M37"))
                updateVorgSheddach("D24", loadPultdach("M36"))
            }else if(selected.slug == "masarddach") {
                updateVorgSheddach("D20", loadMassarddach("P42"))
                updateVorgSheddach("D21", loadMassarddach("P18"))
                updateVorgSheddach("D23", loadMassarddach("P37"))
                updateVorgSheddach("D24", loadMassarddach("P36"))
            }else if(selected.slug == "flachdach") {
                updateVorgSheddach("D20", loadFlachdach("T21"))
                updateVorgSheddach("D21", loadFlachdach("T18"))
                updateVorgSheddach("D23", loadFlachdach("T37"))
                updateVorgSheddach("D24", loadFlachdach("T37"))
            }else if(selected.slug == "sheddach") {
                updateVorgSheddach("D20", loadSheddach("W21"))
                updateVorgSheddach("D21", loadSheddach("W18"))
                updateVorgSheddach("D23", loadSheddach("W37"))
                updateVorgSheddach("D24", loadSheddach("W36"))
            }
        }
    }

    handleChangeText(event) {
        const value = event.target.value;
        const name = event.target.name;

        this.setState({[name]: value});
    }

    handleChange(e, iteml, k) {
        var found = null;
        if(e.target.value == null) {
            e.target.value = "";
        }

        e.target.value = e.target.value.toString().replace(/[A-Z]/g, '');
        e.target.value = e.target.value.toString().replace(/[a-z]/g, '');
        e.target.value = e.target.value.toString().replace(/[&\/\\#;+()$~_%-'":*?<>{}]/g, '');
        e.target.value = e.target.value.toString().replace(",,", ",");
        e.target.value = e.target.value.toString().replace("..", ".");
        e.target.value = e.target.value.toString().replace("-", "");
        e.target.value = e.target.value.toString().replace("ß", "");
        e.target.value = e.target.value.toString().replace("=", "");
        e.target.value = e.target.value.toString().replace("`", "");
        e.target.value = e.target.value.toString().replace("/[^\\d*(\\.\\d{0,2})?$]/", "");

        e.target.value = e.target.value.toString().replace(",", ".");
        let da = e.target.value.split(".");
        if(da.length > 1) {
            da[1] = da[1].substring(0,2);
            e.target.value = da[0]+","+da[1]
        }else{
            e.target.value = e.target.value.toString().replace(".", ",");
        }

        found = this.state.daecher.filter(x => x.slug == iteml.slug)[0];

        if(typeof found["items"][k] == "undefined") {
            return true
        }
        found["items"][k]["value"] = e.target.value.toString().replace("--","-");
        this.setState({daecher: this.state.daecher}, function() {

            if(iteml.slug == "assymsattelsach")  {
//////console.log(k)

                let dach = this.state.daecher.filter(x => x.slug == "assymsattelsach")[0];

                let TextAsym1 = dach.items.filter(x => x.name == "TextAsym1")[0];

                if(k == 2) {
                    update("G11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    update("G12", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 0) {
                    update("G16", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    update("G17", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    update("G13", e.target.value.toString().replace(",",".").replace(",,","."))
                    update("G15", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    update("G18", e.target.value.toString().replace(",",".").replace(",,","."))
                }

                if(k == 7) {

                    update("G19", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                let G11 = load("G11");
                //////console.log(G11);
                TextAsym1.value = G11;
            }

            if(iteml.slug == "flachdach") {

                if(k == 0) {
                    updateFlachdach("T19", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    updateFlachdach("T15", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updateFlachdach("T13", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateFlachdach("T18", e.target.value.toString().replace(",",".").replace(",,","."))
                }

            }

            if(iteml.slug == "satteldach") {
                //TextSattel1
                if(k == 1) {
                    updateSatteldach("D11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 0) {
                    updateSatteldach("D15", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updateSatteldach("D13", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    updateSatteldach("D18", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateSatteldach("D19", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

            if(iteml.slug == "pultdach") {

                if(k == 1) {
                    updatePultdach("M11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 0) {
                    updatePultdach("M15", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updatePultdach("M13", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    updatePultdach("M18", e.target.value.toString().replace(",",".").replace(",,","."))
                }

                if(k == 4) {
                    //TextPult9
                    updatePultdach("M19", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

            if(iteml.slug == "masarddach") {
                //TextSattel
////console.log(k, e.target.value.toString().replace(",",".").replace(",,","."))
                if(k == 3) {
                    updateMassarddach("P11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updateMassarddach("P12", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    updateMassarddach("P15", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 0) {
                    updateMassarddach("P16", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateMassarddach("P14", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    updateMassarddach("P18", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 7) {
                    updateMassarddach("P40", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

            if(iteml.slug == "sheddach") {

                if(k == 0) {
                    updateSheddach("W15", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    updateSheddach("W13", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updateSheddach("W14", e.target.value.toString().replace(",",".").replace(",,","."))
                }

                if(k == 3) {
                    updateSheddach("W12", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateSheddach("W18", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    updateSheddach("W19", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

            if(iteml.slug == "walmdach") {
                //TextSattel1
                ////console.log(k,e.target.value.toString().replace(",",".").replace(",,","."))
                if(k == 2) {
                    updateWalmdach("J11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    updateWalmdach("J12", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 0) {
                    updateWalmdach("J15", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    updateWalmdach("J38", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    updateWalmdach("J13", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 6) {
                    updateWalmdach("J14", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 7) {
                    updateWalmdach("J18", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 8) {
                    updateWalmdach("J19", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

        });
    }

    handleVorgChange(e, iteml, k) {
        var found = null;

        found = this.state.vorg_daecher.filter(x => x.slug == iteml.slug)[0]

        if(typeof found["items"][k] == "undefined") {
            return true
        }

        if(e.target.value == null) {
            e.target.value = "";
        }


        if(e.target.value.toString() == "NaN" || e.target.value.toString() == "") {
            e.target.value = "";
        }

        found["items"][k]["value"] = e.target.value.toString().replace("--","-");


        this.setState({vorg_daecher: this.state.vorg_daecher}, function() {

            if(iteml.slug == "flachdach") {

                if(k == 0) {
                    updateVorgFlachdach("T9", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {

                    updateVorgFlachdach("T10", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {

                    updateVorgFlachdach("T11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    updateVorgFlachdach("T14", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateVorgFlachdach("T15", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    updateVorgFlachdach("T16", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 6) {
                    updateVorgFlachdach("T17", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

            if(iteml.slug == "satteldach") {
                //////console.log(k)
                if(k == 0) {
                    updateVorgSatteldach("D9", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    updateVorgSatteldach("D10", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updateVorgSatteldach("D11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    updateVorgSatteldach("D12", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateVorgSatteldach("D14", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    updateVorgSatteldach("D16", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 6) {
                    updateVorgSatteldach("D17", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

            if(iteml.slug == "assymsattelsach") {
//////console.log(k)
                if(k == 0) {
                    updateVorgAsyncSatteldach("G9", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    updateVorgAsyncSatteldach("G10", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updateVorgAsyncSatteldach("G11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    updateVorgAsyncSatteldach("G12", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateVorgAsyncSatteldach("G13", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    updateVorgAsyncSatteldach("G14", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 6) {
                    updateVorgAsyncSatteldach("G16", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 7) {
                    updateVorgAsyncSatteldach("G17", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

            if(iteml.slug == "pultdach") {

                if(k == 0) {
                    updateVorgPultdach("M9", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    updateVorgPultdach("M10", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updateVorgPultdach("M11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    updateVorgPultdach("M12", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateVorgPultdach("M14", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    updateVorgPultdach("M16", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 6) {
                    updateVorgPultdach("M17", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

            if(iteml.slug == "masarddach") {
                console.log(k)
                if(k == 0) {
                    updateVorgMassarddach("P9", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    updateVorgMassarddach("P10", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updateVorgMassarddach("P11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    updateVorgMassarddach("P12", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateVorgMassarddach("P13", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    updateVorgMassarddach("P14", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 6) {
                    updateVorgMassarddach("P15", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 7) {
                    updateVorgMassarddach("P16", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 8) {
                    updateVorgMassarddach("P17", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 9) {
                    updateVorgMassarddach("P18", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

            if(iteml.slug == "flachdach") {
                if(k == 0) {
                    updateVorgFlachdach("S9", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    updateVorgFlachdach("S10", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updateVorgFlachdach("S11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    updateVorgFlachdach("S14", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateVorgFlachdach("S16", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    updateVorgFlachdach("S17", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

            if(iteml.slug == "sheddach") {
                if(k == 0) {
                    updateVorgSheddach("V9", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    updateVorgSheddach("V10", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updateVorgSheddach("V11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    updateVorgSheddach("V14", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateVorgSheddach("V16", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    updateVorgSheddach("V17", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

            if(iteml.slug == "walmdach") {
                //////console.log(k)
                //////console.log(e.target.value)
                if(k == 0) {
                    updateVorgWalmdach("J9", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 1) {
                    updateVorgWalmdach("J10", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 2) {
                    updateVorgWalmdach("J11", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 3) {
                    updateVorgWalmdach("J12", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 4) {
                    updateVorgWalmdach("J14", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 5) {
                    updateVorgWalmdach("J16", e.target.value.toString().replace(",",".").replace(",,","."))
                }
                if(k == 6) {
                    updateVorgWalmdach("J17", e.target.value.toString().replace(",",".").replace(",,","."))
                }
            }

        });
    }

    updateChange(iteml) {

        if(iteml.slug == "flachdach") {
            this.load(this.state.daecher.filter(x => x.selected == true)[0], "update");
        }

        if(iteml.slug == "assymsattelsach") {
            this.calcassymsattelsach();
        }

        if(iteml.slug == "masarddach") {
            this.calcmasarddach();
        }

        if(iteml.slug == "pultdach") {
            this.calcapultdach();
        }

        if(iteml.slug == "satteldach") {
            this.calcsatteldach();
        }

        if(iteml.slug == "sheddach") {
            this.calcsheddach();
        }

        if(iteml.slug == "walmdach") {
            this.calcwalmdach();
        }

        this.setState({isLoading: true});
        this.setState({isLoading: false});

    }

    updateVorgChange(iteml) {
        if(iteml.slug == "satteldach") {
            this.calcvorgsatteldach();
        }

        if(iteml.slug == "assymsattelsach") {
            this.calcvorgassymsattelsach();
        }

        if(iteml.slug == "walmdach") {
            this.calcvorgwalmdach();
        }

        if(iteml.slug == "pultdach") {
            this.calcvorgpultdach();
        }

        if(iteml.slug == "flachdach") {
            this.calcvorgflachdach();
        }

        if(iteml.slug == "masarddach") {
            this.calcvorgmassarddach();
        }

        if(iteml.slug == "sheddach") {
            this.calcvorgsheddach();
        }

        this.setState({isLoading: true});
        this.setState({isLoading: false});

    }

    showForm(found) {
        let innerwidth = (this.state.width);
        return  <div><div style={{display: innerwidth < 1024 ? "block" : "flex"}}>

            <div style={{marginTop: 40}}>
                {found.items.map((x, k) => {
                    return x.type == "text" ? <div style={{width: "100%", display: "none", marginLeft: 12, marginBottom: 20}}>{x.label}</div> : <div style={{height: innerwidth < 900 ? "auto" : found.slug == "flachdach" ? 110 : found.slug == "walmdach" ? 125 : found.slug == "sheddach" ? 130 : 70, width: innerwidth < 1024 ? "90%": "40%", marginRight: "5%", float: "left", marginLeft: 10, marginBottom: 15}}>

                        <div style={{display: "inline"}}>
                        <input
                               id={x.name}
                               placeholder={x.disabled ? "-" : "Wert eingeben"}
                               disabled={x.disabled}
                               type={x.disabled ? "text" : "tel"}
                               style={{marginBottom: 0,width:"85%",backgroundColor: x.error ? "red": x.disabled ? "#999": "#f1f1f2"}}
                               value={x.disabled ? this.checkParser(x.value) : isNaN(parseInt(x.value.toString().replace(",", ""))) ? "" :  x.value} name={x.name}
                               onBlur={() => this.updateChange(found)}

                               onChange={(y) => this.handleChange(y, found, k)}
                        />
                        <small style={{marginLeft: 5}}>{x.subfix}</small>
                        </div>
                        <div style={{padding: 5, paddingTop: x.label.length > 29 ? 0 : 14, paddingLeft: 0, fontSize: 12}}>{x.label}</div>
                    </div>
                })}

                <div style={{clear: "both"}}></div>
            </div>
            <div>
                {this.showHome(found)}
            </div>

        </div>
            {
                found.slug == "assymsattelsach" ? found.items.filter(x => (x.name == "TextAsym1" && x.value >= 75) || (x.name == "TextAsym2" && x.value >= 75)).length > 0 ? <div style={{color: "red"}}>
                    Nach VDI 3781 Blatt 4, Ziffer 6.2.1.2.4, ist bei einem Dachneigungswinkel >= 75° das Dach wie ein Pultdach zu behandeln.
                </div> : null : null
            }
            {this.showResult(found)}
        </div>
    }

    isRegularValue(x) {
         let allow_text = false;

         let wert = x;

         if(typeof wert != "undefined" && wert != null) {
            if(typeof wert == "object") {
                allow_text = false;
            }else if(wert.toString().indexOf("-") ==! -1) {
                allow_text = true;

            }else if(!isNaN(parseInt(wert.toString().replace(",", "")))) {
                allow_text = true

            }
         }

         if(allow_text === false) {
            return "";
         }

        return wert;
    }

    showVorgForm(found) {
        let innerwidth = (this.state.width);
        return  <div><div style={{display: innerwidth < 1024 ? "block" : "flex"}}>

            <div style={{marginTop: 40}}>
                {found.items.map((x, k) => {
                    return x.type == "text" ? <div style={{width: "100%", display: "inline-block", marginLeft: 12, marginBottom: 20}}>{x.label}</div> : <div style={{marginBottom: 20, height: innerwidth < 800 ? "auto": 145, width: innerwidth < 1024 ? "90%": "40%", marginRight: "5%", float: "left", marginLeft: 10}}>

                        <div style={{display: "inline"}}>
                        <input type="text"
                               id={x.name}
                               placeholder={x.disabled ? "-" : "Wert eingeben"}
                               disabled={x.disabled}
                               type={x.disabled ? "text" : "tel"}
                               style={{marginBottom: 0,width:"85%",backgroundColor: x.error ? "red": x.disabled ? "#999": "#f1f1f2"}}
                               value={this.isRegularValue(x.value)} name={x.name}
                               onBlur={() => this.updateVorgChange(found)}
                               onChange={(y) => this.handleVorgChange(y, found, k)}
                        />
                        <small style={{marginLeft: 5}}>{x.subfix}</small></div>
                        <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>{x.label}</div>
                    </div>
                })}

                <div style={{clear: "both"}}></div>

            </div>
            <div>
                {this.showVorgHome(found)}
            </div>

        </div>
            {this.showVorgResult(found)}
        </div>
    }

    showVorgHome(found) {
        let innerwidth = this.state.width;

            if(found.slug == "masarddach") {
                return <div style={{marginLeft: 12}}>

                    <div style={{width: innerwidth < 1024 ? 360.5:  536, height: innerwidth < 1024 ?  405.1 : 505.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                    </div>

                </div>
            }

            if(found.slug == "satteldach") {
                return <div style={{marginLeft: 12}}>

                    <div style={{width: innerwidth < 1024 ? 360.5:  536, height: innerwidth < 1024 ?  405.1 : 505.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                    </div>

                </div>
            }

            if(found.slug == "sheddach") {
                return <div style={{marginLeft: 12}}>
                    <div style={{width: innerwidth < 1024 ? 360.5:  536, height: innerwidth < 1024 ?  405.1 : 505.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                    </div>
                </div>
            }

            if(found.slug == "walmdach") {
                return <div style={{marginLeft: 12}}>

                    <div style={{width: innerwidth < 1024 ? 360.5:  536, height: innerwidth < 1024 ?  405.1 : 505.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                    </div>

                </div>
            }

            if(found.slug == "flachdach") {
                return <div style={{marginLeft: 12}}>
                    <div style={{width: innerwidth < 1024 ? 360.5:  536, height: innerwidth < 1024 ?  405.1 : 505.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                    </div>

                </div>
            }

            return <div style={{marginLeft: 12}}>

                <div style={{width: innerwidth < 1024 ? 360.5:  536, height: innerwidth < 1024 ?  405.1 : 505.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                </div>

            </div>


    }

    showHome(found) {
        let innerwidth = this.state.width;

        if(this.state.type_dach != "daecher") {


            if(found.slug == "masarddach") {
                return <div style={{marginLeft: 12}}>

                    <div style={{width: innerwidth < 1024 ? 360.5:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                    </div>

                </div>
            }

            if(found.slug == "satteldach") {
                return <div style={{marginLeft: 12}}>

                    <div style={{width: innerwidth < 1024 ? 360.5:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                    </div>

                </div>
            }

            if(found.slug == "sheddach") {
                return <div style={{marginLeft: 12}}>
                    <div style={{width: innerwidth < 1024 ? 360.5:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                    </div>
                </div>
            }

            if(found.slug == "walmdach") {
                return <div style={{marginLeft: 12}}>

                    <div style={{width: innerwidth < 1024 ? 360.5:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                    </div>

                </div>
            }

            if(found.slug == "flachdach") {
                return <div style={{marginLeft: 12}}>
                    <div style={{width: innerwidth < 1024 ? 360.5:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                    </div>

                </div>
            }

            return <div style={{marginLeft: 12}}>

                <div style={{width: innerwidth < 1024 ? 360.5:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/vorgelagert/" + found.image + ")"}}>

                </div>

            </div>
        }

        if(found.slug == "flachdachold") {
            return <div style={{marginLeft: 12}}>
                <div style={{width: innerwidth < 1024 ? 360.5:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/home_colored/" + found.image + ")"}}>
                    <span style={{position: "absolute",marginLeft: 185,marginTop: 8}}>= 2m</span>
                    <span style={{position: "absolute",marginLeft: 290,marginTop: 80}}>10m</span>
                    <span style={{position: "absolute",marginLeft: 160,marginTop: 120}}>b = 80m</span>
                </div>
            </div>
        }

        if(found.slug == "masarddach") {
            return <div style={{marginLeft: 12}}>
                <div style={{width: innerwidth < 1024 ? 536:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/home_colored/" + found.image + ")"}}>
                    <span style={{position: "absolute", marginLeft: 234,marginTop: 34}}>= {found.items[4].value}m</span>
                    <span style={{position: "absolute", marginLeft: 362,marginTop: 180}}>= {found.items[8].value}m</span>
                    <span style={{position: "absolute", marginLeft: 162,marginTop: 180}}>= {found.items[3].value}°</span>
                    <span style={{position: "absolute", marginLeft: 122,marginTop: 255}}>= {found.items[2].value}°</span>
                    <span style={{position: "absolute", marginLeft: 215,marginTop: 275}}>= {found.items[1].value}m</span>
                    <span style={{position: "absolute", marginLeft: 205,marginTop: 370}}>= {found.items[0].value}m</span>
                    <span style={{position: "absolute", marginLeft: 365,marginTop: 300}}>= {found.items[7].value}m</span>
                </div>

            </div>
        }

        if(found.slug == "satteldach") {
            return <div style={{marginLeft: 12}}>

                <div style={{width: innerwidth < 1024 ? 536:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/home_colored/" + found.image + ")"}}>
                    <span style={{position: "absolute", marginLeft: 258, marginTop: 38}}>= {found.items[2].value}m</span>
                    <span style={{position: "absolute", marginLeft: 415,marginTop: 200}}>={found.items[5].value}m</span>
                    <span style={{position: "absolute", marginLeft: 415,marginTop: 300}}>={found.items[4].value}m</span>

                    <span style={{position: "absolute", marginLeft: 150,marginTop: 250, color: "red"}}>= {found.items[1].value}°</span>

                    <span style={{position: "absolute", marginLeft: 230,marginTop: 360}}>= {found.items[0].value}m</span>
                </div>

            </div>
        }

        if(found.slug == "pultdach") {
            return <div style={{marginLeft: 12}}>

                <div style={{width: innerwidth < 1024 ? 536:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/home_colored/" + found.image + ")"}}>
                    <span style={{position: "absolute", marginLeft: 125, marginTop: 21}}>={found.items[2].value}m</span>
                    <span style={{position: "absolute", marginLeft: 405,marginTop: 160}}>={found.items[5].value}m</span>
                    <span style={{position: "absolute", marginLeft: 405,marginTop: 308}}>={found.items[4].value}m</span>
                    <span style={{position: "absolute", marginLeft: 280,marginTop: 242, color: "red"}}>={found.items[1].value}°</span>
                    <span style={{position: "absolute", marginLeft: 230,marginTop: 363}}>={found.items[0].value}m</span>
                </div>

            </div>
        }

        if(found.slug == "assymsattelsach") {
            return <div style={{marginLeft: 12}}>

                <div style={{width: innerwidth < 1024 ? 536:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/home_colored/" + found.image + ")"}}>
                    <span style={{position: "absolute", marginLeft: 190, marginTop: 10}}>= {found.items[4].value}m</span>
                    <span style={{position: "absolute", marginLeft: 395,marginTop: 200}}>={found.items[8].value}m</span>
                    <span style={{position: "absolute", marginLeft: 395,marginTop: 300}}>={found.items[7].value}m</span>

                    <span style={{position: "absolute", marginLeft: 105, marginTop: 278, color: "red"}}>={found.items[2].value}°</span>
                    <span style={{position: "absolute", marginLeft: 280,marginTop: 278, color: "red"}}>={found.items[3].value}°</span>

                    <span style={{position: "absolute", marginLeft: 115,marginTop: 355}}>= {found.items[0].value}m</span>
                    <span style={{position: "absolute", marginLeft: 260,marginTop: 355}}>= {found.items[1].value}m</span>
                </div>

            </div>
        }

        if(found.slug == "sheddach") {
            return <div style={{marginLeft: 12}}>
                <div style={{width: innerwidth < 1024 ? 536:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/home_colored/" + found.image + ")"}}>
                    <span style={{position: "absolute", marginLeft: 356,marginTop: 5}}>= {found.items[1].value}m</span>
                    <span style={{position: "absolute", marginLeft: 395,marginTop: 45}}>={found.items[2].value}m</span>
                    <span style={{position: "absolute", marginLeft: 375,marginTop: 220, color: "red"}}>= {found.items[3].value}°</span>
                    <span style={{position: "absolute", marginLeft: 489,marginTop: 210}}>= {found.items[5].value}m</span>
                    <span style={{position: "absolute", marginLeft: 286,marginTop: 334}}>= {found.items[0].value}m</span>
                </div>
            </div>
        }

        if(found.slug == "walmdach") {
            return <div style={{marginLeft: 12}}>

                <div style={{width: innerwidth < 1024 ? 536:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/home_colored/" + found.image + ")"}}>
                    <span style={{position: "absolute",marginLeft: 225,marginTop: 12}}>={found.items[5].value}m</span>
                    <span style={{position: "absolute",marginLeft: 270,marginTop: 107}}>={found.items[6].value}m</span>
                    <span style={{position: "absolute",marginLeft: 125,marginTop: 43}}>={found.items[4].value}m</span>
                    <span style={{position: "absolute",marginLeft: 172,marginTop: 360}}>= {found.items[0].value}m</span>
                    <span style={{position: "absolute",marginLeft: 104,marginTop: 86}}>= {found.items[9].value}m</span>
                    <span style={{position: "absolute",marginLeft: 195,marginTop: 187,color: "red"}}>={found.items[3].value}°</span>
                    <span style={{position: "absolute",marginLeft: 275,marginTop: 142,color: "red"}}>={found.items[2].value}°</span>
                    <span style={{position: "absolute",marginLeft: 417,marginTop: 320}}>={found.items[1].value}m</span>

                    <span style={{position: "absolute",marginLeft: 105,marginTop: 235}}>={found.items[8].value}m</span>
                    <span style={{position: "absolute",marginLeft: 355,marginTop: 259}}>={found.items[4].value}m</span>
                </div>

            </div>
        }

        if(found.slug == "flachdach") {
            return <div style={{marginLeft: 12}}>
                <div style={{width: innerwidth < 1024 ? 536:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/home_colored/" + found.image + ")"}}>
                    <span style={{position: "absolute", marginLeft: 290,marginTop: 12}}>= {found.items[1].value}m</span>
                    <span style={{position: "absolute", marginLeft: 274,marginTop: 247}}>= {found.items[0].value}m</span>
                    <span style={{position: "absolute", marginLeft: 480,marginTop: 177}}>= {found.items[3].value}m</span>
                </div>

            </div>
        }

        return <div style={{marginLeft: 12}}>

            <div style={{width: innerwidth < 1024 ? 536:  536, height: 405.1, backgroundRepeat: "no-repeat",backgroundSize: "contain",backgroundImage: "url(/home_colored/" + found.image + ")"}}>
                <span style={{position: "absolute", marginLeft: 84,marginTop: 8}}>= 2m</span>
                <span style={{position: "absolute", marginLeft: 175,marginTop: 90}}>=10m</span>
                <span style={{position: "absolute", marginLeft: 179,marginTop: 151}}>= 6m</span>
                <span style={{position: "absolute", marginLeft: 32,marginTop: 190}}>= 3m</span>
                <span style={{position: "absolute", marginLeft: 98,marginTop: 190}}>= 10m</span>
            </div>

        </div>
    }

    checkParser(valer) {
        if(valer == null) {
            return ""
        }
        if(valer.toString() == "NaN" || valer.toString() == "") {
            return ""
        }
        if(valer.toString() == "0") {
            return ""
        }
         let data = valer;

         if(valer.toString().indexOf(",") != -1) {
             data = parseFloat(data.toString().replace(",",".").replace(",,",".")).toFixed(2).toString().replace(".", ",");
         }else if(!isNaN(valer)){

             data = parseFloat(data.toString().replace(",",".").replace(",,",".")).toFixed(2).toString().replace(".", ",");
         }else if((valer.toString().indexOf(".") != -1)){
             data = parseFloat(data.toString()).toFixed(2).toString().replace(".", ",");
         }
        return data
    }

    showResult(found) {
        let innerwidth = this.state.width;

        if (found.items.filter(x => x.value == "").length > 0) {
            return ""
        }

        if (found.slug == "flachdachold") {

            return <div style={{marginLeft: 12}}>
                <h3>Ergebnis der Berechnung für Flachdach</h3>
                <div style={{display: innerwidth < 1024 ? "block" : "flex"}}>
                    <div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600"}}>Scho.-Höhe nach VDI 3781 BI. 4 Abs. 6.2.1
                            </div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym7"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][0].value)} name={"TextAsym7"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym9"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][1].value)} name={"TextAsym9"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym15"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][2].value)} name={"TextAsym15"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugseben (AB)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12}}>Scho.-Höhe nach §19 Abs. 1</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][3].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>Dachfläche (HAges)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][4].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym16"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][5].value)} name={"TextAsym16"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugseben (AB)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12}}>Mindestabstand zu Lüftungsöffnungen, Fenster und Türen des
                                Nachbargebäudes
                            </div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][6].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>

                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12}}>Mindesthöhe der Schornsteinmündung über Läuftg.-öffnung.,
                                Fenster und Türen des Nachbargebäudes
                            </div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][7].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>

                    </div>
                </div>
            </div>
        }

        if (found.slug == "masarddach") {
            found["result"].map(x => x.value = x.value.toString().indexOf(".") != -1 ? x.value.toString().replace(".", ",") : x.value)
            return <div style={{marginLeft: 12}}>
                <h3>Ergebnis der Berechnung</h3>
                <div style={{display: innerwidth < 1024 ? "block" : "flex"}}>
                    <div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600"}}>Schornsteinhöhe nach VDI 3781 Blatt 4, Abs. 6.2.1</div>
                            <div style={{display: "flex", marginTop: 18}}>
                                <input type="text"
                                       id={"TextAsym7"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][0].value)} name={"TextAsym7"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym9"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][1].value)} name={"TextAsym9"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym15"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][2].value)} name={"TextAsym15"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600}}>Schornsteinhöhe nach § 19 Abs. 1 der 1. BImSchV</div>
                            <div style={{display: "flex", marginTop: 18}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][3].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][4].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym16"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][5].value)} name={"TextAsym16"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600}}>Mindestabstand zu Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][6].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>

                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600, marginTop: 7}}>Mindesthöhe der Schorn-steinmündung über Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 10}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][7].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}></div>
                        </div>

                    </div>
                </div>
            </div>
        }

        if (found.slug == "satteldach") {
            found["result"].map(x => x.value = x.value.toString().indexOf(".") != -1 ? x.value.toString().replace(".", ",") : x.value)
            return <div style={{marginLeft: 12}}>
                <h3>Ergebnis der Berechnung</h3>
                <div style={{display: innerwidth < 1024 ? "block" : "flex"}}>
                    <div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600", height: 40}}>Schornsteinhöhe nach VDI 3781 Blatt 4, Abs. 6.2.1</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym7"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][0].value)} name={"TextAsym7"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym9"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][1].value)} name={"TextAsym9"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym15"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][2].value)} name={"TextAsym15"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600", height: 40}}>Schornsteinhöhe nach § 19 Abs. 1 der 1. BImSchV</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][3].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][4].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym16"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][5].value)} name={"TextAsym16"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600}}>Mindestabstand zu Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][6].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>

                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600}}>Mindesthöhe der Schorn-steinmündung über Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][7].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}></div>
                        </div>

                    </div>
                </div>
            </div>
        }

        if (found.slug == "sheddach") {
            found["result"].map(x => x.value = x.value == null ? "" : (x.value.toString().indexOf(".") != -1 ? x.value.toString().replace(".", ",") : x.value))
            return <div style={{marginLeft: 12}}>
                <h3>Ergebnis der Berechnung</h3>
                <div style={{display: innerwidth < 1024 ? "block" : "flex"}}>
                    <div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600", height: 56}}>Schornsteinhöhe nach VDI 3781, Blatt 4, Abs. 6.2.1</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym7"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][0].value)} name={"TextAsym7"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über schräger Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym9"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][1].value)} name={"TextAsym9"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Sheddachfirst (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym15"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][2].value)} name={"TextAsym15"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600"}}>Schornsteinhöhe nach VDI 3781 BIatt 4, Abs. 6.2.1.2.6; Hges. sollte nicht größer sein als 2x HFirst </div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][3].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über schräger Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][4].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Sheddachfirst (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym16"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][5].value)} name={"TextAsym16"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600", height: 56}}>Schornsteinhöhe nach § 19 Abs. 1 der 1. BImSchV</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][6].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über schräger Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][7].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Sheddachfirst (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym16"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][8].value)} name={"TextAsym16"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600, height: 56}}>Mindestabstand zu Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][9].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>

                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600, marginTop: 10}}>Mindesthöhe der Schorn-steinmündung über Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 18}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][10].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}></div>
                        </div>

                    </div>
                </div>
            </div>
        }

        if (found.slug == "walmdach") {
            found["result"].map(x => x.value = x.value.toString().indexOf(".") != -1 ? x.value.toString().replace(".", ",") : x.value)
            return <div style={{marginLeft: 12}}>
                <h3>Ergebnis der Berechnung</h3>
                <div style={{display: innerwidth < 1024 ? "block" : "flex"}}>
                    <div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600", height: 40}}>Schornsteinhöhe nach VDI 3781, Blatt 4, Abs. 6.2.1</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym7"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][0].value)} name={"TextAsym7"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym9"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][1].value)} name={"TextAsym9"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym15"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][2].value)} name={"TextAsym15"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600", height: 40}}>Reduzierung der Höhe um 40 %*</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][3].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][4].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym16"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][5].value)} name={"TextAsym16"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600", height: 40}}>Schornsteinhöhe nach § 19 Abs. 1 der 1. BImSchV</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][6].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][7].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym16"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][8].value)} name={"TextAsym16"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600}}>Mindestabstand zu Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][9].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>

                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600, marginTop: 5}}>Mindesthöhe der Schorn-steinmündung über Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 10}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][10].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}></div>
                        </div>

                    </div>
                </div>
                <p><small style={{fontSize: 11}}>*Bei Walmdächern, mit allseitig gleichen Dachneigungswinkeln und Seitenverhältnissen von 1:1 bis 1:1,2, kann die Höhe über First um 40 % reduziert werden.</small></p>
            </div>
        }

        if (found.slug == "flachdach") {
            found["result"].map(x => x.value = x.value.toString().indexOf(".") != -1 ? x.value.toString().replace(".", ",") : x.value)
            return <div style={{marginLeft: 12}}>
                <h3>Ergebnis der Berechnung</h3>
                <div style={{display: innerwidth < 1024 ? "block" : "flex"}}>
                    <div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600", height: 56}}>Schornsteinhöhe nach VDI 3781, Blatt 4, Abs. 6.2.1</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym7"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][0].value)} name={"TextAsym7"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5, height: 53}}>über Flachdach, bzw. Attika (HAges.).</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym9"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][1].value)} name={"TextAsym9"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{fontSize: 12, fontWeight: "600", maxWidth: 200}}>Schornsteinhöhe nach VDI 3781 BIatt 4, Abs. 6.2.1.2.3; Hges. sollte nicht größer sein als 2x HFirst</div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym15"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][2].value)} name={"TextAsym15"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5, height: 53}}>über Flachdach, bzw. Attika (HAges.).</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>

                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][3].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600, height: 56}}>Schornsteinhöhe nach § 19 Abs. 1 der 1. BImSchV</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][4].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5, height: 53}}>über Flachdach, bzw. Attika (HAges.).</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym16"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][5].value)} name={"TextAsym16"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600, height: 56}}>Mindestabstand zu Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][6].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>

                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600, marginTop: 5}}>Mindesthöhe der Schorn-steinmündung über Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][7].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}></div>
                        </div>

                    </div>
                </div>
            </div>
        }

        if (found.slug == "pultdach") {
            found["result"].map(x => x.value = x.value.toString().indexOf(".") != -1 ? x.value.toString().replace(".", ",") : x.value)
            return <div style={{marginLeft: 12}}>
                <h3>Ergebnis der Berechnung</h3>
                <div style={{display: innerwidth < 1024 ? "block" : "flex"}}>
                    <div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600"}}>Schornsteinhöhe nach VDI 3781, Blatt 4, Abs. 6.2.1</div>
                            <div style={{display: "flex", marginTop: 28}}>
                                <input type="text"
                                       id={"TextAsym7"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][0].value)} name={"TextAsym7"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 7}}>über Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym9"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][1].value)} name={"TextAsym9"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym15"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][2].value)} name={"TextAsym15"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600}}>Schornsteinhöhe nach § 19 Abs. 1 der 1. BImSchV</div>
                            <div style={{display: "flex", marginTop: 28}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][3].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][4].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym16"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][5].value)} name={"TextAsym16"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600, marginTop: 5}}>Mindestabstand zu Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 10}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][6].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>

                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: 600, marginTop: 7}}>Mindesthöhe der Schorn-steinmündung über Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 10}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][7].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}></div>
                        </div>

                    </div>
                </div>
            </div>
        }

        if (found.slug == "assymsattelsach") {
            found["result"].map(x => x.value = x.value.toString().indexOf(".") != -1 ? x.value.toString().replace(".", ",") : x.value)
            return <div style={{marginLeft: 12}}>

                <h3>Ergebnis der Berechnung</h3>
                <div style={{display: innerwidth < 1024 ? "block" : "flex"}}>
                    <div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600"}}>Schornsteinhöhe nach VDI 3781, Blatt 4, Abs. 6.2.1</div>
                            <div style={{display: "flex", marginTop: 17}}>
                                <input type="text"
                                       id={"TextAsym7"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][0].value)} name={"TextAsym7"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym9"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][1].value)} name={"TextAsym9"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym15"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][2].value)} name={"TextAsym15"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600"}}>Schornsteinhöhe nach § 19 Abs. 1 der 1. BImSchV</div>
                            <div style={{display: "flex", marginTop: 30}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][3].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges.)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][4].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym16"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][5].value)} name={"TextAsym16"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}>über Bezugsebene (Hges.)</div>
                        </div>
                    </div>
                    <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, fontWeight: "600"}}>Mindestabstand zu Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 5}}>
                                <input type="text"
                                       id={"TextAsym8"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][6].value)} name={"TextAsym8"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>

                        </div>
                        <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                            <div style={{fontSize: 12, marginTop: 8, fontWeight: "600"}}>Mindesthöhe der Schorn-steinmündung über Lüftungsöffnungen, Fenster und Türen eines Nachbargebäudes</div>
                            <div style={{display: "flex", marginTop: 8}}>
                                <input type="text"
                                       id={"TextAsym10"}
                                       disabled={true}
                                       style={{backgroundColor: "#999", marginBottom: 0}}
                                       value={this.checkParser(found["result"][7].value)} name={"TextAsym10"}
                                />
                                <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                            </div>
                            <div style={{fontSize: 10, marginTop: 5}}></div>
                        </div>

                    </div>
                </div>
            </div>
        }
        return <div style={{marginLeft: 12}}>
            <h3>Ergebnis der Berechnung</h3>
            <div style={{display: innerwidth < 1024 ? "block" : "flex"}}>
                <div>
                    <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                        <div style={{fontSize: 12, fontWeight: "600"}}>Scho.-Höhe nach VDI 3781 BI. 4 Abs. 6.2.1</div>
                        <div style={{display: "flex", marginTop: 30}}>
                            <input type="text"
                                   id={"TextAsym7"}
                                   disabled={true}
                                   style={{backgroundColor: "#999", marginBottom: 0}}
                                   value={this.checkParser(found["result"][0].value)} name={"TextAsym7"}
                            />
                            <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                        </div>
                        <div style={{fontSize: 10, marginTop: 5}}>über Dachfläche (HAges)</div>
                    </div>
                    <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                        <div style={{display: "flex", marginTop: 5}}>
                            <input type="text"
                                   id={"TextAsym9"}
                                   disabled={true}
                                   style={{backgroundColor: "#999", marginBottom: 0}}
                                   value={this.checkParser(found["result"][1].value)} name={"TextAsym9"}
                            />
                            <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                        </div>
                        <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                    </div>
                    <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                        <div style={{display: "flex", marginTop: 5}}>
                            <input type="text"
                                   id={"TextAsym15"}
                                   disabled={true}
                                   style={{backgroundColor: "#999", marginBottom: 0}}
                                   value={this.checkParser(found["result"][2].value)} name={"TextAsym15"}
                            />
                            <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                        </div>
                        <div style={{fontSize: 10, marginTop: 5}}>über Bezugseben (AB)</div>
                    </div>
                </div>
                <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                    <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                        <div style={{fontSize: 12, fontWeight: "600"}}>Scho.-Höhe nach §19 Abs. 1</div>
                        <div style={{display: "flex", marginTop: 30}}>
                            <input type="text"
                                   id={"TextAsym8"}
                                   disabled={true}
                                   style={{backgroundColor: "#999", marginBottom: 0}}
                                   value={this.checkParser(found["result"][3].value)} name={"TextAsym8"}
                            />
                            <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                        </div>
                        <div style={{fontSize: 10, marginTop: 5}}>Dachfläche (HAges)</div>
                    </div>
                    <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                        <div style={{display: "flex", marginTop: 5}}>
                            <input type="text"
                                   id={"TextAsym10"}
                                   disabled={true}
                                   style={{backgroundColor: "#999", marginBottom: 0}}
                                   value={this.checkParser(found["result"][4].value)} name={"TextAsym10"}
                            />
                            <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                        </div>
                        <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                    </div>
                    <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                        <div style={{display: "flex", marginTop: 5}}>
                            <input type="text"
                                   id={"TextAsym16"}
                                   disabled={true}
                                   style={{backgroundColor: "#999", marginBottom: 0}}
                                   value={this.checkParser(found["result"][5].value)} name={"TextAsym16"}
                            />
                            <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                        </div>
                        <div style={{fontSize: 10, marginTop: 5}}>über Bezugseben (AB)</div>
                    </div>
                </div>
                <div style={{marginLeft: innerwidth < 1024 ? 0 : 20}}>
                    <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                        <div style={{fontSize: 12, fontWeight: "600"}}>Mindestabstand zu Lüftungsöffnungen, Fenster und Türen des Nachbargebäudes</div>
                        <div style={{display: "flex", marginTop: 5}}>
                            <input type="text"
                                   id={"TextAsym8"}
                                   disabled={true}
                                   style={{backgroundColor: "#999", marginBottom: 0}}
                                   value={this.checkParser(found["result"][6].value)} name={"TextAsym8"}
                            />
                            <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                        </div>

                    </div>
                    <div style={{maxWidth: innerwidth < 1024 ? innerwidth : 200}}>
                        <div style={{fontSize: 12, marginTop: 8, fontWeight: "600"}}>Mindesthöhe der Schornsteinmündung über Läuftg.-öffnung., Fenster und Türen des Nachbargebäudes</div>
                        <div style={{display: "flex", marginTop: 8}}>
                            <input type="text"
                                   id={"TextAsym10"}
                                   disabled={true}
                                   style={{backgroundColor: "#999", marginBottom: 0}}
                                   value={this.checkParser(found["result"][7].value)} name={"TextAsym10"}
                            />
                            <div style={{marginLeft: 10, paddingTop: 5}}>m</div>
                        </div>
                        <div style={{fontSize: 10, marginTop: 5}}>über realen First (HA1)</div>
                    </div>

                </div>
            </div>
        </div>
    }

    isAlpha(text) {
         if(typeof text == "string") {
             return text.matches("[a-zA-Z]");
         }else {
             return text;
         }

    }


    showVorgResult(found) {
        let innerwidth = this.state.width;

        found["result"].map(x => {
            if(x.value != null) {
                if(x.value != "entfällt") {


                        x.value = x.value.toString().indexOf(".") != -1 ? (Math.round(x.value * 100) / 100).toString().replace(".", ",") : x.value

                }
            }
        })
        return <div style={{marginLeft: 12}}>
            <h3>Ergebnis der Berechnung</h3>



            <div>
                {
                    found.result.map(x => <div style={{marginTop: 10, maxWidth: innerwidth < 1024 ? innerwidth : "100%"}}>

                        <div style={{display: "flex", marginTop: 5}}>
                            <input type="text"
                                   id={"TextAsym7"}
                                   disabled={true}
                                   style={{backgroundColor: "#999", marginBottom: 0}}
                                   value={this.checkParser(x.value)}
                                   name={"TextAsym7"}
                            />
                            <div style={{marginLeft: 10, paddingTop: 5}}>{x.prefix}</div>
                        </div>
                        <div style={{fontSize: 12, fontWeight: "600"}}>{x.label}</div>
                    </div>)
                }
            </div>
        </div>
    }

    showDaecher() {
        let innerwith = this.state.width;
        return <div>
            <div style={{display: "inline", marginBottom: 20}}>
                <div style={{width: innerwith < 1024 ? "83%" : 150, display: "inline-block"}}>
                    <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>Projektdatum</div>
                    <input type="date"
                           id="from"
                           placeholder="von" name="date_from"
                           value={this.state.date_from} onChange={this.handleChangeText}
                           style={{marginRight: 5, marginBottom: 0}}
                    />

                    <small style={{fontSize: 10, color: '#fff'}}>Folgt</small>
                </div>
                <div style={{width: innerwith < 1024 ? "83%" : 150, display: "inline-block", marginLeft: innerwith < 1024 ? 0 : 10}}>
                    <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>Projektname</div>

                    <input type="text"
                           id="name"
                           placeholder="Projektname" name="name"
                           style={{marginBottom: 0}}
                           value={this.state.name} onChange={this.handleChangeText}
                    />
                    <small style={{fontSize: 10, color: '#fff'}}>Folgt</small>
                </div>
                <div style={{width: innerwith < 1024 ? "83%" : 150, display: "inline-block", marginLeft: innerwith < 1024 ? 0 : 10}}>
                    <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>Projektnummer</div>

                    <input type="text"
                           id="name"
                           placeholder="Projektnummer" name="nr"
                           disabled={true}
                           style={{marginBottom: 0}}
                           value={this.state.weight} onChange={this.handleChangeText}
                    />
                    <small style={{fontSize: 10}}>wird automatisch erstellt</small>
                </div>

            </div>

            <div>
                <p style={{fontWeight: "bold"}}>
                    Wählen Sie den Dachtyp für das zu berechnende Gebäude mit Schornstein
                </p>
                {
                    this.state.daecher.map(item => <div className={"selectHome"}
                                                                                            style={{width: innerwith < 1024 ? 90 : 70}}
                                                                                            onClick={() => this.selectDaecher(item)}>
                        <div className={item.selected ? "selectHomeActive active" : "selectHomeActive"}
                             style={{width: innerwith < 1024 ? 90 : 70}}>
                            <img src={"/check_mark_ok.svg"}
                                 style={{width: 30, margin: "auto", marginTop: 20, marginLeft: 20}}/>
                        </div>
                        <img style={{width: innerwith < 1024 ? 90 : 70, margin: "auto", display: "inline-block"}}
                             src={"/home_clean/" + item.image}/>
                        <div style={{fontSize: 10, textAlign: "center"}}>{item.name}</div>
                    </div>)
                }

                <div>
                    {

                        this.state.daecher.filter(x => x.selected).length > 0 ? this.showForm(this.state.daecher.filter(x => x.selected)[0]) : null
                    }

                    {
                        this.state.daecher.filter(x => x.selected).length  > 0 ? this.state.daecher.filter(x => x.selected)[0].items.filter(x => x.value == "").length == 0 ? <>
                            <p style={{fontWeight: 600}}>Ist ein vorgelagertes Gebäude vorhanden?</p>
                            <div style={{marginTop: 10, marginBottom: 15}}>
                                <input type="radio" checked={this.state.type_dach_selected == 1} onChange={() => this.setState({type_dach_selected: !this.state.type_dach_selected}, function() {this.selectVorgDaecher(this.state.vorg_daecher.filter(x => x.slug == this.state.daecher.filter(x => x.selected)[0].slug)[0])})} value="1" name="type_dach_selected" id={"mit"}/>
                                <label htmlFor={"mit"}>Ja</label>

                                <input type="radio" style={{marginLeft: 10}} checked={this.state.type_dach_selected == 0} onChange={() => this.setState({type_dach_selected: !this.state.type_dach_selected}, function() {this.selectVorgDaecher(this.state.vorg_daecher.filter(x => x.slug == this.state.daecher.filter(x => x.selected)[0].slug)[0])})} value="0" name="type_dach_selected" id={"mit_nein"}/>
                                <label htmlFor={"mit_nein"}>Nein</label>
                            </div>

                            {
                                this.state.type_dach_selected ? <>
                                    <p style={{fontWeight: 600}}>Welche Art von vorgelagertes Gebäude ist vorhanden? (Das höchste Gebäude ist maßgebend.)</p>
                                    <div style={{marginTop: 10, marginBottom: 15}}>
                                        <input type="radio" checked={this.state.type_dach_selected_art == 0} onChange={() => this.setState({type_dach_selected_art: !this.state.type_dach_selected_art}, function() {this.selectVorgDaecher(this.state.vorg_daecher.filter(x => x.slug == this.state.vorg_daecher.filter(x => x.selected)[0].slug)[0])})} value="0" name="mittype_dach_selected_art" id={"mittype_dach_selected_art"}/>
                                        <label htmlFor={"mittype_dach_selected_art"}>vorgelagertes Einzelgebäude</label>

                                        <input type="radio" style={{marginLeft: 10}} checked={this.state.type_dach_selected_art == 1} onChange={() => this.setState({type_dach_selected_art: !this.state.type_dach_selected_art}, function() {this.selectVorgDaecher(this.state.vorg_daecher.filter(x => x.slug == this.state.vorg_daecher.filter(x => x.selected)[0].slug)[0])})} value="1" name="mittype_dach_selected_art" id={"mit_neintype_dach_selected_art"}/>
                                        <label htmlFor={"mit_neintype_dach_selected_art"}>geschlossene Bauweise ohne Grenzabstand.</label>
                                    </div></> : null
                            }


                            {
                                !this.state.type_dach_selected ? null : this.state.vorg_daecher.map(item => <div className={"selectHome"} style={{width: innerwith < 1024 ? 90 : 70}} onClick={() => this.selectVorgDaecher(item)}>
                                    <div className={item.selected ? "selectHomeActive active" : "selectHomeActive"} style={{width: innerwith < 1024 ? 90 : 70}}>
                                        <img src={"/check_mark_ok.svg"} style={{width: 30, margin: "auto", marginTop: 20, marginLeft: 20}}/>
                                    </div>
                                    <img style={{width: innerwith < 1024 ? 90 : 70, margin: "auto", display: "inline-block"}} src={"/home_clean/"+item.image}/>
                                    <div style={{fontSize: 10, textAlign: "center"}}>{item.name}</div>
                                </div>)
                            }


                            {
                                !this.state.type_dach_selected ? null : this.state.vorg_daecher.filter(x => x.selected).length > 0 ? this.state.daecher.filter(x => x.selected)[0].items.filter(x => x.value == "").length == 0 ? this.showVorgForm(this.state.vorg_daecher.filter(x => x.selected)[0]): null: null
                            }
                        </> : null : null
                    }

                </div>

                {
                    this.state.daecher.filter(x => x.selected).length > 0 ?
                        <Button showLoadingState={this.state.isSending} style={{marginTop: 10}} title={"Weiter"} onClick={() => (this.state.name.length < 2) ? alert("Bitte geben Sie einen Projektnamen an."): this.setState({show: "data"})}/>
                        : null
                }

            </div>
        </div>
    }

    selectView() {
        //if(this.state.show == "daecher") {

        //}

        // && (this.state.show == "daecher")
        return <div>
            {this.showDaecher()}
            {this.state.show != "data" && (this.state.project_firstname == null || this.state.project_firstname == "") ? null : this.renderUser() }
        </div>
    }

    renderUser() {
        let innerwidth = this.state.width;
         return <div>
             <h3>Projekt-Adresse</h3>
             <div>
                 <div>
                     <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>Bauvorhaben</div>
                        <div style={{display: innerwidth < 1024 ? "block" : "inline"}}>
                         <input type="text"
                                id={"project_title"}
                                onChange={this.handleChangeText}
                                style={{width:"85%",backgroundColor: "#f1f1f2"}}
                                name={"project_title"}
                                value={this.state.project_title}

                         />
                     </div>
                 </div>
             </div>
             <h3>Sachbearbeiter</h3>
             <div>
                 <div>
                     <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>Vorname</div>
                     <div style={{display: "inline"}}>
                         <input type="text"
                                id={"project_firstname"}
                                onChange={this.handleChangeText}
                                style={{width:"85%",backgroundColor: "#f1f1f2"}}
                                name={"project_firstname"}
                                value={this.state.project_firstname}
                         />
                     </div>
                 </div>
                 <div>
                     <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>Nachname</div>
                     <div style={{display: innerwidth < 1024 ? "block" : "inline"}}>
                         <input type="text"
                                id={"project_lastname"}
                                onChange={this.handleChangeText}
                                style={{width:"85%",backgroundColor: "#f1f1f2"}}
                                name={"project_lastname"}
                                value={this.state.project_lastname}
                         />
                     </div>
                 </div>
             </div>
             <div>
                 <div>
                     <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>Straße</div>
                     <div style={{display:innerwidth < 1024 ? "block" :  "inline"}}>
                         <input type="text"
                                id={"project_street"}
                                onChange={this.handleChangeText}
                                style={{width:"85%",backgroundColor: "#f1f1f2"}}
                                name={"project_street"}
                                value={this.state.project_street}
                         />
                     </div>
                 </div>
                 <div>
                     <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>PLZ</div>
                     <div style={{display: innerwidth < 1024 ? "block" : "inline"}}>
                         <input type="text"
                                id={"project_zip"}
                                onChange={this.handleChangeText}
                                style={{width:"85%",backgroundColor: "#f1f1f2"}}
                                name={"project_zip"}
                                value={this.state.project_zip}
                         />
                     </div>
                 </div>
                 <div>
                     <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>Ort</div>
                     <div style={{display: innerwidth < 1024 ? "block" : "inline"}}>
                         <input type="text"
                                id={"project_city"}
                                onChange={this.handleChangeText}
                                style={{width:"85%",backgroundColor: "#f1f1f2"}}
                                name={"project_city"}
                                value={this.state.project_city}

                         />
                     </div>
                 </div>
                 <div>
                     <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>Land</div>
                     <div style={{display:innerwidth < 1024 ? "block" :  "inline"}}>
                         <input type="text"
                                id={"project_country"}
                                onChange={this.handleChangeText}
                                style={{width:"85%",backgroundColor: "#f1f1f2"}}
                                name={"project_country"}
                                value={this.state.project_country}
                         />
                     </div>
                 </div>

             </div>
             <div>
                 <div>
                     <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>Telefon</div>
                     <div style={{display: innerwidth < 1024 ? "block" : "inline"}}>
                         <input type="text"
                                id={"project_phone"}
                                onChange={this.handleChangeText}
                                style={{width:"85%",backgroundColor: "#f1f1f2"}}
                                name={"project_phone"}
                                value={this.state.project_phone}

                         />
                     </div>
                 </div>
                 <div>
                     <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>Handy</div>
                     <div style={{display:innerwidth < 1024 ? "block" :  "inline"}}>
                         <input type="text"
                                id={"project_mobile"}
                                onChange={this.handleChangeText}
                                style={{width:"85%",backgroundColor: "#f1f1f2"}}
                                name={"project_mobile"}
                                value={this.state.project_mobile}

                         />
                     </div>
                 </div>
                 <div>
                     <div style={{padding: 5, paddingLeft: 0, fontSize: 12}}>E-Mail</div>
                     <div style={{display: innerwidth < 1024 ? "block" : "inline"}}>
                         <input type="text"
                                id={"project_email"}
                                onChange={this.handleChangeText}
                                style={{width:"85%",backgroundColor: "#f1f1f2"}}
                                name={"project_email"}
                                value={this.state.project_email}
                         />
                     </div>
                 </div>
             </div>
             <Button showLoadingState={this.state.isSending} style={{marginTop: 10}} title={"Speichern"} onClick={() => (this.state.name.length < 2) ? alert("Bitte geben Sie einen Projektnamen an.") : this.finale()}/>
         </div>
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar" style={{display: "flex"}}>
                    <p style={{fontSize: this.state.width < 500 ? 18 : 30, flex: 1}} className="titlebar-title">{(this.props.location.search == "") ? "Neues Projekt erstellen" : "Projekt bearbeiten"}</p>
                    <div>
                        <Button style={{cursor: "pointer", width: 100, padding: 2, fontSize: 12, height: 35, marginTop: 30, backgroundColor: 'darkred', color: '#fff'}} title={"löschen"} onClick={() => this.deleteProject()}/>
                    </div>
                </div>

                <div className="content">
                    {
                        this.state.isLoading ? this.LoadingView() : this.selectView()
                    }

                </div>

            </div>
        );
    }
}

export default Add;
