let M7 = 0;
let M9 = null;
let M10 = null;
let M11 = null;
let M12 = null;
let M14 = null;
let M16 = null;
let M17 = null;
let M18 = null;

let D20 = null;
let D21 = null;
let D22 = null;
let D23 = null;
let D24 = null;

let M31 = null;
let M32 = null;
let M35 = null;
let M36 = null;
let M39 = null;
let M40 = null;
let M42 = null;
let M43 = null;
let M44 = null;
let M45 = null;

function degreeToRadians($degree)
{
    return $degree * Math.PI / 180;
}

function calcM18() {
   let result = null;

    result = M17;

   return M18 = result;
}

function calcD22() {
    //calc D21? Check it

    let result = null;
    if (D21 <= 400) {
        result = 0.4;
    } else if (D21 > 400 && D21 <= 900) {
        result = 1;
    } else if (D21 > 900) {
        result = 3;
    }

    return D22 = result;
}

function calcM31() {
    let result = null;
    result = M9 * Math.tan((Math.PI / 180) * M12);
    return M31 = result;
}

function calcM35() {
    let result = null;

    /**
     *
     * @deprecated 27.05.24

    const M7Value = M7;
    const M10Value = M10;
    const M14Value = M14;
    const M9Value = M9;


    if (M7Value == 1) {
        result = "entfällt";
    } else {
        result = M10Value * Math.sin(M14Value * Math.PI / 180) + M9Value * Math.cos(M14Value * Math.PI / 180);
    }*/
    var radian = Math.PI / 180; // Umrechnungsfaktor für Grad in Bogenmaß

    if (M7 === 1) {
        result = "entfällt";
    } else if (M14 > 90) {
        var angle = (180 - M14) * radian;
        result = M10 * Math.sin(angle) + M9 * Math.cos(angle);
    } else {
        var angle = M14 * radian;
        result = M10 * Math.sin(angle) + M9 * Math.cos(angle);
    }

    return M35 = result;
}

function calcM32() {
    return M32 = M9 / 2 * Math.tan(20 * Math.PI / 180);
}

function calcM36() {
    let result = null;
    const M35Value = calcM35();
    const M11Value = M11;

    if (M35Value == "entfällt") {
        result = 6 * M11Value;
    } else {
        result = (1.75 * M35Value) / (1 + 0.25 * M35Value / M11Value);
    }

    return M36 = result;
}

function calcM39() {
    const M16Value = M16;
    const M36Value = calcM36();

    let result = null;

    if (M16Value >= M36Value) {
        result = "nicht relevant";
    } else {
        result = Math.sqrt(1 - (Math.pow(M16Value, 2) / Math.pow(M36Value, 2)));
    }

    return M39 = result;
}

function calcM40() {
    const M39Value = calcM39();
    const M11Value = M11;
    const M32Value = calcM32();
    const M18Value = calcM18();
    const D20Value = D20;

    let result;

    if (M39Value == "nicht relevant") {
        result = "nicht relevant";
    } else {
        var berechnung = M39Value * (M11Value + M32Value) + M18Value - D20Value;
        result = (berechnung <= 0) ? "nicht relevant" : berechnung;
    }

    return M40 = result;
}

function calcM42() {
    const M40Value = calcM40();
    const D22Value = calcD22();

    let result;

    if (M40Value == "nicht relevant") {
        result = "nicht relevant";
    } else {

        // =WENN(M40="nicht relevant";"nicht relevant";WENN((M40+$E22)<=$E23;"nicht relevant";M40+$E22))

        result = (M40Value+D22Value)<=D23 ? "nicht relevant" : M40Value + D22Value;
    }

    return M42 = result;
}

function calcM43() {
    const M42Value = calcM42();
    const D23Value = D23;

    let result = null;

    if (M42Value == "nicht relevant") {
        result = "nicht relevant";
    } else {
        // =WENN(M42="nicht relevant";"nicht relevant";WENN(M42<=$E23;"nicht relevant";M42-$E23))
        result = (M42Value <= D23Value) ? "nicht relevant" : M42Value - D23Value;
    }

    return M43 = result;
}

function calcM44() {
    const M43Value = calcM43();

    let result = null;
    if (M43Value == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = D24 + M43Value;
    }
    return M44 = result;
}

function calcM45() {
    const M42Value = calcM42();
    const M43Value = calcM43();
    const D20Value = D20;
    let result = null;

    if (M43Value == "nicht relevant") {
        result = "nicht relevant";
    } else {
        result = D20Value + M42Value;
    }

    return M45 = result;
}

function loadVorgPultdach(param) {

    switch(param) {
        case "M9":
            return M9;
        break;
        case "M10":
            return M10;
        break;
        case "M11":
            return M11;
        break;
        case "M12":
            return M12;
        break;
        case "M14":
            return M14;
        break;
        case "M16":
            return M16;
        break;
        case "M17":
            return M17;
        break;
        case "M18":
            return calcM18();
        break;
        case "M31":
            return calcM31();
        break;
        case "M35":
            return calcM35();
        break;
        case "M36":
            return calcM36();
        break;
        case "M42":
            return calcM42();
        break;
        case "M43":
            return calcM43();
        break;
        case "M44":
            return calcM44();
        break;
        case "M45":
            return calcM45();
        break;
    }
}

function updateVorgPultdach(param, value) {
    switch(param) {
        case 'M7':
            M7 = parseInt(value);
            break;
        case 'D20':
            D20 = parseFloat(value)
            break;
        case 'D21':
            D21 = parseFloat(value.toString().replace(",", "."))
            break;
        case 'D22':
            D22 = parseFloat(value)
            break;
        case 'D23':
            D23 = parseFloat(value)
            break;
        case 'D24':
            D24 = parseFloat(value)
            break;
        case "M9":
            M9 = parseFloat(value.toString().replace(",", "."));
            break;
        case "M10":
            M10 = parseFloat(value.toString().replace(",", "."));
            break;
        case "M11":
            M11 = parseFloat(value.toString().replace(",", "."));
            break;
        case "M12":
            M12 = parseFloat(value.toString().replace(",", "."));
            break;
        case "M14":
            M14 = parseFloat(value.toString().replace(",", "."));
            break;
        case "M16":
            M16 = parseFloat(value.toString().replace(",", "."));
            break;
        case "M17":
            M17 = parseFloat(value.toString().replace(",", "."));
            break;
    }
}

export{
    loadVorgPultdach, updateVorgPultdach
}
